import { createTheme } from '@material-ui/core/styles'
import { rubikLight, rubikMedium, rubikRegular } from './fontface'
import { spacing } from './spacing'
import { zIndex } from './zIndex'

/**
 * COMMENTED CODE IN THIS FILE IS FOR REFERENCE OF COLOR
 * VARIABLE NAMES WHEN REFACTORING OLD STYLING SOLUTION
 */

/* export const POD001 = {
  CTAPrimary1: '#348e99',
  CTAPrimary2: '#e0eef0',
  CTAPrimary3: '#eaf4f5',
  CTASecondary1: '#e4ecb4',
  CTASecondary2: '#f4f8e1',
  TextNumerals: '#4d4d4d',
  TextPrimary: '#5f6367',
  BackgroundGrid: '#e8e9e9',
  MessageSuccess: '#7dbf7d',
  MessageWarning1: '#b7543b',
  MessageWarning2: '#e07354'
} */

const palette = {
  error: {
    main: '#ff604f',
  },
  primary: {
    light: '#f3f6dd',
    main: '#e4ecb4',
    dark: '#d6e28f',
    contrastText: '#5f6367',
  },
  secondary: {
    light: '#E1F2F9',
    main: '#00A0CB',
    dark: '#04728F',
    mainRgb: '0, 160, 203',
    contrastText: '#fff',
  },
  highlight: {
    light: '#eaf4f5',
    main: '#dae8e9',
    dark: '#a2ccd1',
  },
  appointments: {
    houseCall: '#e9c0b1',
    preparations: '#e5b0ea',
    organizational: '#dae8e9',
  },
  text: {
    primary: '#000000',
    primaryRgb: '95, 99, 103',
    secondary: '#b0b0b0',
    disabled: '#b0b0b0',
  },
  background: {
    default: '#fff',
    paper: '#fff',
  },
}

/* const palette = {
  primary1Color: '#348e99',
  // primary1Color: '#ff0000',
  primary2Color: '#e4ecb4',
  primary3Color: '#5f6367',
  primary4Color: '#f3f6dd',
  accent1Color: '#348e99',
  accent2Color: '#eaf4f5',
  accent3Color: '#dae8e9',
  houseCallColor: '#e9c0b1',
  preparationsColor: '#e5b0ea',
  textColor: '#5f6367',
  alternateTextColor: '#ffffff',
  canvasColor: '#ffffff',
  // borderColor: '#eaf4f5',
  borderColor: '#a1ccd1',
  // disabledColor: '#d7e5e6',
  disabledColor: '#939393',
  warningColor: '#ff9800',
  pickerHeaderColor: cyan[500],
  clockCircleColor: 'rgba(0, 0, 0, 0.87)',
  shadowColor: 'rgba(0, 0, 0, 1)',
  customColors: {
    disabledColor: '#b0b0b0',
    disabledBackgroundColor: '#cfcfcf',
    brightGreen: '#d6e28f'
  }
} */

export const theme = createTheme({
  spacing,
  zIndex,
  palette,
  typography: {
    fontFamily: 'Rubik',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 500,
    body1: {
      fontWeight: 300,
    },
    body2: {
      fontWeight: 300,
    },
    h2: {
      fontWeight: 500,
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [rubikLight, rubikRegular, rubikMedium] as object[],
      },
    },
    MuiButton: {
      root: {
        fontWeight: 300,
      },
    },
    MuiTableCell: {
      head: {
        fontWeight: 300,
      },
    },
    MuiInputLabel: {
      root: {
        color: '#5f6367',
      },
    },
    MuiFormLabel: {
      root: {
        color: palette.text.primary,

        '&.Mui-focused': {
          color: palette.text.primary,
        },
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: 0,
      },
    },
    MuiIconButton: {
      colorSecondary: {
        '&:hover': {
          backgroundColor: `rgba(${palette.secondary.mainRgb}, 0.1)`,
        },
      },
    },
    MuiInput: {
      underline: {
        // color when not focused
        '&:before': {
          borderBottom: `solid 1px ${palette.highlight.dark}`,
        },
        // color when hovering unfocused
        '&:hover:not($disabled):before': {
          borderBottom: `solid 2px ${palette.highlight.dark}`,
        },
        // color when focused
        '&:after': {
          borderBottom: `solid 2px ${palette.secondary.main}`,
        },
      },
    },
    MuiFormHelperText: {
      root: {
        color: '#5f6367',
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        color: '#5f6367',
      },
    },
    MuiListSubheader: {
      root: {
        color: '#5f6367',
      },
    },
    MuiTypography: {
      colorTextSecondary: {
        color: '#5f6367',
      },
    },
    MuiRadio: {
      root: {
        color: 'grey',
      },
    },
  },
  props: {
    MuiTextField: {
      color: 'secondary',
    },
    MuiInputLabel: {
      color: 'secondary',
    },
    MuiListItemText: {
      secondaryTypographyProps: {
        color: 'textPrimary',
      },
    },
  },
})
