import { differenceInMinutes, format, isAfter, isBefore, parse } from 'date-fns'
import { cloneDeep } from 'lodash'
import { GERMAN_DATE_SHORT_YEAR_FNS } from '../constants/dateFormats'
import { leistungenKK } from '../shared/static-data/leistungenKK'
import { getDateAfter } from './dates'

export function sortByAusstellungsdatumDescending(a, b) {
  if (isAfter(a.ausstellungsdatum, b.ausstellungsdatum)) {
    return -1
  }
  if (isAfter(b.ausstellungsdatum, a.ausstellungsdatum)) {
    return 1
  }
  return 0
}

export function buildHeilmittelverordnungString(hvo, termine, kkLeistungen, patienten = false, name = false) {
  const stringVerordnungsmengeVerbraucht = buildHvoStringVerordnungsmengeVerbraucht(hvo, termine, kkLeistungen)

  // temporary fix for old HVOs
  if (!hvo.diagnosegruppe && !hvo.leitsymptomatik) {
    hvo.heilmittel = 78007
  }

  const indikationsschluessel = !!hvo.nagelkorrektur
    ? hvo.nagelkorrekturIndikation
    : kkLeistungen[hvo.heilmittel]
    ? kkLeistungen[hvo.heilmittel].indikationsschluessel
    : ''
  let frequencyString = ''
  if (hvo.minFrequenz === hvo.maxFrequenz) {
    frequencyString = hvo.minFrequenz
  } else {
    frequencyString = `${hvo.minFrequenz}-${hvo.maxFrequenz}`
  }
  let hausbesuchString = ''
  if (hvo.hausbesuch) hausbesuchString = ' | \u2302'
  if (patienten && name && patienten[hvo.patient]) {
    const patient = patienten[hvo.patient]
    return `${patient.nachname}, ${patient.vorname} | ${format(
      hvo.ausstellungsdatum,
      GERMAN_DATE_SHORT_YEAR_FNS,
    )} | ${indikationsschluessel} | ${stringVerordnungsmengeVerbraucht} | ${frequencyString}W${hausbesuchString}`
  }
  return `${format(hvo.ausstellungsdatum, GERMAN_DATE_SHORT_YEAR_FNS)} | ${indikationsschluessel}${
    !!hvo.nagelkorrekturLokalisierung ? ` (${hvo.nagelkorrekturLokalisierung})` : ''
  } | ${stringVerordnungsmengeVerbraucht} | ${frequencyString}W${hausbesuchString}`
}

export function buildHvoStringVerordnungsmengeVerbraucht(hvo, termine, kkLeistungen) {
  console.log('TERMINE: ', termine)
  let countTermineNichtAbgesagt = 0
  let addAnpassungBilateralCount = false

  for (const terminId of hvo.termine) {
    console.log('terminId: ', terminId)
    if (termine[terminId] && termine[terminId].absagegrund === null) {
      if (hvo?.nagelkorrektur) {
        const kkLeistungenArray = Object.keys(kkLeistungen).map((k) => leistungenKK[k])
        const leistungenForTermin = termine[terminId].leistungen.filter((l) => l?.istKKLeistung)
        let foundKKLeistungen = []

        leistungenForTermin.forEach((l) => {
          const foundKKLeistung = kkLeistungenArray.find((k) => k?.positionsnummer === l?.positionsnummer)

          if (foundKKLeistung) {
            foundKKLeistungen.push(foundKKLeistung)
          }

          if (foundKKLeistung?.prescribedService) {
            if (foundKKLeistung?.positionsnummer === 78210) {
              if (!addAnpassungBilateralCount) {
                addAnpassungBilateralCount = true
                countTermineNichtAbgesagt++
              }
            } else {
              countTermineNichtAbgesagt++
            }
          }
        })
      } else {
        if (termine[terminId].leistungen.some((l) => l.positionsnummer !== 78040)) {
          countTermineNichtAbgesagt++
        }
      }
    }
  }
  return `${countTermineNichtAbgesagt}/${hvo.verordnungsmenge}`
}

function getVerguetungFuerPositionsnummer(positionsnummer, verguetungen) {
  if (positionsnummer === 79934) {
    const verguetungenFuerHBSozialeGemeinschaft = verguetungen.filter((verguetung) => {
      return verguetung.positionsnummer === positionsnummer
    })

    if (
      verguetungenFuerHBSozialeGemeinschaft.length > 0 &&
      !(verguetungenFuerHBSozialeGemeinschaft[0].verguetung > 0)
    ) {
      const alternativeVerguetungenFuerHBSozialeGemeinschaft = verguetungen.filter((verguetung) => {
        return verguetung.positionsnummer === 79933
      })

      if (alternativeVerguetungenFuerHBSozialeGemeinschaft.length > 0) {
        return alternativeVerguetungenFuerHBSozialeGemeinschaft.reduce((previous, current) => {
          if (
            isAfter(
              parse(previous.datenstand, 'dd.MM.yyyy', new Date()),
              parse(current.datenstand, 'dd.MM.yyyy', new Date()),
            )
          ) {
            return previous
          }
          return current
        })
      }
    } else if (
      verguetungenFuerHBSozialeGemeinschaft.length > 0 &&
      verguetungenFuerHBSozialeGemeinschaft[0].verguetung !== 0
    ) {
      return verguetungenFuerHBSozialeGemeinschaft.reduce((previous, current) => {
        if (
          isAfter(
            parse(previous.datenstand, 'dd.MM.yyyy', new Date()),
            parse(current.datenstand, 'dd.MM.yyyy', new Date()),
          )
        ) {
          return previous
        }
        return current
      })
    }
  }

  const verguetungenFuerPositionsnummer = verguetungen.filter((verguetung) => {
    return verguetung.positionsnummer === positionsnummer
  })
  if (verguetungenFuerPositionsnummer.length > 0) {
    return verguetungenFuerPositionsnummer.reduce((previous, current) => {
      if (
        isAfter(
          parse(previous.datenstand, 'dd.MM.yyyy', new Date()),
          parse(current.datenstand, 'dd.MM.yyyy', new Date()),
        )
      ) {
        return previous
      }
      return current
    })
  }
  return { verguetung: 0 }
}

interface calcHVOResult {
  heilmittelPositionsnummer: number
  wegeGeldPositionsnummer?: number
  hausbesuchPositionsnummer?: number
  faktorHeilmittel: number
  faktorHausbesuche?: number
  kmGesamt?: number
  gesamtZuzahlung?: number
  nGesamtZuzahlung?: number
  gesamtBrutto?: number
  nGesamtBrutto?: number
  termine: any[]
  inputBehandlungAbgebrochenAktiv: boolean
  inputHVOAbgerechnetAktiv: boolean
}

interface calcHVOResultNew {
  faktorHausbesuche?: number
  faktorSozialeGemeinschaft?: number
  faktorArztlichVerodnet?: number
  faktorBefundung?: number
  faktorKleineBehandlung?: number
  faktorGrosseBehandlung?: number
  faktorEingangsbefundung?: number
  faktorTherapieberichtUI2?: number
  positionsnummerHausbesuche?: number
  positionsnummerSozialeGemeinschaft?: number
  positionsnummerBefundung?: number
  positionsnummerKleineBehandlung?: number
  positionsnummerGrosseBehandlung?: number
  positionsnummerEingangsbefundung?: number
  positionsnummerTherapieberichtUI2?: number
  gesamtZuzahlung?: number
  nGesamtZuzahlung?: number
  gesamtBrutto?: number
  nGesamtBrutto?: number
  termine: any[]
  inputBehandlungAbgebrochenAktiv: boolean
  inputHVOAbgerechnetAktiv: boolean
  nagelkorrekturPositionen: {
    positionsnummer: number
    faktor: number
  }[]
}

interface prices {
  78010: number
  78020: number
  78030: number
  78040: number
  79933: number
  79934: number
  prescriptionFee: number

  78100: number
  78110: number
  78210: number
  78220: number
  78230: number
  78300: number
  78400: number
  78510: number
  78520: number
  78530: number
}

export function calcHVOVerguetung(hvo, state): calcHVOResult | calcHVOResultNew {
  return calcHVOVerguetungFunc(hvo, state)
}

const pricesAfter01072024: prices = {
  78010: 3418,
  78020: 4912,
  78030: 337,
  78040: 2183,
  78100: 5441,
  78110: 2713,
  78510: 1675,
  78520: 2520,
  78210: 9633,
  78220: 5277,
  78230: 4828,
  78300: 9193,
  78400: 5253,
  78530: 1637,
  79933: 2195,
  79934: 1254,
  prescriptionFee: 1000,
}

const pricesAfter01072023: prices = {
  78010: 3270,
  78020: 4693,
  78030: 321,
  78040: 2079,
  78100: 5197,
  78110: 2598,
  78510: 1610,
  78520: 2415,
  78210: 9213,
  78220: 5043,
  78230: 4619,
  78300: 8844,
  78400: 5044,
  78530: 1559,
  79933: 1995,
  79934: 1140,
  prescriptionFee: 1000,
}

const pricesAfter01072022: prices = {
  78010: 3070,
  78020: 4400,
  78030: 300,
  79933: 1750,
  79934: 1000,
  prescriptionFee: 1000,

  // Nagelkorrektur HVO prices
  78100: 4871,
  78110: 0,
  78210: 8654,
  78220: 4737,
  78230: 4339,
  78300: 8681,
  78400: 4764,
  78510: 1519,
  78520: 2271,
}

const pricesBefore01072022: prices = {
  78010: 2935,
  78020: 4200,
  78030: 200,
  79933: 1650,
  79934: 950,
  prescriptionFee: 1000,

  // Nagelkorrektur HVO prices
  78100: 4871,
  78110: 0,
  78210: 8654,
  78220: 4737,
  78230: 4339,
  78300: 8681,
  78400: 4764,
  78510: 1519,
  78520: 2271,
}

export const getPriceForService = (service, appointment) => {}

export function calcHVOVerguetungFunc(hvo, state): calcHVOResultNew {
  const notCancelledEvents = hvo.termine
    .map((key) => state.entities.termine[key])
    .filter((termin) => termin && !termin.absagegrund)

  const res: calcHVOResultNew = {
    faktorHausbesuche: 0,
    faktorSozialeGemeinschaft: 0,
    faktorArztlichVerodnet: 0,
    faktorBefundung: notCancelledEvents.length,
    faktorKleineBehandlung: 0,
    faktorGrosseBehandlung: 0,
    faktorEingangsbefundung: 0,
    positionsnummerHausbesuche: 79933,
    positionsnummerSozialeGemeinschaft: 79934,
    positionsnummerBefundung: 78030,
    positionsnummerKleineBehandlung: 78010,
    positionsnummerGrosseBehandlung: 78020,
    positionsnummerEingangsbefundung: 78040,
    gesamtZuzahlung: undefined,
    nGesamtZuzahlung: undefined,
    gesamtBrutto: undefined,
    nGesamtBrutto: undefined,
    termine: notCancelledEvents,
    inputBehandlungAbgebrochenAktiv: false,
    inputHVOAbgerechnetAktiv: false,
    nagelkorrekturPositionen: [],
  }

  const today = new Date()

  console.log('dates after today: ', getDateAfter(today, notCancelledEvents))
  const followingEvents = !!getDateAfter(today, notCancelledEvents)

  if (hvo.hausbesuch) {
    res.faktorHausbesuche = notCancelledEvents.reduce((previous, current) => {
      return current.hausbesuch ? previous + 1 : previous
    }, 0)
  }

  let zuzahlungGesamt = 1000
  let bruttoGesamt = 0

  if (hvo.nagelkorrektur) {
    const positionenUnsorted = []
    const leistungenKKArray = Object.keys(leistungenKK).map((key) => leistungenKK[key])
    let anpassungPriceAdded = false
    // calculation for Nagelkorrektur HVO
    notCancelledEvents.forEach((t) => {
      const filteredLeistungenKK = t?.leistungen?.filter((l) => !!l?.istKKLeistung)

      filteredLeistungenKK.forEach((l) => {
        const leistungenKKObject = leistungenKKArray.find((lk) => lk.positionsnummer === l.positionsnummer)

        if (!!leistungenKKObject) {
          const positionenIndex = positionenUnsorted.findIndex((p) => p.positionsnummer === l.positionsnummer)
          if (positionenIndex === -1) {
            positionenUnsorted.push({
              positionsnummer: l.positionsnummer,
              faktor: 1,
            })
          } else {
            positionenUnsorted[positionenIndex].faktor += 1
          }
        }
      })

      if (positionenUnsorted.length > 0) {
        res.nagelkorrekturPositionen = positionenUnsorted.slice().sort((a, b) => a.positionsnummer - b.positionsnummer)
      }

      let prices
      if (isBefore(t.beginn, new Date('2022-07-01'))) {
        prices = pricesBefore01072022
      } else if (isBefore(t.beginn, new Date('2023-07-01')) && isAfter(t.beginn, new Date('2022-07-01'))) {
        prices = pricesAfter01072022
      } else if (isBefore(t.beginn, new Date('2024-07-01')) && isAfter(t.beginn, new Date('2023-07-01'))) {
        prices = pricesAfter01072023
      } else {
        prices = pricesAfter01072024
      }

      let bruttoGesamtForTermine = 0

      if (hvo.hausbesuch && t.hausbesuch) {
        if (hvo.socialFacility) {
          bruttoGesamtForTermine += prices[79934]
          res.faktorSozialeGemeinschaft = res.faktorSozialeGemeinschaft + 1
        } else if (hvo.medicallyPrescribed) {
          bruttoGesamtForTermine += prices[79933]
          res.faktorArztlichVerodnet = res.faktorArztlichVerodnet + 1
        }
      }

      leistungenKKArray.forEach((lk) => {
        const foundLeistung = filteredLeistungenKK.find((l) => l.positionsnummer === lk.positionsnummer)
        console.log('foundLeistung: ', foundLeistung)
        if (!!foundLeistung && foundLeistung.positionsnummer !== 78220) {
          if (foundLeistung.positionsnummer === 78210) {
            if (!anpassungPriceAdded) {
              anpassungPriceAdded = true
              bruttoGesamtForTermine += prices[foundLeistung.positionsnummer]
            }
          } else {
            bruttoGesamtForTermine += prices[foundLeistung.positionsnummer]
          }
        }
      })

      if (filteredLeistungenKK.some((l) => l.positionsnummer === 78520)) {
        zuzahlungGesamt -= 1
      }

      zuzahlungGesamt += Math.round(bruttoGesamtForTermine * 0.1)

      if (filteredLeistungenKK.some((l) => l.positionsnummer === 78220)) {
        bruttoGesamtForTermine += prices[78220]
      }

      bruttoGesamt += bruttoGesamtForTermine
    })

    if (hvo.diagnosegruppe === 'UI2' && hvo.therapiebericht && notCancelledEvents.length === hvo.verordnungsmenge) {
      res.faktorTherapieberichtUI2 = 1

      const clonedTermine = cloneDeep(notCancelledEvents)
      clonedTermine.sort((a, b) => a.beginn - b.beginn)
      const lastTermin = clonedTermine[clonedTermine.length - 1]

      let priceForTherapiebericht = 0
      if (isBefore(lastTermin.beginn, new Date('2022-07-01'))) {
        priceForTherapiebericht = 0
      } else if (
        isBefore(lastTermin.beginn, new Date('2023-11-01')) &&
        isAfter(lastTermin.beginn, new Date('2022-07-01'))
      ) {
        priceForTherapiebericht = 0
      } else if (
        isBefore(lastTermin.beginn, new Date('2024-07-01')) &&
        isAfter(lastTermin.beginn, new Date('2023-11-01'))
      ) {
        priceForTherapiebericht = pricesAfter01072023[78530]
      } else {
        priceForTherapiebericht = pricesAfter01072024[78530]
      }

      bruttoGesamt += priceForTherapiebericht
    }

    res.gesamtZuzahlung = zuzahlungGesamt
    res.gesamtBrutto = bruttoGesamt

    if (hvo.gebuehrenbefreit) {
      res.gesamtZuzahlung = 0
    }

    res.nGesamtZuzahlung = res.gesamtZuzahlung
    res.nGesamtBrutto = res.gesamtBrutto
  } else {
    // calulation for "normal" HVO

    notCancelledEvents.forEach((t) => {
      let prices

      if (isBefore(t.beginn, new Date('2022-07-01'))) {
        prices = pricesBefore01072022
      } else if (isBefore(t.beginn, new Date('2023-07-01')) && isAfter(t.beginn, new Date('2022-07-01'))) {
        prices = pricesAfter01072022
      } else if (isBefore(t.beginn, new Date('2024-07-01')) && isAfter(t.beginn, new Date('2023-07-01'))) {
        prices = pricesAfter01072023
      } else {
        prices = pricesAfter01072024
      }

      let bruttoGesamtForTermine = prices[78030]

      let duration = differenceInMinutes(t.ende, t.beginn)
      if (t.preparationNeeded) {
        duration -= 15
      }

      if (duration > 20) {
        bruttoGesamtForTermine += prices[78020]
        res.faktorGrosseBehandlung = res.faktorGrosseBehandlung + 1
      } else {
        bruttoGesamtForTermine += prices[78010]
        res.faktorKleineBehandlung = res.faktorKleineBehandlung + 1
      }

      if (hvo.hausbesuch && t.hausbesuch) {
        if (hvo.socialFacility) {
          bruttoGesamtForTermine += prices[79934]
          res.faktorSozialeGemeinschaft = res.faktorSozialeGemeinschaft + 1
        } else if (hvo.medicallyPrescribed) {
          bruttoGesamtForTermine += prices[79933]
          res.faktorArztlichVerodnet = res.faktorArztlichVerodnet + 1
        }
      }

      if (t.leistungen.some((l) => l.positionsnummer === 78040)) {
        res.faktorEingangsbefundung = 1
        bruttoGesamtForTermine += prices[78040]

        res.faktorBefundung = notCancelledEvents.length - 1 === 0 ? undefined : notCancelledEvents.length - 1
        bruttoGesamtForTermine -= prices[78030]
      }

      bruttoGesamt += bruttoGesamtForTermine
      zuzahlungGesamt += Math.round(bruttoGesamtForTermine * 0.1)
    })
  }

  res.gesamtZuzahlung = zuzahlungGesamt
  res.gesamtBrutto = bruttoGesamt

  if (hvo.gebuehrenbefreit) {
    res.gesamtZuzahlung = 0
  }

  if (notCancelledEvents.length < hvo.verordnungsmenge && !followingEvents && !hvo.abgerechnet) {
    res.inputBehandlungAbgebrochenAktiv = true
  }

  console.log('hvo.nagelkorrektur: ', hvo.nagelkorrektur)
  console.log('hvo.behandlungAbgebrochen: ', hvo.behandlungAbgebrochen)
  console.log(
    'notCancelledEvents.length >= hvo.verordnungsmenge && !followingEvents: ',
    notCancelledEvents.length >= hvo.verordnungsmenge && !followingEvents,
  )
  console.log('hvo.abgerechnet: ', hvo.abgerechnet)

  if (
    hvo.nagelkorrektur ||
    hvo.behandlungAbgebrochen ||
    (notCancelledEvents.length >= hvo.verordnungsmenge && !followingEvents) ||
    hvo.abgerechnet
  ) {
    res.inputHVOAbgerechnetAktiv = true
  }

  res.nGesamtZuzahlung = res.gesamtZuzahlung
  res.nGesamtBrutto = res.gesamtBrutto

  console.log('res: ', res)

  return res
}
