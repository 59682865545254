/**
 Will be used as wrapper for MUI TextField
 */
import { Paper, Popper, TextField } from '@material-ui/core'
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'

interface Props {
  className?: string
  onChange?: (value: string | number | null) => void
  onBlur?: (e: any) => void
  onFocus?: (e: any) => void
  onKeyDown?: (e: any) => void
  onKeyUp?: (e: any) => void
  onClick?: (e: any) => void

  value?: string | number | null
  fullWidth?: boolean
  label?: string | JSX.Element
  placeholder?: string

  error?: boolean
  helperText?: string | JSX.Element
  redHelperText?: string | JSX.Element
  disabled?: boolean
  InputProps?: any
  inputProps?: any
  readOnly?: boolean
  autoComplete?: string

  variant?: 'standard' | 'filled' | 'outlined'
  autoFocus?: boolean
  multiline?: boolean
  rows?: number | ''

  textExpand?: boolean
  abbreviations: Record<string, string>
}

const Listbox = styled.ul`
  list-style: none;
  margin: 0;
  padding: 8px 0;
  max-height: 20vh;
  overflow: auto;
  &:focus {
    outline: none;
  }
  scrollbar-width: thin;
`

const Option = styled.li`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  padding: 6px 16px;
  box-sizing: border-box;
  outline: 0;
  font-size: 1rem;
  line-height: 1.5;
  -webkit-tap-highlight-color: transparent;
  background-color: ${(props: { selected: boolean }) => (props.selected ? 'lightgrey' : 'transparent')};

  &:hover {
    background-color: lightgrey;
  }

  &:active {
    background-color: lightgrey;
  }

  &:focus {
    background-color: lightgrey;
  }
`

const escapeRegExp = (string: string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

const TextExpandInput = ({
  className = '',
  onChange,
  onBlur,
  onFocus,
  onKeyDown,
  onKeyUp,
  onClick,
  value = null,
  fullWidth = true,
  label = '',
  placeholder = '',

  error,
  helperText,
  redHelperText,
  disabled,
  InputProps = {},
  inputProps = {},
  readOnly = false,

  variant = 'standard',
  autoComplete = '',
  autoFocus,
  multiline = false,
  rows = 1,

  textExpand = false,
  abbreviations = {},
}: Props) => {
  const [focused, setFocused] = useState<boolean>(false)
  const [stateValue, setStateValue] = useState<string | number | null>(value)
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const [isMouseDown, setIsMouseDown] = useState<boolean>(false)
  const listboxRef = useRef<HTMLUListElement>(null)

  const inputRef = useRef<HTMLInputElement | null>(null)

  const selectableAbbreviations = useMemo(() => {
    let result = []

    if (abbreviations && typeof stateValue === 'string' && stateValue !== '') {
      const lines = stateValue.split('\n')
      const lastLine = lines[lines.length - 1]
      const splitLastLine = lastLine.split(' ')
      const lastValue = splitLastLine[splitLastLine.length - 1]

      if (lastValue !== '' && lastValue !== ' ') {
        const escapedLastValue = escapeRegExp(lastValue)
        const lastValueAsRegexp = new RegExp(`^${escapedLastValue}`, 'i')

        Object.keys(abbreviations)
          .sort((a, b) => a.localeCompare(b))
          .filter((key) => lastValueAsRegexp.test(key))
          .forEach((abb) => {
            result.push({ abbreviation: abb, description: abbreviations[abb] })
          })
      } else {
        result = []
      }
    }

    return result
  }, [stateValue])

  const getNumber = (value) => {
    const parsedNumber = Number(value)
    let newValue = isNaN(parsedNumber) ? '' : parsedNumber
    newValue = String(newValue !== '' ? newValue : '')
    return newValue
  }

  useEffect(() => {
    let newValue: string | number | null = value
    if (value === '' || value == null) {
      newValue = ''
    }
    setStateValue(newValue)
  }, [value])

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    e?.persist()
    let newValue: string | number | null = e.target.value

    if (newValue === '' || newValue == null) {
      newValue = ''
    }

    setStateValue(newValue)
    onChange && onChange(newValue)
    setSelectedIndex(0)
  }

  const handleOnBlur = (e) => {
    e?.persist()

    if (!isMouseDown) {
      setFocused(false)
    }
  }

  const handleOnFocus = (e) => {
    e?.persist()
    setFocused(true)
    onFocus && onFocus(e)
  }

  const handleSelectAbbreviation = (abbr) => {
    // Replace the last part of stateValue with the abbreviation

    const lines = stateValue.split('\n')
    const lastLine = lines[lines.length - 1]
    const splitLastLine = lastLine.split(' ')
    const lastValue = splitLastLine[splitLastLine.length - 1]

    const newValue = stateValue.replace(RegExp(`${lastValue}$`, 'gm'), `${abbr.abbreviation}-${abbr.description}, `)

    setStateValue(newValue)
    onChange && onChange(newValue)
    setIsMouseDown(false)
    inputRef?.current?.focus()
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    const isListboxOpen = focused && !!selectableAbbreviations && selectableAbbreviations.length > 0

    if (!isListboxOpen) {
      return
    } else if (e.key === 'ArrowDown') {
      e.preventDefault()
      setSelectedIndex((prev) => (prev + 1) % selectableAbbreviations.length)
    } else if (e.key === 'ArrowUp') {
      e.preventDefault()
      setSelectedIndex((prev) => (prev + selectableAbbreviations.length - 1) % selectableAbbreviations.length)
    } else if (e.key === 'Enter') {
      e.preventDefault()
      handleSelectAbbreviation(selectableAbbreviations[selectedIndex])
    }
  }

  return (
    <>
      <TextField
        inputRef={inputRef}
        className={className}
        onClick={onClick}
        onChange={!readOnly ? handleChange : undefined}
        onBlur={!readOnly ? handleOnBlur : undefined}
        onFocus={handleOnFocus}
        onKeyDown={handleKeyDown}
        onKeyUp={onKeyUp}
        value={stateValue ?? ''}
        fullWidth={fullWidth}
        label={label}
        placeholder={placeholder}
        error={error || !!redHelperText}
        helperText={!!helperText ? helperText : !!redHelperText ? redHelperText : ''}
        disabled={disabled}
        InputProps={{
          ...InputProps,
          autoComplete,
          readOnly,
        }}
        inputProps={inputProps}
        variant={variant}
        autoFocus={autoFocus}
        multiline={multiline}
        rows={rows}
        InputLabelProps={{ shrink: (stateValue != null && stateValue !== '') || focused }}
      />
      <Popper
        anchorEl={inputRef?.current}
        style={{ width: inputRef?.current?.clientWidth, zIndex: 5000 }}
        open={focused && !!selectableAbbreviations && selectableAbbreviations.length > 0}
        role="presentation"
      >
        <Paper>
          {focused && !!selectableAbbreviations && selectableAbbreviations.length > 0 && (
            <Listbox ref={listboxRef} role="listbox" tabIndex={-1}>
              {selectableAbbreviations.map((abbr, index) => (
                <Option
                  key={abbr.abbreviation}
                  onMouseDown={() => setIsMouseDown(true)}
                  onClick={() => handleSelectAbbreviation(abbr)}
                  selected={index === selectedIndex}
                >
                  {abbr.abbreviation} - {abbr.description}
                </Option>
              ))}
            </Listbox>
          )}
        </Paper>
      </Popper>
    </>
  )
}

TextExpandInput.displayName = 'ControlledTextExpandInput'

export default TextExpandInput
