import { Link as MuiLink } from '@material-ui/core'
import {
  addWeeks,
  differenceInDays,
  differenceInMinutes,
  endOfDay,
  endOfWeek,
  format,
  getDate,
  isAfter,
  isBefore,
  isDate,
  isSameDay,
  isSameMinute,
  isValid,
  startOfDay,
  startOfWeek,
  subWeeks,
} from 'date-fns'
import { Link } from 'react-router-dom'
import isPristine from 'redux-form/lib/isPristine'
import { findCustomers, hasPatientsRemaining } from '../actions/customers'
import { lookup } from '../actions/phoneNumber'
import { GERMAN_DATE_SHORT_YEAR_FNS } from '../constants/dateFormats'
import { validate as validateEmail } from '../redux/modules/mailgun'
import { plan } from '../shared/utils/constants'
import { getDateAfter, getDateBefore } from './dates'

export function validateDateFieldNotInFuture(values, props, fields) {
  const today = new Date()
  const errors: KeyValue<string> = {}
  fields.forEach((fieldName) => {
    if (values[fieldName] && isValid(values[fieldName]) && isAfter(values[fieldName], today)) {
      errors[fieldName] = 'Dieses Datum darf nicht in der Zukunft liegen.'
    }
  })
  return errors
}

export function checkTerminConditions(beginn, ende, _, isAllDay = false) {
  return {
    beginnNachEnde: beginn && ende && isAfter(beginn, ende),
    beginnEndeGleich: beginn && ende && isSameMinute(beginn, ende),
    dauerZuKurz: beginn && ende && differenceInMinutes(ende, beginn) < 5,
    terminUeberschneidetTage: beginn && ende && getDate(beginn) !== getDate(ende) && !isAllDay,
  }
}

export function validateTerminConditions(values, props) {
  const { terminConditions } = props
  const { isAllDay } = values
  const errors: KeyValue<string> = {}

  if (terminConditions.beginnNachEnde) {
    errors.beginn = 'Der Termin darf nicht nach seinem Ende beginnen!'
  }

  if (!isAllDay && terminConditions.beginnEndeGleich) {
    errors.ende = 'Der Termin darf nicht zur gleichen Zeit enden, zu der er beginnt!'
  }

  if (!isAllDay && terminConditions.dauerZuKurz) {
    errors.ende = 'Die Dauer muss mindestens 5 Minuten betragen.'
  }

  if (terminConditions.terminUeberschneidetTage) {
    errors.beginn = 'Beginn und Ende des Termins müssen am gleichen Tag sein!'
  }

  return errors
}

export function validateHVOConditions(values, props, nagelkorrektur = false) {
  const errors: KeyValue<string> = {}
  const { HVOConditions } = props

  if (!values.begruendungFrequenzueberschreitung) {
    // if (HVOConditions.frequenzueberschreitung.vergangenheit || HVOConditions.frequenzueberschreitung.zukunft) {
    if (HVOConditions.frequenzueberschreitung.vergangenheit) {
      errors.begruendungFrequenzueberschreitung = 'Bitte ausfüllen.'
    }
  }
  if (HVOConditions.frequenzunterschreitungWoche.vergangenheit) {
    errors._error = `Unzulässige Frequenzunterschreitung.\nLetzter HVO-Termin war am: ${format(
      HVOConditions.frequenzunterschreitungWoche.vergangenheit.beginn,
      GERMAN_DATE_SHORT_YEAR_FNS,
    )}`
  }
  if (HVOConditions.frequenzunterschreitungWoche.zukunft) {
    errors._error = `Unzulässige Frequenzunterschreitung.\nNächster HVO-Termin ist am: ${format(
      HVOConditions.frequenzunterschreitungWoche.zukunft.beginn,
      GERMAN_DATE_SHORT_YEAR_FNS,
    )}`
  }
  if (HVOConditions.behandlungVorAusstellungsdatum) {
    errors.heilmittelverordnung = 'Die Behandlung darf nicht vor dem Ausstellungsdatum der HVO beginnen.'
  }
  if (HVOConditions.behandlungNachSpaetestemBeginn && !HVOConditions.cancellationRequired) {
    errors.heilmittelverordnung =
      'Die Behandlung darf nicht nach dem Datum des spätesten Behandlungsbeginns der HVO liegen.'
  }

  if (HVOConditions.cancellationRequired) {
    errors.heilmittelverordnung = `Der Termin darf nicht später als 12 Wochen nach dem vorherigen Termin liegen (${format(
      HVOConditions.cancellationRequired,
      GERMAN_DATE_SHORT_YEAR_FNS,
    )})!`
  }

  // temporary fix for nagelkorrektur hvos:
  // nagelkorrektur currently has wrong count for verordnungsmenge, therefore we skip them on the below check to still allow dates to be created
  if (!nagelkorrektur && HVOConditions.verordnungsmengeErreicht) {
    errors.heilmittelverordnung = 'Verordnungsmenge erreicht. Neue HVO anlegen oder Termin ohne HVO anlegen.'
  }
  if (HVOConditions.behandlungAbgebrochen) {
    errors.heilmittelverordnung = 'Behandlung wurde abgebrochen. Korrigieren Sie dies ggf. in der HVO.'
  }
  if (HVOConditions.abgerechnet) {
    errors.heilmittelverordnung = 'HVO wurde abgerechnet. Weitere Termine können nicht zugeordnet werden.'
  }
  if (HVOConditions.erstbefundungVergeben !== false) {
    errors.leistungen = `Die Leistung "Erstbefundung" ist bereits einem anderen Termin zugeordnet (${HVOConditions.erstbefundungVergeben}).`
  }

  console.log('errors: ', errors)
  return errors
}

const isDateCancelled = (date: Appointment) => !!date?.absagedatum || !!date?.absagegrund

export function checkHVOConditions({ hvos, termine, terminHVOId, terminBeginn, terminId, terminLeistungen }) {
  const results = {
    frequenzunterschreitungTag: {
      zukunft: false,
      vergangenheit: false,
      differenzTage: 0,
    },
    frequenzunterschreitungWoche: {
      zukunft: false,
      vergangenheit: false,
    },
    frequenzueberschreitung: {
      zukunft: false,
      vergangenheit: false,
    },
    verordnungsmengeErreicht: false,
    behandlungVorAusstellungsdatum: false,
    behandlungNachSpaetestemBeginn: false,
    abgerechnet: false,
    behandlungAbgebrochen: false,
    erstbefundungVergeben: false,
    cancellationRequired: false,
  }

  console.log('test terminHVOId: ', terminHVOId)
  if (
    !terminHVOId ||
    !isDate(terminBeginn) ||
    !isValid(terminBeginn) ||
    terminHVOId === 'hvoWirdNachgereicht' ||
    terminHVOId === 'null'
  ) {
    return results
  }

  const hvo = hvos[terminHVOId]

  const terminArray = hvo.termine
    .map((key) => termine[key])
    .filter((termin) => {
      return termin && !termin.absagegrund && termin.id !== terminId
    })

  const existingDateData = terminId ? termine[terminId] : null

  if (hvo.behandlungAbgebrochen) {
    results.behandlungAbgebrochen = true
  }

  if (hvo.abgerechnet) {
    results.abgerechnet = true
  }

  const hasSingularEingangsbefundung = terminArray.some(
    (t) => t.leistungen.length === 1 && t.leistungen[0].positionsnummer === 78040,
  )
  const terminCount = hasSingularEingangsbefundung ? terminArray.length - 1 : terminArray.length
  if (terminCount >= hvo.verordnungsmenge) {
    results.verordnungsmengeErreicht = true
  }

  const vorherigerTermin = getDateBefore(terminBeginn, terminArray)

  const naechsterTermin = getDateAfter(terminBeginn, terminArray)

  if (vorherigerTermin && (!existingDateData || !isDateCancelled(existingDateData))) {
    if (!hvo.nagelkorrektur) {
      if (
        vorherigerTermin.leistungen.some((l) => l.positionsnummer !== 78040) &&
        isAfter(addWeeks(vorherigerTermin.beginn, hvo.minFrequenz), endOfDay(terminBeginn))
      ) {
        results.frequenzunterschreitungTag.vergangenheit = vorherigerTermin
        results.frequenzunterschreitungTag.differenzTage = Math.abs(
          differenceInDays(startOfDay(terminBeginn), startOfDay(addWeeks(vorherigerTermin.beginn, hvo.minFrequenz))),
        )
      }
      if (
        vorherigerTermin.leistungen.some((l) => l.positionsnummer !== 78040) &&
        isAfter(addWeeks(vorherigerTermin.beginn, hvo.minFrequenz), endOfWeek(terminBeginn, { weekStartsOn: 1 }))
      ) {
        results.frequenzunterschreitungWoche.vergangenheit = vorherigerTermin
      }
    }

    if (isAfter(startOfDay(terminBeginn), addWeeks(endOfDay(vorherigerTermin.beginn), 12))) {
      results.cancellationRequired = vorherigerTermin.beginn
    }

    if (
      !hvo.nagelkorrektur &&
      !results.cancellationRequired &&
      isBefore(addWeeks(vorherigerTermin.beginn, hvo.maxFrequenz), startOfDay(terminBeginn))
    ) {
      results.frequenzueberschreitung.vergangenheit = vorherigerTermin
    }
  }

  if (!hvo.nagelkorrektur && naechsterTermin && (!existingDateData || !isDateCancelled(existingDateData))) {
    if (isBefore(subWeeks(naechsterTermin.beginn, hvo.minFrequenz), startOfDay(terminBeginn))) {
      results.frequenzunterschreitungTag.zukunft = naechsterTermin
      results.frequenzunterschreitungTag.differenzTage = Math.abs(
        differenceInDays(startOfDay(terminBeginn), startOfDay(subWeeks(naechsterTermin.beginn, hvo.minFrequenz))),
      )
    }
    if (isBefore(subWeeks(naechsterTermin.beginn, hvo.minFrequenz), startOfWeek(terminBeginn, { weekStartsOn: 1 }))) {
      results.frequenzunterschreitungWoche.zukunft = naechsterTermin
    }
    if (isAfter(subWeeks(naechsterTermin.beginn, hvo.maxFrequenz), endOfDay(terminBeginn))) {
      results.frequenzueberschreitung.zukunft = naechsterTermin
    }
  }

  if (hvo?.nagelkorrektur) {
    // validate if service "Erstbefundung" is already set on another appointment
    if (terminCount > 0 && !!terminLeistungen?.find((leistung) => leistung?.positionsnummer === 78100)) {
      const terminWithErstbefundung = terminArray.find(
        (termin) => !!termin.leistungen?.filter((leistung) => leistung.positionsnummer === 78100).length,
      )

      results.erstbefundungVergeben = terminWithErstbefundung?.beginn
        ? format(terminWithErstbefundung.beginn, GERMAN_DATE_SHORT_YEAR_FNS)
        : false
    }

    // check if cancellation is required
    if (terminCount > 0 && isAfter(startOfDay(terminBeginn), addWeeks(endOfDay(vorherigerTermin.beginn), 12))) {
      results.cancellationRequired = vorherigerTermin.beginn
    }
  }

  if (isBefore(terminBeginn, hvo.ausstellungsdatum)) {
    results.behandlungVorAusstellungsdatum = true
  }

  if (terminCount === 0 && isAfter(terminBeginn, endOfDay(hvo.behandlungsbeginnSpaetestensAm))) {
    results.behandlungNachSpaetestemBeginn = true
  }
  return results
}

async function validatePhoneNumber(values, dispatch) {
  const { mobil, telefon, mobilCountry, smsReminder, callReminder } = values

  if (smsReminder) {
    if (!mobilCountry || !mobil) {
      return null
    }

    const replaceCharsRegexp = /[^0-9]/gi

    const phone = `${mobilCountry}${mobil.replace(replaceCharsRegexp, '')}`

    const response = await dispatch(lookup(phone))

    if (!response) {
      return { mobil: 'Bitte geben Sie eine gültige Rufnummer an!' }
    }
  }

  if (callReminder) {
    if (!mobilCountry || !telefon) {
      return null
    }

    const replaceCharsRegexp = /[^0-9]/gi

    const phone = `${mobilCountry}${telefon.replace(replaceCharsRegexp, '')}`

    const response = await dispatch(lookup(phone))

    if (!response) {
      return { telefon: 'Bitte geben Sie eine gültige Rufnummer an!' }
    }
  }

  return null
}

export async function validatePracticePhoneNumber(values, dispatch) {
  const { telefon, countryCode, callReminder } = values

  if ((callReminder && !countryCode) || (callReminder && !telefon) || !callReminder) return null

  const replaceCharsRegexp = /[^0-9]/gi

  const phone = `${countryCode}${telefon.replace(replaceCharsRegexp, '')}`

  const response = await dispatch(lookup(phone))

  if (!response) {
    return { telefon: 'Bitte geben Sie eine gültige Rufnummer an!' }
  }

  return null
}

const planUpgradeUrl =
  process.env.NODE_ENV === 'production'
    ? 'https://pododesk.de/fuexc_unidy/start'
    : 'https://staging.pododesk.de/fuexc_unidy/start'

export async function asyncValidateCustomer(values, dispatch, props) {
  console.log('props in validation: ', props)
  const serverHash = props.params?.serverHash ?? null
  const emailField = props.form.email

  const lookupResult = await validatePhoneNumber(values, dispatch)

  if (props.editMode) {
    const remainingData = await dispatch(hasPatientsRemaining())
    const planData = remainingData?.ownerPlanId ? plan.find((p) => p.id === remainingData.ownerPlanId) : null
    const remainingPatients = planData?.maxNumberOfPatients
      ? planData.maxNumberOfPatients - remainingData.ownerPatientsCount
      : null

    if (planData) {
      if (
        remainingPatients !== null &&
        remainingPatients <= 5 &&
        (props.form.verstorben.initial || props.form.abgewandertZu.initial) &&
        !props.form.verstorben.value &&
        !props.form.abgewandertZu.value
      ) {
        return {
          verstorben: !isPristine(props.form.verstorben.initial, props.form.verstorben.value) ? (
            <span>
              Kann nicht geändert werden, da das Patientenlimit des Plans erreicht ist.
              {props.currentUser.authUserId === props.currentServer.createdBy ? (
                <>
                  &nbsp; (
                  <MuiLink color="secondary" href={planUpgradeUrl}>
                    Jetzt upgraden
                  </MuiLink>
                  )
                </>
              ) : null}
            </span>
          ) : null,
          abgewandertZu: !isPristine(props.form.abgewandertZu.initial, props.form.abgewandertZu.value) ? (
            <span>
              Kann nicht geändert werden, da das Patientenlimit des Plans erreicht ist.
              {props.currentUser.authUserId === props.currentServer.createdBy ? (
                <>
                  &nbsp; (
                  <MuiLink color="secondary" href={planUpgradeUrl}>
                    Jetzt upgraden
                  </MuiLink>
                  )
                </>
              ) : null}
            </span>
          ) : null,
        }
      }
    }
  }

  if (lookupResult) {
    console.log('Found phone lookup error', lookupResult)
    return lookupResult
  }

  if (
    typeof emailField.value === 'string' &&
    emailField.value.trim() !== '' &&
    !isPristine(emailField.initial, emailField.value)
  ) {
    try {
      const res = await dispatch(validateEmail(emailField.value))
      if (res.result !== 'deliverable' && res.result !== 'unknown') {
        return {
          email: (
            <span>
              Diese Adresse ist nicht erreichbar und muss korrigiert werden. (
              <a
                href="https://pododesk.freshdesk.com/support/solutions/articles/17000086743-was-bedeutet-e-mail-adresse-nicht-erreichbar-"
                target="_blank"
                rel="noreferrer"
              >
                Warum?
              </a>
              )
            </span>
          ),
        }
      }
    } catch (err) {
      console.log(err)
      setTimeout(props.stopSubmit, 0)
      return {
        email: (
          <span>
            Adresse kann nicht überprüft werden. Bitte wenden Sie sich an den&nbsp;
            <a onClick={() => window['fcWidget']?.open()}>pododesk Support</a>.
          </span>
        ),
      }
    }
  }

  if (!values.nachname) {
    return {}
  }
  const res = await dispatch(
    findCustomers(
      [(values.vorname ?? '').trim(), (values.nachname ?? '').trim()].join(' '),
      ['vorname', 'nachname'],
      false,
    ),
  )
  const patientenSearchResults = Object.keys(res.entities.patienten).map((key) => res.entities.patienten[key])

  const patientenNachnameMatches = patientenSearchResults.filter((patient) => {
    return patient.nachname.toLowerCase().trim() === values.nachname.toLowerCase().trim()
  })
  if (patientenNachnameMatches.length && (!values.vorname || !values.vorname.trim())) {
    if (values.id) return {}
    return { vorname: 'Namensgleichheit gefunden, bitte zusätzlich den Vornamen angeben!' }
  }

  const patientenVornameMatches = patientenNachnameMatches.filter((patient) => {
    return patient.vorname.toLowerCase().trim() === values.vorname.toLowerCase().trim()
  })
  if (patientenVornameMatches.length && values.vorname.trim() && !values.geburtsdatum) {
    if (values.id) return {}
    return { geburtsdatum: 'Namensgleichheit gefunden, bitte zusätzlich das Geburtsdatum angeben!' }
  }

  const patientenGeburtsdatumMatches = patientenVornameMatches.filter((patient) => {
    const date1 = new Date(patient.geburtsdatum)
    const date2 = new Date(values.geburtsdatum)
    return isSameDay(date1, date2)
  })
  if (values.id && patientenGeburtsdatumMatches.length === 1 && patientenGeburtsdatumMatches[0].id === values.id) {
    return {}
  } else if (patientenGeburtsdatumMatches.length === 1) {
    return {
      geburtsdatum: (
        <span>
          Patient existiert bereits! (
          <Link to={`/${serverHash}/contacts/customers/${patientenGeburtsdatumMatches[0].id}`}>
            zur Patientenkartei
          </Link>
          )
        </span>
      ),
    }
  } else if (patientenGeburtsdatumMatches.length > 1) {
    return {
      geburtsdatum: (
        <span>
          Patient existiert bereits mehrfach! Bitte prüfen Sie die (
          <Link to={`/${serverHash}/contacts/customers/`}>Patientenliste</Link>)!
        </span>
      ),
    }
  }

  return {}
}
