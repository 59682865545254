import { Divider, IconButton, Typography } from '@material-ui/core'
import { FC } from 'react'
import { FacebookIcon } from '../../../shared/components/FacebookIcon/FacebookIcon'
import { InstagramIcon } from '../../../shared/components/InstagramIcon/InstagramIcon'
import { StyledInfo } from './StyledInfo'

interface Props {
  className?: string
  openChangelogHistoryDialog: () => unknown
}

const smMapping = {
  facebook: 'https://www.facebook.com/pododesk',
  instagram: 'https://www.instagram.com/pododesk/',
}

const Info: FC<Props> = ({ className = '', openChangelogHistoryDialog = () => {} }): JSX.Element => {
  const smRedirect = (platform: string) => {
    window.open(smMapping[platform], '_blank')
  }

  return (
    <StyledInfo className={className} square>
      <div className="contentBlock">
        <Typography variant="h5">Rechnungserstellung</Typography>
        <Typography>
          Entdecken Sie{' '}
          <a
            href="https://pododesk.de/funktionen/smarte-rechnungserstellung/"
            target="_blank"
            rel="noreferrer"
            className="link"
          >
            hier
          </a>{' '}
          die smarte Rechnungserstellung über pododesk
        </Typography>
      </div>
      <Divider />
      <div className="contentBlock">
        <Typography variant="h5">Hilfe</Typography>
        <Typography>
          Anleitungen, Hilfeartikel und Antworten auf häufige Fragen finden Sie in&nbsp;
          <a href="http://support.pododesk.de" target="_blank" rel="noreferrer" className="link">
            unserer Wissendatenbank
          </a>
          .
        </Typography>
      </div>
      <Divider />
      <div className="contentBlock">
        <Typography variant="h5">Datenbereinigung</Typography>
        <Typography>
          Schaffen Sie mit unserer{' '}
          <a target="_blank" rel="noreferrer" href="https://pododesk.de/planmodelle/datenbereinigung/" className="link">
            Datenbereinigung
          </a>{' '}
          Platz in Ihrer Patient:innen-Kartei.
        </Typography>
      </div>
      <Divider />
      <div className="contentBlock">
        <Typography variant="h5">pododesk-Community</Typography>
        <Typography>
          Tauschen Sie sich in&nbsp;
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/plugins/group/join/popup/?group_id=1709253566064810&source=email_campaign_plugin"
            className="link"
          >
            unserer Community
          </a>
          &nbsp;mit anderen pododesk-Usern aus und stimmen Sie über neue Entwicklungsfeatures ab.
        </Typography>
      </div>
      <Divider />
      <div className="contentBlock">
        <Typography variant="h5">Bisherige Updates</Typography>
        <Typography>
          Eine Übersicht über die bisherigen Updates und Infos finden Sie&nbsp;
          <a onClick={openChangelogHistoryDialog} className="link">
            hier
          </a>
          &nbsp; zum Nachlesen.
        </Typography>
      </div>
      <Divider />
      <div className="contentBlock">
        <Typography variant="h5">Funktionen</Typography>
        <Typography>
          Eine Übersicht aller Funktionen und Vorteile finden Sie in unserem&nbsp;
          <a target="_blank" rel="noreferrer" href="https://pododesk.de/funktionen" className="link">
            pododesk Online-Shop
          </a>
          &nbsp;.
        </Typography>
      </div>
      <Divider />
      <div className="contentBlock">
        <Typography variant="h5" paragraph>
          Folgen Sie uns
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
          <IconButton onClick={() => smRedirect('facebook')} edge="start">
            <FacebookIcon fontSize="large" />
          </IconButton>
          <IconButton onClick={() => smRedirect('instagram')} edge="end">
            <InstagramIcon fontSize="large" />
          </IconButton>
        </div>
      </div>
    </StyledInfo>
  )
}

export default Info
