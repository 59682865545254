import { fade } from '@material-ui/core/styles'
import styled from 'styled-components'

export const StyledFilePreview = styled.div`
  ${({ theme }) => `
    user-select: none;
    display: flex;
    flex-direction: row;
    max-height: 50px;
    align-items: center;

    .previewThumbnail {
      margin: 4px 8px 4px 4px;
      max-width: 50px;
    }

    .previewTextArea {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .textTypography {
      justify-self: center;
      margin: 0;

      &.status {
        color: ${fade(theme.palette.text.primary, 0.3)};
      }
    }
  `}
`
