import * as types from '../constants/actionTypes'
import makeActionCreator from '../shared/redux/utils/makeActionCreator'
import { fetchSecure } from '../shared/utils/auth'
import { datesToStrings } from '../shared/utils/dates'
import { RequestConfig } from './interface'

export const selectPatient = makeActionCreator(types.SELECT_CUSTOMER, 'patient')

export function loadCustomer(customerId, force): RequestConfig {
  return {
    types: [types.LOAD_CUSTOMER, types.LOAD_CUSTOMER_SUCCESS, types.LOAD_CUSTOMER_FAILURE],
    requiresAuth: true,
    // shouldCallAPI: ( state ) => state.ids.dates.length <= 0,
    shouldCallAPI: (state): boolean => {
      return typeof state.entities.patienten[customerId] === 'undefined' || force
    },
    callAPI: ({ apiUrl, auth0Credentials }): void => {
      return fetchSecure(
        `${apiUrl}/patienten/${customerId}`,
        {
          credentials: 'include', // send credentials like cookies & basic auth
        },
        auth0Credentials,
      )
    },
    payload: {},
  }
}

export function loadCustomers(): RequestConfig {
  return {
    types: [types.LOAD_CUSTOMERS, types.LOAD_CUSTOMERS_SUCCESS, types.LOAD_CUSTOMERS_FAILURE],
    requiresAuth: true,
    // shouldCallAPI: ( state ) => state.ids.dates.length <= 0,
    shouldCallAPI: (): boolean => true,
    callAPI: ({ apiUrl, auth0Credentials }): void => {
      const URL = `${apiUrl}/patienten`
      return fetchSecure(
        URL,
        {
          credentials: 'include', // send credentials like cookies & basic auth
        },
        auth0Credentials,
      )
    },
    payload: {},
  }
}

export function hasPatientsRemaining(): RequestConfig {
  return {
    types: [types.HAS_PATIENTS_REMAINING, types.HAS_PATIENTS_REMAINING_SUCCESS, types.HAS_PATIENTS_REMAINING_FAILURE],
    requiresAuth: true,
    shouldCallAPI: (): boolean => true,
    callAPI: ({ apiUrl, auth0Credentials }): void => {
      const URL = `${apiUrl}/patienten/remaining`
      return fetchSecure(
        URL,
        {
          credentials: 'include', // send credentials like cookies & basic auth
        },
        auth0Credentials,
      )
    },
    payload: {},
  }
}

export function loadCustomersAlphabetically(char): RequestConfig {
  return {
    types: [
      types.LOAD_CUSTOMERS_ALPHABETICALLY,
      types.LOAD_CUSTOMERS_ALPHABETICALLY_SUCCESS,
      types.LOAD_CUSTOMERS_ALPHABETICALLY_FAILURE,
    ],
    requiresAuth: true,
    shouldCallAPI: (): boolean => true,
    callAPI: ({ apiUrl, auth0Credentials }): void => {
      const URL = `${apiUrl}/patienten/alphabetically/${char}`
      return fetchSecure(
        URL,
        {
          credentials: 'include', // send credentials like cookies & basic auth
        },
        auth0Credentials,
      )
    },
    payload: { char },
  }
}

export function findCustomers(searchText, fieldsToSearch, limit): RequestConfig {
  return {
    types: [types.FIND_CUSTOMERS, types.FIND_CUSTOMERS_SUCCESS, types.FIND_CUSTOMERS_FAILURE],
    requiresAuth: true,
    shouldCallAPI: (): boolean => {
      return true
    },
    callAPI: ({ apiUrl, auth0Credentials }): void => {
      const url = `${apiUrl}/patienten/find`
      return fetchSecure(
        url,
        {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
          },
          body: JSON.stringify({ searchText, fieldsToSearch, limit }),
          credentials: 'include', // send credentials like cookies & basic auth
        },
        auth0Credentials,
      )
    },
    payload: { searchText, fieldsToSearch },
  }
}

export const selectCustomer = makeActionCreator(types.SELECT_CUSTOMER, 'patient')
export const filterCustomersLastNameBeginsWith = makeActionCreator(types.FILTER_CUSTOMERS_LASTNAME_BEGINS_WITH, 'char')
export const filterDeceased = makeActionCreator(types.FILTER_DECEASED, 'deceased')
export const filterLeavers = makeActionCreator(types.FILTER_LEAVERS, 'leavers')

export function createCustomer(data, pdCallRedirect = true): RequestConfig {
  return {
    types: [types.CREATE_CUSTOMER, types.CREATE_CUSTOMER_SUCCESS, types.CREATE_CUSTOMER_FAILURE],
    requiresAuth: true,
    //    shouldCallAPI: ( state ) => true,
    shouldCallAPI: (): boolean => {
      return true
    },
    callAPI: ({ apiUrl, auth0Credentials }): void => {
      const { ...other } = data // eslint-disable-line no-unused-vars
      other.frequenz = isNaN(parseInt(other.frequenz, 10)) ? null : parseInt(other.frequenz, 10)
      const URL = `${apiUrl}/patienten`
      return fetchSecure(
        URL,
        {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
          },
          body: JSON.stringify(datesToStrings(other)),
          credentials: 'include', // send credentials like cookies & basic auth
        },
        auth0Credentials,
      )
    },
    payload: { ...data, pdCallRedirect },
  }
}

export function updateCustomer(data, notification): RequestConfig {
  return {
    types: [types.UPDATE_CUSTOMER, types.UPDATE_CUSTOMER_SUCCESS, types.UPDATE_CUSTOMER_FAILURE],
    requiresAuth: true,
    //    shouldCallAPI: ( state ) => true,
    shouldCallAPI: (): boolean => {
      return true
    },
    callAPI: ({ apiUrl, auth0Credentials }): void => {
      const { id, ...other } = data
      other.frequenz = isNaN(parseInt(other.frequenz, 10)) ? null : parseInt(other.frequenz, 10)
      const URL = `${apiUrl}/patienten/${id}`
      return fetchSecure(
        URL,
        {
          method: 'PUT',
          headers: {
            'Content-type': 'application/json',
          },
          body: JSON.stringify(datesToStrings(other)),
          credentials: 'include', // send credentials like cookies & basic auth
        },
        auth0Credentials,
      )
    },
    payload: { ...data, notification },
  }
}

export function getTreatmentHistory(patientId): RequestConfig {
  return {
    types: [types.GET_TREATMENT_HISTORY, types.GET_TREATMENT_HISTORY_SUCCESS, types.GET_TREATMENT_HISTORY_FAILURE],
    requiresAuth: true,
    shouldCallAPI: (): boolean => true,
    callAPI: ({ apiUrl, auth0Credentials }): void => {
      const URL = `${apiUrl}/patienten/${patientId}/history`

      return fetchSecure(
        URL,
        {
          method: 'GET',
          credentials: 'include',
        },
        auth0Credentials,
      )
    },
  }
}
