import { Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { StyledIconHeading } from './StyledIconHeading';

interface Props {
  text: any;
  icon: any;
  className?: string;
  additionalElements?: React.ReactElement[];
}

const IconHeading: FC<Props> = ({ text, icon, className, additionalElements }): JSX.Element => (
  <StyledIconHeading className={className}>
    <div className="icon">{icon}</div>
    <Typography variant="body1" className="text">
      {text}
    </Typography>
    {!!additionalElements?.length && (
      <div className="additionalElements">
        {additionalElements.map((element) => element)}
      </div>
    )}
  </StyledIconHeading>
);

export default IconHeading;
