import { Button, Popover } from '@material-ui/core'
import { connect } from 'react-redux'
import styled from 'styled-components'
import TextExpandInput from '../../shared/components/InputField/TextExpandInput'
import { sHotspotAbbreviations } from '../Calendar/selectors'
import UploadContainer from './HotspotFileUpload/UploadContainer'

// const UploadContainer = styled.div`
//   display: flex;
//   flex-direction: row;
//   padding: 8px;
//   border: 1px dashed ${({ theme }) => theme.palette.primary.main};
//   align-items: center;
//   cursor: pointer;
//   transition: background-color 0.3s ease;

//   &:hover {
//     background-color: ${({ theme }) => theme.palette.primary.light};
//   }

//   & > * {
//     cursor: pointer;
//   }
// `

const UploadedFilesContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const ThumbnailContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-wrap: wrap;
`

const Thumbnail = styled.div`
  width: 100px;
  height: 100px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 4px;
  overflow: hidden;
  position: relative;

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  & .fallback {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background: ${({ theme }) => theme.palette.grey[100]};
    padding: 8px;
    text-align: center;
    word-break: break-word;
  }
`

const DeleteButton = styled(Button)`
  position: absolute;
  right: 4px;
  bottom: 4px;
  min-width: 32px;
  width: 32px;
  height: 32px;
  padding: 4px;
  opacity: 0;
  transition: opacity 0.2s ease;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;

  &:hover {
    background: rgba(0, 0, 0, 0.7);
  }
`

const ThumbnailWrapper = styled.div`
  position: relative;
  display: flex;
  gap: 8px;
  align-items: center;

  &:hover ${DeleteButton} {
    opacity: 1;
  }
`

interface AddNoteProps {
  anchorEl: HTMLElement | null
  selectedSpot: any
  onClose: () => void
  fields?: any
  inputValue: string
  filesValue: number[]
  onInputChange: any
  onFilesChange: any
  onDeleteFile: (fileName: string) => void
}

const fields = ['note', 'files']

const AddNote = ({
  anchorEl,
  selectedSpot,
  onClose,
  inputValue,
  filesValue,
  onInputChange,
  onFilesChange,
  onDeleteFile,
  hotspotAbbreviations,
  abbreviationsList,
  confirmHotspotValuesChange,
}: AddNoteProps) => {
  return (
    <Popover
      open={Boolean(selectedSpot)}
      anchorEl={anchorEl}
      onClose={() => {
        confirmHotspotValuesChange(selectedSpot, inputValue)
        onClose()
      }}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
    >
      <div style={{ padding: 16, paddingBottom: 0, fontWeight: 'bolder', fontSize: '1rem' }}>
        Behandlungsdokumentation
      </div>
      {selectedSpot && (
        <div
          style={{ padding: '16px', display: 'flex', flexDirection: 'column', gap: 16, minWidth: 500, maxWidth: 500 }}
        >
          <TextExpandInput
            variant="outlined"
            rows={3}
            label={`${selectedSpot.abbreviation} (${selectedSpot.description})`}
            value={inputValue}
            onChange={onInputChange}
            multiline
            textExpand
            abbreviations={abbreviationsList}
            // disabled={isReadOnly() || isCalendarPlan}
          />
          <UploadContainer fileIds={filesValue} onChange={onFilesChange} />
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              confirmHotspotValuesChange(selectedSpot, inputValue)
              onClose()
            }}
          >
            Speichern
          </Button>
        </div>
      )}
    </Popover>
  )
}

const mapStateToProps = (state) => ({
  hotspotAbbreviations: sHotspotAbbreviations(state),
})

export default connect(mapStateToProps)(AddNote)
