import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  type DialogProps,
} from '@material-ui/core'

interface ExportDataDialogProps extends Omit<DialogProps, 'onClose'> {
  onCloseFunc: () => void
  onRequestExport: () => void
}

export const ExportDataDialog = ({
  open,
  onCloseFunc,
  onRequestExport,
  ...props
}: ExportDataDialogProps): JSX.Element => {
  const handleRequestExport = () => {
    onRequestExport()
    onCloseFunc()
  }

  return (
    <Dialog {...props} open={open} onClose={onCloseFunc}>
      <DialogTitle>Praxisdaten exportieren</DialogTitle>
      <DialogContent>
        <Typography paragraph variant="body1">
          Um die Daten Ihrer Praxis zu exportieren, klicken Sie bitte auf den Button „Export anfordern“.
        </Typography>
        <Typography paragraph variant="body1">
          Sobald der Vorgang abgeschlossen ist, senden wir Ihnen eine E-Mail mit einem Link, über den Sie das Archiv
          bequem herunterladen können. Bitte beachten Sie, dass dies je nach Datenmenge einige Stunden in Anspruch
          nehmen kann. Der Export wird im Hintergrund ausgeführt, welches Ihnen ein reibungsloses Weiterarbeiten ermöglicht.
        </Typography>
        <Typography paragraph variant="body1">
          Wir bedanken uns für Ihr Verständnis!
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button type="button" variant="text" color="secondary" onClick={onCloseFunc}>
          Abbrechen
        </Button>
        <Button type="button" color="secondary" variant="contained" onClick={handleRequestExport}>
          Export anfordern
        </Button>
      </DialogActions>
    </Dialog>
  )
}
