import styled from 'styled-components'

export const StyledIconHeading = styled.div`
  ${({ theme }) => `
    background-color: ${theme.palette.primary.dark};
    display: flex;
    flex-direction: row;
    height: 32px;
    padding-left: 16px;
    align-items: center;
    line-height: 0;

    .text {
      margin-left: 16px;
      font-size: medium;
      > span {
        display: flex;
      }
    }

    .icon {
      display: inline-flex;
    }

    .link {
      color: ${theme.palette.secondary.main};
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    .additionalElements {
      display: flex;
      margin-left: auto;
      }
  `}
`
