import { Button, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import { StyledDialog } from './StyledDialog'

interface Props {
  open: boolean
  termin: any
  onCloseFunc: (...params) => any
  createInvoice: (id: string) => any
}

export const NewInvoiceDialog = ({ open, onCloseFunc, termin, createInvoice }: Props): JSX.Element => {
  const handleDialogClose = (): void => {
    onCloseFunc()
  }

  const handleDialogClick = () => {
    createInvoice(termin)
    onCloseFunc()
  }

  return (
    <StyledDialog open={open}>
      <DialogTitle className="dialogTitle">Neuen Rechnungsentwurf erstellen</DialogTitle>
      <DialogContent className="dialogContent">
        <Typography variant="body1" gutterBottom>
          Der Termin oder Patientendaten wurden nach Rechnungserstellung geändert.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Möchten Sie jetzt einen neuen Rechnungsentwurf erstellen?
        </Typography>
      </DialogContent>
      <DialogActions className="dialogActions">
        <Button variant="outlined" type="button" color="secondary" onClick={handleDialogClose}>
          Verstanden
        </Button>
        <Button variant="contained" type="button" color="secondary" onClick={handleDialogClick}>
          Rechnungsentwurf erstellen
        </Button>
      </DialogActions>
    </StyledDialog>
  )
}
