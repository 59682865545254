import { Button, CircularProgress, ListItemIcon, MenuItem, Box, Tooltip, Typography } from '@material-ui/core'
import {
  AddCircle as AddCircleIcon,
  ArrowRight as ArrowRightIcon,
  Assignment as AssignmentIcon,
  Cancel as CancelIcon,
  Delete as DeleteIcon,
  Description as DescriptionIcon,
  Eco as EcoIcon,
  EuroSymbol as EuroSymbolIcon,
  Event as EventIcon,
  InsertDriveFile as InsertDriveFileIcon,
  NoteAdd as NoteAddIcon,
  Note as NoteIcon,
  Person as PersonIcon,
  Redo as RedoIcon,
  Refresh as RefreshIcon,
  SwapHoriz as SwapHorizIcon,
  Visibility as VisibilityIcon,
  Warning as WarningIcon,
} from '@material-ui/icons'
import { addWeeks, endOfDay, format, isAfter, isBefore, isValid, isFuture, parseISO, subSeconds } from 'date-fns'
import { cloneDeep, stubTrue } from 'lodash'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import shortid from 'shortid'
import * as patientenActions from '../../actions/customers'
import * as datesActions from '../../actions/dates'
import { selectTerminProps, undoCancelDate } from '../../actions/dates'
import * as storeDialogActions from '../../actions/dialogs'
import * as heilmittelverordnungenActions from '../../actions/heilmittelverordnungen'
import { clearNotification, showNotification } from '../../actions/notification'
import { ISO8601DATE_FNS } from '../../constants/dateFormats'
import { MessageError, MessageSuccess } from '../../shared/components/Message'
import { apiServerHashSelector, fetchSecure, sApiServer, sCredentials } from '../../shared/utils/auth'
import { plan } from '../../shared/utils/constants'
import { getApiUrl } from '../../utils/auth'
import { isReadOnly } from '../../utils/helpers'
import { sTerminHatZahlung } from './selectors'
import { StyledListItemText } from './StyledListItemText'
import { StyledMenuTermin } from './StyledMenuTermin'
import { Dialog } from '../SevdeskDialog/Dialog'
import { NewInvoiceDialog } from '../SevdeskDialog/NewInvoiceDialog'
import { praxisstammdatenSelector } from '../../selectors/selectors'

const terminAbsageNotifications = {
  request: 'Terminabsage wird gespeichert...',
  success: <MessageSuccess message="Terminabsage erfolgreich gespeichert." />,
  failure: <MessageError message="Speichern der Terminabsage fehlgeschlagen!" />,
}

const terminAbsageOrganisatorischNotifications = () => ({
  request: 'Terminabsage wird gespeichert...',
  success: (
    <MessageSuccess message="Organisatorischer Termin gelöscht" />
    /* Below line was commented out for testing. It SHOULD be able to be re-enabled again.
        This is the button in the snackbar for undoing deletion of an organizational appointment */
    /* <ActionLink actionCreator={() => datesActions.undoCancelDate(date)}>rückgängig</ActionLink> */
  ),
  failure: <MessageError message="Speichern der Terminabsage fehlgeschlagen!" />,
})

const MenuItemsTermin = ({
  api,
  actions,
  selectedTermin,
  termine,
  patienten,
  heilmittelverordnungen,
  anamnesen,
  terminHatZahlung,
  onCloseFunc,
  calendarView,
  hvoView,
  dialogActions,
  currentServer,
  praxisstammdaten,
}) => {
  const [subMenuOpen, setSubMenuOpen] = useState<boolean>(false)
  const [subMenuAnchorEl, setSubMenuAnchorEl] = useState<any>(null)
  const [appointmentAdjustedAfterInvoice, setAppointmentAdjustedAfterInvoice] = useState(false)
  const termin = termine[selectedTermin]
  const hvoTermine = !!termin?.heilmittelverordnung
    ? Object.values(termine).filter(
        (t) =>
          t.heilmittelverordnung === termin.heilmittelverordnung && (t.absagegrund === null || t.absagegrund === ''),
      )
    : []
  const hvo = termin?.heilmittelverordnung ? heilmittelverordnungen[termin.heilmittelverordnung] : null
  const patient = termin ? patienten[termin.patient] : null

  const navigate = useNavigate()
  const { serverHash } = useParams()

  const isCalendarPlan =
    currentServer?.ownerPlanId && plan.find((p) => p.id === currentServer.ownerPlanId)?.name === 'calendar'

  const closeMenu = () => {
    setSubMenuOpen(false)
    setSubMenuAnchorEl(null)
    onCloseFunc()
  }

  const openSubMenu = (event) => {
    setSubMenuOpen(true)
    setSubMenuAnchorEl(event.currentTarget)
  }

  const closeSubMenu = () => {
    setSubMenuOpen(false)
    setSubMenuAnchorEl(null)
  }

  const anamnesenDesPatienten = () =>
    Object.keys(anamnesen)
      .map((key) => anamnesen[key])
      .filter((anamnese) => anamnese.patient === termin.patient)

  const openTerminAbsageDialog = (termin) => {
    closeMenu()
    dialogActions?.openTerminAbsageDialog(termin, hvoTermine, hvo)
  }

  const openTerminAbrechnenDialog = (termin) => {
    closeMenu()
    dialogActions?.openTerminAbrechnenDialog(termin)
  }

  const updateTerminOhneAbsageNichtErschienen = (termin) => {
    closeMenu()
    if (!termin || !termin.id) {
      return
    }

    actions.termine.updateDate(
      {
        ...termin,
        absagegrund: 'ohne Absage nicht erschienen',
      },
      terminAbsageNotifications,
    )
  }

  const updateTerminOrganisatorischerTerminAbgesagt = (termin) => {
    closeMenu()
    if (!termin || !termin.id) {
      return
    }

    actions.termine.updateDate(
      {
        ...termin,
        absagegrund: 'Dieser organisatorische Termin wurde abgesagt.',
      },
      terminAbsageOrganisatorischNotifications(termin),
    )
  }

  const updateTerminVerschiebung = async (termin, absagegrund) => {
    closeMenu()

    if (!termin || !termin.id || !absagegrund) {
      return
    }

    actions.termine.selectTerminProps(termin)

    await actions.termine.updateDate(
      {
        ...termin,
        absagegrund,
        absagedatum: new Date(),
      },
      {
        request: 'Markiere Termin als abgesagt...',
        success: 'Termin abgesagt, wählen Sie einen Zeitpunkt für den neuen Termin!',
        failure: <MessageError message="Speichern der Terminabsage fehlgeschlagen!" />,
        action: (
          <Button variant="contained" color="primary" onClick={() => handleCancelAppointment(termin)}>
            Abbrechen
          </Button>
        ),
        timer: 150000,
        hidesOnLocationChange: true,
      },
    )

    navigate(`/${serverHash}/calendar/free`)
  }

  const openEditDateDialog = (date) => {
    closeMenu()
    actions.dialogs.openEditTerminDialog(date)
  }

  const viewPatient = (termin) => {
    closeMenu()
    navigate(`/${serverHash}/contacts/customers/${termin.patient}`)
  }

  const handleCancelAppointment = (termin) => {
    actions.selectTerminPropAction({})
    navigate(termin?.url || `/${serverHash}/calendar/free`)

    actions.clearNotificationAction()

    if (termin?.absagegrund === null) {
      const cancelledDate = Object.assign({}, termin)
      delete cancelledDate.url
      actions.undoCancelDateAction(cancelledDate)
    }

    setTimeout(() => {
      actions.showNotification({
        message: <MessageSuccess message="Terminvereinbarung abgebrochen." />,
        busy: false,
        error: false,
        timer: 3000,
      })
    }, 0)
  }

  const addFolgetermin = (termin) => {
    termin.begruendungFrequenzueberschreitung = null

    let terminClone = cloneDeep(termin)

    // Unselect "Eingangsbefundung" if it was selected in the previous appointment
    if (terminClone?.leistungen?.some((l) => l.positionsnummer === 78040)) {
      terminClone = {
        ...terminClone,
        leistungen: terminClone.leistungen.filter((l) => l.positionsnummer !== 78040),
      }
    }

    actions.termine.selectTerminProps(terminClone)
    closeMenu()
    const heilmittelverordnung = termin.heilmittelverordnung && heilmittelverordnungen[terminClone.heilmittelverordnung]
    const patient = patienten[terminClone.patient]
    let frequenz = 0
    if (heilmittelverordnung) {
      frequenz = heilmittelverordnung.minFrequenz
    } else if (patient.frequenz) {
      frequenz = patient.frequenz
    }
    const dateFolgeTermin = addWeeks(terminClone.beginn, frequenz)
    actions.termine.selectDay(dateFolgeTermin)
    actions.showNotification({
      action: (
        <Button variant="contained" color="primary" onClick={() => handleCancelAppointment(terminClone)}>
          Abbrechen
        </Button>
      ),
      message: <MessageSuccess message="Daten für Folgetermin gespeichert. Bitte Termin wählen.&nbsp;" />,
      busy: false,
      error: false,
      timer: 150000,
      hidesOnLocationChange: true,
    })
    navigate(`/${serverHash}/calendar/free`)
  }

  const addTermin = (termin) => {
    actions.termine.selectTerminProps(termin)
    closeMenu()
    navigate(`/${serverHash}/calendar/free`)
  }

  const viewInCalendar = (termin) => {
    closeMenu()
    if (termin) {
      actions.termine.selectDay(termin.beginn)
      navigate(`/${serverHash}/calendar/day/${format(termin.beginn, ISO8601DATE_FNS)}`)
    }
  }

  const openDokumentation = (termin) => {
    closeMenu()
    if (termin) {
      navigate(`/${serverHash}/documentation/${termin.patient}/${termin.id}`)
    }
  }

  const goToAnamnese = (termin) => {
    closeMenu()
    navigate(`/${serverHash}/anamnesis/${termin.patient}/01`)
  }

  const viewHVO = (termin) => {
    closeMenu()
    navigate(`/${serverHash}/hvo/${termin.heilmittelverordnung}`)
  }

  const addHvo = (termin) => {
    closeMenu()
    navigate(`/${serverHash}/hvo/add/${termin.patient}`)
  }

  const getAppointmentPDF = async (termin) => {
    closeMenu()
    const { apiUrl, auth0Credentials } = api
    const URL = `${apiUrl}/termine/getAppointmentPDF/${termin.patient}`
    actions.showNotification('Terminzettel wird in neuem Tab geöffnet. Bitte Pop-Up-Blocker beachten!')
    const response = await fetchSecure(URL, { credentials: 'include' }, auth0Credentials)
    const pdfBlob = await response.blob()
    const fileUrl = window.URL.createObjectURL(pdfBlob)
    window.open(fileUrl)
  }

  const [dialogData, setDialogData] = useState<{
    open: boolean
    loading: boolean
    content?: string
    title?: string
    additionalButton?: {
      type: string
      link: string
    }
  }>({ open: false, loading: true })

  const [newInvoiceDialogData, setNewInvoiceDialogData] = useState<{
    open: boolean
  }>({ open: false })

  const closeDialog = () => {
    setDialogData({
      open: false,
      loading: false,
    })
    closeMenu()
  }

  const closeNewInvoiceDialog = () => {
    setNewInvoiceDialogData({ open: false })
  }

  const createInvoice = async (termin) => {
    const { apiUrl, auth0Credentials } = api
    const URL = `${apiUrl}/sevdesk/invoice/create/${termin.id}`

    setDialogData({
      open: true,
      loading: true,
    })

    try {
      const response = await fetchSecure(URL, { method: 'POST', credentials: 'include' }, auth0Credentials)
      const data = await response.json()

      if (data.id) {
        await actions.termine.updateDate(
          {
            ...termin,
            sevdeskinvoiceId: data.id,
          },
          {
            request: 'Termin aktualisiert...',
            success: <MessageSuccess message="Termin erfolgreich aktualisiert." />,
            failure: <MessageError message="Aktualisierung des Termins fehlgeschlagen!" />,
          },
        )
      }

      setDialogData({
        open: true,
        loading: false,
        title: data.title,
        content: data.message,
        additionalButton: data.additionalButton,
      })
    } catch (error) {
      console.error(error)
    }
  }

  const showInvoice = () => {
    window.open(`https://my.sevdesk.de/fi/edit/type/RE/id/${termin.sevdeskinvoiceId}`, '_blank')
  }

  const getInvoice = async () => {
    const { apiUrl, auth0Credentials } = api
    const URL = `${apiUrl}/sevdesk/invoice/get/${termin.sevdeskinvoiceId}`

    try {
      const response = await fetchSecure(URL, { method: 'GET', credentials: 'include' }, auth0Credentials)
      const invoice = await response.json()
      return invoice[0]
    } catch (error: any) {
      console.error('Fehler beim abrufen der Rechnung: ', error)
    }
  }

  const compareInvoiceAndAppointment = async () => {
    const invoice = await getInvoice()
    const invoiceCreateDate = parseISO(invoice.create)

    if (
      isAfter(subSeconds(new Date(termin.modifiedAt), 2), invoiceCreateDate) ||
      isAfter(new Date(patient.modifiedAt), invoiceCreateDate)
    ) {
      setAppointmentAdjustedAfterInvoice(true)
    } else {
      setAppointmentAdjustedAfterInvoice(false)
    }
  }

  const handleGenerateNewIncoice = async () => {
    const invoice = await getInvoice()
    const invoiceStatus = invoice.status

    if (!invoice) return

    if (invoiceStatus == 100 || invoiceStatus == 1000) {
      setNewInvoiceDialogData({
        open: true,
      })
    } else {
      setDialogData({
        open: true,
        loading: false,
        title: 'Rechnungsentwurf kann nicht erstellt werden',
        content:
          'Aufgrund einer offenen Rechnung in sevdesk kann kein neuer Rechnungsentwurf erstellt werden – bitte stornieren Sie zuerst die vorhandene Rechnung in sevdesk, um fortzufahren.',
        additionalButton: {
          type: 'invoice',
          link: `https://my.sevdesk.de/fi/edit/type/RE/id/${invoice.id}`,
        },
      })
    }
  }

  const terminHasInvoice = termin ? termin.sevdeskinvoiceId : null

  if (terminHasInvoice) {
    compareInvoiceAndAppointment()
  }

  if (!termin) return null

  const now = new Date()
  const endOfToday = endOfDay(now)

  const terminAbsagenVerschiebenItems: JSX.Element[] = []

  const terminIstAbgesagt = typeof termin.absagegrund === 'string' && termin.absagegrund !== ''
  const terminIstOrganisatorisch = termin.istOrganisatorisch

  if (!terminIstOrganisatorisch && !terminIstAbgesagt) {
    if (isValid(termin.beginn) && isBefore(termin.beginn, now)) {
      terminAbsagenVerschiebenItems.push(
        <MenuItem key={shortid.generate()} dense onClick={() => updateTerminOhneAbsageNichtErschienen(termin)}>
          <ListItemIcon>
            <CancelIcon color="secondary" />
          </ListItemIcon>
          <StyledListItemText primary="Patient nicht erschienen (noshow)" />
        </MenuItem>,
      )
    }

    terminAbsagenVerschiebenItems.push(
      <MenuItem key={shortid.generate()} dense onClick={() => openTerminAbsageDialog(termin)}>
        <ListItemIcon>
          <WarningIcon color="secondary" />
        </ListItemIcon>
        <StyledListItemText primary="Terminabsage" />
      </MenuItem>,
      <MenuItem
        key={shortid.generate()}
        dense
        onClick={() => updateTerminVerschiebung(termin, 'Patient musste verschieben')}
      >
        <ListItemIcon>
          <RedoIcon color="secondary" />
        </ListItemIcon>
        <StyledListItemText primary="Patient muss verschieben" />
      </MenuItem>,
      <MenuItem
        key={shortid.generate()}
        dense
        onClick={() => updateTerminVerschiebung(termin, 'Praxis musste verschieben')}
      >
        <ListItemIcon>
          <RedoIcon color="secondary" />
        </ListItemIcon>
        <StyledListItemText primary="Praxis muss verschieben" />
      </MenuItem>,
    )
  }

  return (
    <div>
      <MenuItem dense onClick={() => openEditDateDialog(termin)}>
        <ListItemIcon>
          <VisibilityIcon color="secondary" />
        </ListItemIcon>
        <StyledListItemText
          primary={!terminIstAbgesagt && !isReadOnly() ? 'Termin anzeigen & bearbeiten' : 'Termin anzeigen'}
        />
      </MenuItem>
      {!calendarView && !terminIstAbgesagt && (
        <MenuItem dense onClick={() => viewInCalendar(termin)}>
          <ListItemIcon>
            <EventIcon color="secondary" />
          </ListItemIcon>
          <StyledListItemText primary="Termin im Kalender anzeigen" />
        </MenuItem>
      )}
      {calendarView && !terminIstOrganisatorisch && (
        <MenuItem dense onClick={() => viewPatient(termin)}>
          <ListItemIcon>
            <PersonIcon color="secondary" />
          </ListItemIcon>
          <StyledListItemText primary="Patientenkartei anzeigen" />
        </MenuItem>
      )}
      {terminIstOrganisatorisch && (
        <MenuItem dense onClick={() => updateTerminOrganisatorischerTerminAbgesagt(termin)}>
          <ListItemIcon>
            <DeleteIcon color="secondary" />
          </ListItemIcon>
          <StyledListItemText primary="Termin löschen" />
        </MenuItem>
      )}
      {!terminIstOrganisatorisch && (
        <>
          {!!anamnesenDesPatienten().length && isReadOnly() && (
            <MenuItem dense onClick={() => goToAnamnese(termin)}>
              <ListItemIcon>
                <AssignmentIcon color="secondary" />
              </ListItemIcon>
              <StyledListItemText primary="Anamnese anzeigen" />
            </MenuItem>
          )}
          {!terminIstAbgesagt && !isReadOnly() && !isCalendarPlan && (
            <MenuItem dense onClick={() => goToAnamnese(termin)}>
              <ListItemIcon>
                <AssignmentIcon color="secondary" />
              </ListItemIcon>
              <StyledListItemText
                primary={anamnesenDesPatienten().length === 0 ? 'Anamnese erstellen' : 'Anamnese anzeigen'}
              />
            </MenuItem>
          )}
          {termin.heilmittelverordnung && !hvoView && (
            <MenuItem dense onClick={() => viewHVO(termin)}>
              <ListItemIcon>
                <EcoIcon color="secondary" />
              </ListItemIcon>
              <StyledListItemText primary="HVO anzeigen" />
            </MenuItem>
          )}
          {!termin.heilmittelverordnung && !terminIstAbgesagt && !isReadOnly() && !isCalendarPlan && (
            <MenuItem dense onClick={() => addHvo(termin)}>
              <ListItemIcon>
                <EcoIcon color="secondary" />
              </ListItemIcon>
              <StyledListItemText primary="neue HVO erfassen" />
            </MenuItem>
          )}
          {!terminIstAbgesagt && (
            <>
              {termin.dokumentation && (!!currentServer?.ownerPlanId || isReadOnly()) && (
                <MenuItem dense onClick={() => openDokumentation(termin)}>
                  <ListItemIcon>
                    <DescriptionIcon color="secondary" />
                  </ListItemIcon>
                  <StyledListItemText primary="Dokumentation anzeigen" />
                </MenuItem>
              )}
              {!termin.dokumentation &&
                isValid(termin.beginn) &&
                !isAfter(termin.beginn, endOfToday) &&
                !isReadOnly() &&
                !isCalendarPlan && (
                  <MenuItem dense onClick={() => openDokumentation(termin)}>
                    <ListItemIcon>
                      <NoteAddIcon color="secondary" />
                    </ListItemIcon>
                    <StyledListItemText primary="Dokumentation erstellen" />
                  </MenuItem>
                )}
              {terminHatZahlung && (
                <MenuItem dense onClick={() => openTerminAbrechnenDialog(termin)}>
                  <ListItemIcon>
                    <EuroSymbolIcon color="secondary" />
                  </ListItemIcon>
                  <StyledListItemText primary="Barzahlung anzeigen" />
                </MenuItem>
              )}
              {!terminHatZahlung && isValid(termin.beginn) && !isAfter(termin.beginn, endOfToday) && !isReadOnly() && (
                <MenuItem dense onClick={() => openTerminAbrechnenDialog(termin)}>
                  <ListItemIcon>
                    <EuroSymbolIcon color="secondary" />
                  </ListItemIcon>
                  <StyledListItemText primary="Barzahlung erfassen" />
                </MenuItem>
              )}
              {!isReadOnly() && (
                <>
                  <MenuItem dense onClick={(event) => openSubMenu(event)}>
                    <ListItemIcon>
                      <SwapHorizIcon color="secondary" />
                    </ListItemIcon>
                    <StyledListItemText primary="absagen / verschieben" />
                    <ArrowRightIcon />
                  </MenuItem>
                  <StyledMenuTermin
                    open={subMenuOpen}
                    anchorEl={subMenuAnchorEl}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    onClose={closeSubMenu}
                    PaperProps={{ className: 'paper' }}
                  >
                    <div>{terminAbsagenVerschiebenItems}</div>
                  </StyledMenuTermin>
                </>
              )}
              {!isReadOnly() && (
                <MenuItem dense onClick={() => addFolgetermin(termin)}>
                  <ListItemIcon>
                    <RefreshIcon color="secondary" />
                  </ListItemIcon>
                  <StyledListItemText primary="Folgetermin vereinbaren" />
                </MenuItem>
              )}
            </>
          )}
          {!calendarView && terminIstAbgesagt && !isReadOnly() && (
            <MenuItem dense onClick={() => addTermin(termin)}>
              <ListItemIcon>
                <AddCircleIcon color="secondary" />
              </ListItemIcon>
              <StyledListItemText primary="neuer Termin" />
            </MenuItem>
          )}
          {!isReadOnly() && (
            <MenuItem dense onClick={() => getAppointmentPDF(termin)}>
              <ListItemIcon>
                <NoteIcon color="secondary" />
              </ListItemIcon>
              <StyledListItemText primary="Terminzettel erstellen" />
            </MenuItem>
          )}

          {!isFuture(termin.beginn) && (
            <>
              {praxisstammdaten.sevDeskUser && (
                <>
                  {termin.sevdeskinvoiceId ? (
                    <>
                      {appointmentAdjustedAfterInvoice ? (
                        <>
                          <Tooltip
                            title="Termin oder Patientendaten wurden nach Rechnungserstellung geändert. Erstellen Sie hier einen aktualisierten Rechnungsentwurf mit den neuesten Informationen."
                            placement="top"
                          >
                            <MenuItem dense onClick={() => handleGenerateNewIncoice()}>
                              <ListItemIcon>
                                <NoteAddIcon color="secondary" />
                              </ListItemIcon>
                              <StyledListItemText primary="Neue Rechnung erstellen" />
                            </MenuItem>
                          </Tooltip>
                          <MenuItem dense onClick={() => showInvoice()}>
                            <ListItemIcon>
                              <InsertDriveFileIcon color="secondary" />
                            </ListItemIcon>
                            <StyledListItemText primary="Rechnung anzeigen" />
                          </MenuItem>
                        </>
                      ) : (
                        <MenuItem dense onClick={() => showInvoice()}>
                          <ListItemIcon>
                            <InsertDriveFileIcon color="secondary" />
                          </ListItemIcon>
                          <StyledListItemText primary="Rechnung anzeigen" />
                        </MenuItem>
                      )}
                    </>
                  ) : (
                    <MenuItem dense onClick={() => createInvoice(termin)}>
                      <ListItemIcon>
                        <InsertDriveFileIcon color="secondary" />
                      </ListItemIcon>
                      <StyledListItemText primary="Rechnung erstellen" />
                    </MenuItem>
                  )}
                </>
              )}
            </>
          )}

          <Dialog
            open={dialogData?.open}
            loading={dialogData?.loading}
            onCloseFunc={closeDialog}
            title={dialogData?.title}
            content={dialogData?.content}
            additionalButton={dialogData?.additionalButton}
          />
          <NewInvoiceDialog
            open={newInvoiceDialogData?.open}
            termin={termin}
            onCloseFunc={closeNewInvoiceDialog}
            createInvoice={createInvoice}
          />
        </>
      )}
    </div>
  )
}

const mapStateToProps = (state, props) => ({
  anamnesen: state.entities.anamnesen,
  heilmittelverordnungen: state.entities.heilmittelverordnungen,
  patienten: state.entities.patienten,
  termine: state.entities.termine,
  terminHatZahlung: sTerminHatZahlung(state, props),
  currentServer: sApiServer(state),
  api: {
    apiUrl: getApiUrl(apiServerHashSelector(state)),
    auth0Credentials: sCredentials(state),
  },
  praxisstammdaten: praxisstammdatenSelector(state),
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    termine: bindActionCreators(datesActions, dispatch),
    patienten: bindActionCreators(patientenActions, dispatch),
    dialogs: bindActionCreators(storeDialogActions, dispatch),
    heilmittelverordnungen: bindActionCreators(heilmittelverordnungenActions, dispatch),
    showNotification: bindActionCreators(showNotification, dispatch),
    clearNotificationAction: bindActionCreators(clearNotification, dispatch),
    undoCancelDateAction: bindActionCreators(undoCancelDate, dispatch),
    selectTerminPropAction: bindActionCreators(selectTerminProps, dispatch),
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(MenuItemsTermin)
