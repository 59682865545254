import { Button, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import { StyledDialog } from './StyledDialog'
import Skeleton from '@material-ui/lab/Skeleton'
import { useNavigate } from 'react-router-dom'

interface Props {
  open: boolean
  loading: boolean
  onCloseFunc: (...params) => any
  title?: string
  content?: string
  additionalButton?: {
    type: string
    link: string
  }
}

export const Dialog = ({ open, loading, onCloseFunc, title, content, additionalButton }: Props): JSX.Element => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(additionalButton?.link!)
  }

  const handleDialogClose = (): void => {
    onCloseFunc()
  }

  return (
    <StyledDialog open={open}>
      <DialogTitle className="dialogTitle">{loading ? <Skeleton width="60%" /> : title}</DialogTitle>
      <DialogContent className="dialogContent">
        {loading ? (
          <>
            <Skeleton variant="text" width="100%" />
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="text" width="90%" />
          </>
        ) : (
          <Typography variant="body1" gutterBottom>
            {content}
          </Typography>
        )}
      </DialogContent>
      <DialogActions className="dialogActions">
        {loading ? (
          <>
            <Skeleton variant="rect" width={100} height={36} />
            <Skeleton variant="rect" width={120} height={36} />
          </>
        ) : (
          <>
            <Button variant="outlined" type="button" color="secondary" onClick={handleDialogClose}>
              Verstanden
            </Button>
            {additionalButton?.type === 'patient' && (
              <Button variant="contained" type="button" color="secondary" onClick={handleClick}>
                Patient bearbeiten
              </Button>
            )}
            {additionalButton?.type === 'invoice' && (
              <Button variant="contained" type="button" color="secondary" href={additionalButton.link} target="_blank">
                Rechnung anzeigen
              </Button>
            )}
            {additionalButton?.type === 'check_key' && (
              <Button variant="contained" type="button" color="secondary" onClick={handleClick}>
                API Key prüfen
              </Button>
            )}
          </>
        )}
      </DialogActions>
    </StyledDialog>
  )
}
