import React, { useEffect, useRef, useState } from 'react'
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import useDeepCompareEffect from 'use-deep-compare-effect'
import AddNote from './AddNote'
import { hotspots } from './hotspots'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'

interface FootModel3DProps {
  width?: number
  height?: number
}

interface Hotspot {
  id: string
  position: THREE.Vector3
  rotation?: THREE.Euler
  abbreviation: string
  description: string
  width?: number
}

const HOTSPOT_COLOR = 0xffffff // Red for normal state
const SELECTED_COLOR = 0xd6e28f
const NOTED_COLOR = 0x00a0cb // Blue for spots with notes

const createEllipseGeometry = (width: number, height: number, segments = 32) => {
  const curve = new THREE.EllipseCurve(
    0,
    0, // Center x, y
    width,
    height, // x-radius, y-radius
    0,
    2 * Math.PI, // Start angle, end angle
    true, // Clockwise
  )
  const points = curve.getPoints(segments)
  const geometry = new THREE.BufferGeometry().setFromPoints(points)
  return geometry
}

interface HotspotNote {
  spotId: string
  text: string
}

const fileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  })
}

const FootModel3D: React.FC<FootModel3DProps> = ({
  width = 800,
  height = 600,
  abbreviationsList = {},
  onNoteChange,
  onFilesChange,
  confirmHotspotValuesChange,
  values = {},
}) => {
  const mountRef = useRef<HTMLDivElement>(null)
  const modelRef = useRef<THREE.Group | null>(null)
  const spotsRef = useRef<THREE.Group[]>([])
  const raycasterRef = useRef(new THREE.Raycaster())
  const isMouseDownRef = useRef(false)
  const isDraggingRef = useRef(false)
  const [isModelLoaded, setIsModelLoaded] = useState<boolean>(false)
  const mousePositionRef = useRef({ x: 0, y: 0 })
  const touchStartRef = useRef<{ x: number; y: number; distance?: number; wasMultiTouch?: boolean }>({ x: 0, y: 0 })
  const touchTimeoutRef = useRef<number | null>(null)

  const [selectedSpot, setSelectedSpot] = useState<Hotspot | null>(null)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const hasValues = (spotId: string): boolean => values[spotId]?.notes.length > 0 || values[spotId]?.files.length > 0

  const resetSpotColors = () => {
    spotsRef.current.forEach((group) => {
      const spotId = group.userData.spotId
      let color = HOTSPOT_COLOR

      // First check if spot is selected (highest priority)
      if (selectedSpot && spotId === selectedSpot.id) {
        color = SELECTED_COLOR
      }
      // Then check if spot has notes/files (second priority)
      else if (hasValues(spotId)) {
        color = NOTED_COLOR
      }

      // Apply color to both dot and ring
      group.children.forEach((child) => {
        if (child instanceof THREE.Mesh) {
          ;(child.material as THREE.MeshBasicMaterial).color.setHex(color)
        }
      })
    })
  }

  useEffect(() => {
    if (!mountRef.current) return

    // Scene setup
    const scene = new THREE.Scene()
    const camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000)
    const renderer = new THREE.WebGLRenderer({ antialias: true })
    renderer.setSize(width, height)
    mountRef.current.appendChild(renderer.domElement)

    // Lighting
    const ambientLight = new THREE.AmbientLight(0xffffff, 1)
    scene.add(ambientLight)
    const directionalLight = new THREE.DirectionalLight(0xffffff, 1)
    directionalLight.position.set(0, 1, 1)
    scene.add(directionalLight)
    scene.background = new THREE.Color(0xffffff)

    // Position camera
    camera.position.z = 5

    // Load FBX model
    const loader = new GLTFLoader()
    const dracoLoader = new DRACOLoader()
    dracoLoader.setDecoderPath('/')
    loader.setDRACOLoader(dracoLoader)

    loader.load('/foot3d.glb', (gltf) => {
      setIsModelLoaded(false)
      modelRef.current = gltf.scene
      scene.add(gltf.scene)

      // Create hotspots with ring + dot
      // const dotGeometry = new THREE.CircleGeometry(0.0025, 32) // Half of 0.005
      // const ringGeometry = new THREE.RingGeometry(0.004, 0.005, 32) // Half of 0.008, 0.01
      // const collisionGeometry = new THREE.CircleGeometry(0.005, 32) // Half of 0.01

      // Fix black material
      gltf.scene.traverse((child) => {
        if (child instanceof THREE.Mesh) {
          child.material = new THREE.MeshPhysicalMaterial({
            color: 0xf9d8c6, // Skin tone
            roughness: 0.8, // Slightly glossy
            metalness: 0.0, // Non-metallic
            clearcoat: 0.0, // Subtle shine
            transmission: 0.1, // Slight translucency
          })
        }
      })

      hotspots.forEach((spot, index) => {
        if (!spot.position) return

        const baseHeight = 0.005 // Standard height
        const baseWidth = spot.width || baseHeight // Width can be customized
        const dotShape = new THREE.Shape()
        const dotEllipse = new THREE.EllipseCurve(
          0,
          0,
          baseWidth / 2,
          baseHeight / 2, // Half size for dot
          0,
          2 * Math.PI,
          true,
        )
        const dotPoints = dotEllipse.getPoints(32)
        dotShape.setFromPoints(dotPoints)
        const dotGeometry = new THREE.ShapeGeometry(dotShape)

        const ringShape = new THREE.Shape()

        const outerEllipse = new THREE.EllipseCurve(0, 0, baseWidth, baseHeight, 0, 2 * Math.PI, true)
        const innerEllipse = new THREE.EllipseCurve(0, 0, baseWidth * 0.8, baseHeight * 0.8, 0, 2 * Math.PI, true)
        const outerPoints = outerEllipse.getPoints(32)
        const innerPoints = innerEllipse.getPoints(32)

        ringShape.setFromPoints(outerPoints)
        const holePath = new THREE.Path()
        holePath.setFromPoints(innerPoints.reverse())
        ringShape.holes.push(holePath)

        const ringGeometry = new THREE.ShapeGeometry(ringShape)

        const collisionShape = new THREE.Shape()
        collisionShape.setFromPoints(outerPoints)
        const collisionGeometry = new THREE.ShapeGeometry(collisionShape)

        // Create dot
        const dotMaterial = new THREE.MeshBasicMaterial({
          color: 0xffffff,
          side: THREE.DoubleSide,
        })
        const dot = new THREE.Mesh(dotGeometry, dotMaterial)

        // Create ring
        const ringMaterial = new THREE.MeshBasicMaterial({
          color: 0xffffff,
          side: THREE.DoubleSide,
          transparent: true,
          opacity: 0.5,
        })
        const ring = new THREE.Mesh(ringGeometry, ringMaterial)

        // Add invisible collision mesh
        const collisionMaterial = new THREE.MeshBasicMaterial({
          transparent: true,
          opacity: 0,
          side: THREE.DoubleSide,
        })
        const collisionMesh = new THREE.Mesh(collisionGeometry, collisionMaterial)

        // Group dot and ring
        const group = new THREE.Group()
        group.add(dot)
        group.add(ring)
        group.add(collisionMesh)

        // Position ring slightly behind dot
        ring.position.z = -0.001
        collisionMesh.position.z = -0.0005

        // Set position and rotation
        group.position.copy(spot.position)
        group.lookAt(camera.position)

        if (spot.rotation) {
          group.setRotationFromEuler(spot.rotation)
        }

        group.userData.spotId = spot.id

        gltf.scene.add(group)
        spotsRef.current[index] = group
      })

      // Center and scale model
      const box = new THREE.Box3().setFromObject(gltf.scene)
      const center = box.getCenter(new THREE.Vector3())
      gltf.scene.position.sub(center)

      // Auto-adjust scale
      const size = box.getSize(new THREE.Vector3())
      const maxDim = Math.max(size.x, size.y, size.z)
      const scale = 6 / maxDim
      gltf.scene.scale.setScalar(scale)

      setIsModelLoaded(true)
    })

    const minZoom = 1
    const maxZoom = 6

    const handleWheel = (e: WheelEvent) => {
      e.preventDefault()

      const zoomSpeed = 0.025
      const delta = e.deltaY * zoomSpeed
      const newZoom = camera.position.z + delta

      if (newZoom >= minZoom && newZoom <= maxZoom) {
        camera.position.z = newZoom
      }
    }

    mountRef.current.addEventListener('wheel', handleWheel, { passive: false })

    const handleMouseUp = () => {
      isMouseDownRef.current = false
    }

    const handleMouseDown = (e: MouseEvent) => {
      isMouseDownRef.current = true
      isDraggingRef.current = false
      mousePositionRef.current = { x: e.clientX, y: e.clientY }
    }

    const handleMouseMove = (e: MouseEvent) => {
      // Handle rotation if mouse is down
      if (isMouseDownRef.current && modelRef.current) {
        isDraggingRef.current = true
        const deltaX = e.clientX - mousePositionRef.current.x
        const deltaY = e.clientY - mousePositionRef.current.y

        modelRef.current.rotation.y += deltaX * 0.01
        modelRef.current.rotation.x += deltaY * 0.01

        mousePositionRef.current = { x: e.clientX, y: e.clientY }
        return
      }

      // Handle hover detection
      const rect = renderer.domElement.getBoundingClientRect()
      const x = ((e.clientX - rect.left) / rect.width) * 2 - 1
      const y = -((e.clientY - rect.top) / rect.height) * 2 + 1

      raycasterRef.current.setFromCamera(new THREE.Vector2(x, y), camera)

      const meshes: THREE.Mesh[] = []
      spotsRef.current.forEach((group) => {
        group.children.forEach((child) => {
          if (child instanceof THREE.Mesh) {
            meshes.push(child)
          }
        })
      })

      const intersects = raycasterRef.current.intersectObjects(meshes)

      if (intersects.length > 0) {
        mountRef.current!.style.cursor = 'pointer'
      } else {
        mountRef.current!.style.cursor = 'grab'
      }
    }

    const handleClick = (event: MouseEvent) => {
      if (isDraggingRef.current) return

      const rect = renderer.domElement.getBoundingClientRect()
      const x = ((event.clientX - rect.left) / rect.width) * 2 - 1
      const y = -((event.clientY - rect.top) / rect.height) * 2 + 1

      raycasterRef.current.setFromCamera(new THREE.Vector2(x, y), camera)

      // Get all meshes from spot groups
      const meshes: THREE.Mesh[] = []
      spotsRef.current.forEach((group) => {
        group.children.forEach((child) => {
          if (child instanceof THREE.Mesh) {
            meshes.push(child)
          }
        })
      })

      const intersects = raycasterRef.current.intersectObjects(meshes)

      if (intersects.length > 0) {
        const hitObject = intersects[0].object
        const spotGroup = hitObject.parent
        if (spotGroup) {
          const spotId = spotGroup.userData.spotId
          const spot = hotspots.find((h) => h.id === spotId)

          if (spot) {
            setSelectedSpot(spot)
            setAnchorEl(event.target as HTMLElement)
          }
        }
      }
    }

    const handleTouchStart = (e: TouchEvent) => {
      if (touchTimeoutRef.current) {
        window.clearTimeout(touchTimeoutRef.current)
        touchTimeoutRef.current = null
      }

      if (e.touches.length === 1) {
        // Single finger for rotation
        touchStartRef.current = {
          x: e.touches[0].clientX,
          y: e.touches[0].clientY,
        }
      } else if (e.touches.length === 2) {
        // Two fingers for zoom and pan
        const dx = e.touches[0].clientX - e.touches[1].clientX
        const dy = e.touches[0].clientY - e.touches[1].clientY
        touchStartRef.current = {
          x: (e.touches[0].clientX + e.touches[1].clientX) / 2,
          y: (e.touches[0].clientY + e.touches[1].clientY) / 2,
          distance: Math.sqrt(dx * dx + dy * dy),
          wasMultiTouch: true, // Add this flag
        }
      }
    }

    const handleTouchEnd = (e: TouchEvent) => {
      if (touchStartRef.current.wasMultiTouch) {
        // Set a timeout before allowing single-finger rotation again
        touchTimeoutRef.current = window.setTimeout(() => {
          touchStartRef.current = { x: 0, y: 0 }
          touchTimeoutRef.current = null
        }, 100) // 100ms delay
      }
    }

    const handleTouchMove = (e: TouchEvent) => {
      e.preventDefault()

      if (!modelRef.current) return

      if (e.touches.length === 1) {
        // Single finger rotation
        // Only allow single finger rotation if we didn't just finish a multi-touch gesture
        if (!touchStartRef.current.wasMultiTouch) {
          const deltaX = e.touches[0].clientX - touchStartRef.current.x
          const deltaY = e.touches[0].clientY - touchStartRef.current.y

          modelRef.current.rotation.y += deltaX * 0.01
          modelRef.current.rotation.x += deltaY * 0.01

          touchStartRef.current = {
            x: e.touches[0].clientX,
            y: e.touches[0].clientY,
          }
        }
      } else if (e.touches.length === 2) {
        // Calculate new distance between fingers
        const dx = e.touches[0].clientX - e.touches[1].clientX
        const dy = e.touches[0].clientY - e.touches[1].clientY
        const newDistance = Math.sqrt(dx * dx + dy * dy)

        if (touchStartRef.current.distance !== undefined) {
          // Pinch-to-zoom
          const zoomDelta = (newDistance - touchStartRef.current.distance) * 0.01
          const newZoom = camera.position.z - zoomDelta

          if (newZoom >= minZoom && newZoom <= maxZoom) {
            camera.position.z = newZoom
          }

          // Two-finger pan
          const centerX = (e.touches[0].clientX + e.touches[1].clientX) / 2
          const centerY = (e.touches[0].clientY + e.touches[1].clientY) / 2

          const deltaX = (centerX - touchStartRef.current.x) * 0.005
          const deltaY = (centerY - touchStartRef.current.y) * 0.005

          modelRef.current.position.x += deltaX
          modelRef.current.position.y -= deltaY
        }

        touchStartRef.current = {
          x: (e.touches[0].clientX + e.touches[1].clientX) / 2,
          y: (e.touches[0].clientY + e.touches[1].clientY) / 2,
          distance: newDistance,
        }
      }
    }

    mountRef.current.addEventListener('click', handleClick)
    mountRef.current.addEventListener('mousedown', handleMouseDown)
    mountRef.current.addEventListener('touchstart', handleTouchStart, { passive: false })
    mountRef.current.addEventListener('touchend', handleTouchEnd)
    mountRef.current.addEventListener('touchmove', handleTouchMove, { passive: false })
    window.addEventListener('mouseup', handleMouseUp)
    window.addEventListener('mousemove', handleMouseMove)

    const animate = () => {
      requestAnimationFrame(animate)

      renderer.render(scene, camera)
    }
    animate()

    // Cleanup
    return () => {
      if (mountRef.current) {
        mountRef.current.removeEventListener('click', handleClick)
        mountRef.current.removeEventListener('wheel', handleWheel)
        mountRef.current.removeEventListener('touchstart', handleTouchStart)
        mountRef.current.removeEventListener('touchend', handleTouchEnd)
        mountRef.current.removeEventListener('touchmove', handleTouchMove)
        mountRef.current.removeChild(renderer.domElement)
        mountRef.current.removeEventListener('mousedown', handleMouseDown)
      }

      if (touchTimeoutRef.current) {
        window.clearTimeout(touchTimeoutRef.current)
      }

      window.removeEventListener('mouseup', handleMouseUp)
      window.removeEventListener('mousemove', handleMouseMove)
      renderer.dispose()
    }
  }, [width, height])

  useEffect(() => {
    resetSpotColors()
  }, [selectedSpot])

  useDeepCompareEffect(() => {
    if (!isModelLoaded) return

    console.log('model is loaded')

    resetSpotColors()
  }, [values, isModelLoaded])

  const handleNoteChange = (text: string) => {
    if (!selectedSpot) return

    onNoteChange(selectedSpot, text)
  }

  const handleFilesChange = async (uploads: number[]) => {
    if (!selectedSpot) return

    onFilesChange(selectedSpot, uploads)
  }

  const getCurrentNote = () => (selectedSpot ? values[selectedSpot?.id]?.notes || '' : '')

  return (
    <>
      <div ref={mountRef} style={{ cursor: 'grab', width: 'min-content' }} />
      <AddNote
        selectedSpot={selectedSpot}
        anchorEl={anchorEl}
        onClose={() => {
          setSelectedSpot(null)
          setAnchorEl(null)
          resetSpotColors()
        }}
        inputValue={getCurrentNote()}
        filesValue={selectedSpot ? values[selectedSpot.id]?.files || [] : []}
        onInputChange={(newValue) => handleNoteChange(newValue)}
        onFilesChange={handleFilesChange}
        abbreviationsList={abbreviationsList}
        confirmHotspotValuesChange={confirmHotspotValuesChange}
      />
    </>
  )
}

export default FootModel3D
