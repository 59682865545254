import { createSelector } from 'reselect'
import * as R from 'ramda'
import { ISO8601DATE_FNS } from '../../constants/dateFormats'
import { abgesagteTermineEntfernen } from '../../utils/dates'
import { praxisstammdatenSelector } from '../../selectors/selectors'
import {
  getHours,
  startOfWeek,
  endOfWeek,
  isValid,
  parseISO,
  isWithinInterval,
  isSameDay,
  format,
  parse,
  setISODay,
  setHours,
  setMinutes,
  setSeconds,
  differenceInCalendarDays,
} from 'date-fns'

export const sTherapists = createSelector(
  (state: StoreState) => state.entities.users,
  R.compose(R.filter(R.propEq('istTherapeut', true)), R.values),
)

export const sRooms = createSelector(
  (state: StoreState) => state.entities.rooms,
  (rooms) => Object.keys(rooms).map((r) => rooms[r]),
)

export const sAbbreviations = createSelector(
  (state: StoreState) => state.entities.abbreviations,
  (abbreviations) => Object.keys(abbreviations).map((a) => abbreviations[a])
)

export const sHotspotAbbreviations = createSelector(
  (state: StoreState) => state.entities.hotspotAbbreviations,
  (hotspotAbbreviations) => Object.keys(hotspotAbbreviations).map((a) => hotspotAbbreviations[a])
)

export const datesWithoutCanceled = createSelector(
  (state: StoreState) => state.entities.termine,
  R.compose(abgesagteTermineEntfernen, R.values),
)

const sSelectedDaysDates = createSelector(
  datesWithoutCanceled,
  (state) => state.selectedDay,
  (dates, selectedDay) => R.filter((date) => isSameDay(selectedDay, date.beginn))(dates),
)

export const getTimeBoundaries = (dates): FreeDates =>
  R.reduce(
    (acc, date) => {
      if (date.isAllDay) return acc
      return {
        firstHour:
          getHours(date.beginn) < acc.firstHour || acc.firstHour === null ? getHours(date.beginn) : acc.firstHour,
        lastHour: getHours(date.ende) > acc.lastHour || acc.lastHour === null ? getHours(date.ende) : acc.lastHour,
      }
    },
    { firstHour: null, lastHour: null },
    dates,
  )

export const sTimeBoundariesDay = createSelector(sSelectedDaysDates, (dates) => getTimeBoundaries(dates))

export const getUpdatedGridConfig = (timeBoundaries, gridConfig): CalendarGrid['default'] => {
  const firstHour =
    timeBoundaries.firstHour !== null && timeBoundaries.firstHour < gridConfig.firstHour
      ? timeBoundaries.firstHour
      : gridConfig.firstHour
  const lastHourGridConfig = gridConfig.firstHour + gridConfig.numHours
  const numHours =
    lastHourGridConfig <= timeBoundaries.lastHour
      ? timeBoundaries.lastHour - firstHour + 1
      : lastHourGridConfig - firstHour
  return {
    ...gridConfig,
    firstHour,
    numHours,
  }
}

export const sGridConfigDay = createSelector(
  sTimeBoundariesDay,
  (state) => state.config.calendarGrid.default,
  (timeBoundaries, gridConfig) => getUpdatedGridConfig(timeBoundaries, gridConfig),
)

export const sDatesByTherapist = createSelector(sTherapists, sSelectedDaysDates, (therapists, dates) => {
  let orderedTherapists = (therapists as any).filter((a) => a.positionInOrder !== null)
  orderedTherapists.sort((a, b) => {
    return a.positionInOrder - b.positionInOrder
  })
  orderedTherapists = orderedTherapists.concat((therapists as any).filter((a) => a.positionInOrder === null))

  return orderedTherapists.map((therapist) => {
    return {
      therapist,
      dates: filterDatesByPerson(dates, therapist.id),
    }
  })
})

export const sDatesByRoom = createSelector(sRooms, sSelectedDaysDates, (rooms, dates) => {
  const orderedRooms = rooms.slice().filter((r) => !r?.disabled)
  orderedRooms.sort((a, b) => a.id - b.id)

  return orderedRooms.map((r) => ({
    room: r,
    dates: filterDatesByRoom(dates, r.id),
  }))
})

const sTermineVonBisGeladen = (state: StoreState): Object => state.termineVonBisGeladen
const sSelectedDay = (state: StoreState): Date => state.selectedDay

const sTermineGeladenKeyTag = createSelector(
  sSelectedDay,
  (selectedDay) => `${format(selectedDay, ISO8601DATE_FNS)}${format(selectedDay, ISO8601DATE_FNS)}`,
)

export const sTermineGeladenTag = createSelector(
  sTermineVonBisGeladen,
  sTermineGeladenKeyTag,
  (termineGeladen, key) => !!termineGeladen[key],
)

const sTermineGeladenKeyWoche = createSelector(
  sSelectedDay,
  (selectedDay) =>
    `${format(startOfWeek(selectedDay, { weekStartsOn: 1 }), ISO8601DATE_FNS)}${format(
      endOfWeek(selectedDay, { weekStartsOn: 1 }),
      ISO8601DATE_FNS,
    )}`,
)

export const sTermineGeladenWoche = createSelector(
  sTermineVonBisGeladen,
  sTermineGeladenKeyWoche,
  (termineGeladen, key) => !!termineGeladen[key],
)

const sEvents = createSelector((state: StoreState) => state.entities.events, R.values)

export const sICSSources = createSelector(praxisstammdatenSelector, (praxisstammdaten) =>
  praxisstammdaten ? praxisstammdaten.icsSources : null,
)

export const sEventsWithICSInfo = createSelector(sEvents, sICSSources, (events, sources) =>
  R.map(
    (event) => ({
      ...event,
      icsSource: R.find(R.propEq('id', event.icsSourceId), sources),
    }),
    events,
  ),
)

const sTermine = createSelector((state: StoreState) => state.entities.termine, R.values)

export const sAllDayTermineOhneAbgesagte = createSelector(
  sTermine,
  R.compose(
    R.reverse,
    R.sortBy(R.compose(R.length, R.prop('teilnehmerOrganisatorischerTermin'))),
    abgesagteTermineEntfernen,
    R.filter(R.propEq('isAllDay', true)),
  ),
)

export const sEventsAndAllDayTermine = createSelector(
  sEventsWithICSInfo,
  sAllDayTermineOhneAbgesagte,
  (events, termine) => R.concat(events, termine),
)

export const calcAllDayGapHeight = (
  eventsAndAllDayTermine,
  selectedDay,
  { allDayItemHeight, allDayItemMargin, allDayItemsShowAll, allDayItemsLimit },
): StoreState =>
  R.compose(
    R.add(10),
    R.multiply(allDayItemHeight + allDayItemMargin),
    R.ifElse(
      (count) => count > allDayItemsLimit,
      R.ifElse(
        () => allDayItemsShowAll,
        (count) => count + 1, // + 1 adds space for 'show less' button
        () => allDayItemsLimit,
      ),
      R.identity,
    ),
    R.length,
    R.filter(
      ({ startDate, endDate, beginn, ende }) =>
        isValid(selectedDay) &&
        isWithinInterval(selectedDay, {
          start: startDate ? parseISO(startDate) : beginn,
          end: endDate ? parseISO(endDate) : ende,
        }),
    ),
  )(eventsAndAllDayTermine)

const sAllDayAppointmentsByTherapist = createSelector(
  sDatesByTherapist,
  R.map(R.compose(R.filter(R.propEq('isAllDay', true)), R.prop('dates'))),
)

const sEventsAndAllDayTermineByTherapist = createSelector(
  sAllDayAppointmentsByTherapist,
  sEventsWithICSInfo,
  (datesByTherapist, events) => R.compose(R.map(R.concat(events)))(datesByTherapist),
)

export const sAllDayGapHeight = createSelector(
  sEventsAndAllDayTermineByTherapist,
  sSelectedDay,
  (state) => state.config.calendarGrid.default,
  (eventsAndAllDayTermineByTherapist, selectedDay, gridConfig) =>
    R.compose(
      R.reduce(R.max, 0),
      R.map((es) => calcAllDayGapHeight(es, selectedDay, gridConfig)),
    )(eventsAndAllDayTermineByTherapist),
)

// 2. Selector

export const sGetBaseGridConfigFreeDates = createSelector(
  (state: StoreState) => state.config.calendarGrid.default,
  (state: StoreState) => state.config.calendarGrid.freeDatesView,
  (defaultConfig, freeDatesView) => ({ ...defaultConfig, ...freeDatesView }),
)

// const getUsers = (state) => state.entities.users

export const getTherapeuten = createSelector(sTherapists, (users) => {
  const orderedTherapists = users.filter((a) => a.positionInOrder !== null)
  orderedTherapists.sort((a, b) => {
    return a.positionInOrder - b.positionInOrder
  })
  return orderedTherapists.concat(users.filter((a) => a.positionInOrder === null))
})

const getTermine = (state): StoreState => state.entities.termine

const getTermineMitFlavor = createSelector(getTermine, (termine) =>
  Object.keys(termine).map((key) => ({
    ...termine[key],
    flavor: 'notext_smallpadding',
  })),
)

const getTermineMitFlavorOhneAbgesagte = createSelector(getTermineMitFlavor, (termine) =>
  abgesagteTermineEntfernen(termine),
)

const getSelectedDay = (state) => state.selectedDay

export const getSelectedWeek = createSelector(getSelectedDay, (day) => format(day, 'RRRRII'))

export const getWeekDaysM = createSelector([sGetBaseGridConfigFreeDates, getSelectedWeek], ({ isoWeekDays }, week) =>
  isoWeekDays.map((day) => {
    return setSeconds(setMinutes(setHours(setISODay(parse(week, 'RRRRII', new Date()), day), 0), 0), 0)
  }),
)

export const getDatesByWeekDay = createSelector(
  [getWeekDaysM, getTermineMitFlavorOhneAbgesagte],
  (weekDaysM, termine) =>
    weekDaysM.map((day) =>
      termine.filter(
        (date) => differenceInCalendarDays(day, date.beginn) === 0 && differenceInCalendarDays(day, date.ende) === 0,
      ),
    ),
)

export const sEinzelnerTherapeut = createSelector(
  (state: StoreState) => state.entities.users,
  (users) =>
    Object.keys(users)
      .map((key) => users[key])
      .filter((user) => user.istTherapeut).length === 1,
)

export const sTerminFindenDeaktiviert = createSelector(
  praxisstammdatenSelector,
  (praxisstammdaten) => praxisstammdaten && praxisstammdaten.terminFindenDeaktiviert,
)

export const sTreatmentRoomFeatureEnabled = createSelector(
  praxisstammdatenSelector,
  (praxisstammdaten) => praxisstammdaten && praxisstammdaten.treatmentRoomFeature,
)

export const sRoomSelectionMandatory = createSelector(
  praxisstammdatenSelector,
  (praxisstammdaten) => praxisstammdaten && praxisstammdaten.roomSelectionMandatory,
)

export const sRoomFeatureEnabledAt = createSelector(
  praxisstammdatenSelector,
  (praxisstammdaten) => praxisstammdaten && praxisstammdaten.roomFeatureEnabledAt,
)

export const sTimeBoundariesFreeDates = createSelector(getDatesByWeekDay, (days) => getTimeBoundaries(R.flatten(days)))

export const sGridConfigFreeDates = createSelector(
  sTimeBoundariesFreeDates,
  sGetBaseGridConfigFreeDates,
  (timeBoundaries, gridConfig) => getUpdatedGridConfig(timeBoundaries, gridConfig),
)

export const sGetBaseGridConfigWeek = createSelector(
  (state: StoreState) => state.config.calendarGrid.default,
  (state) => state.config.calendarGrid.weekView,
  (defaultConfig, weekView) => ({ ...defaultConfig, ...weekView }),
)

export const sWeekDaysM = createSelector(
  sGetBaseGridConfigWeek,
  (state) => state.selectedDay,
  ({ isoWeekDays }, selectedDay) =>
    isoWeekDays.map((day) => setSeconds(setMinutes(setHours(setISODay(selectedDay, day), 0), 0), 0)),
)

export const sAllDayGapHeightWeek = createSelector(
  sEventsAndAllDayTermine,
  sWeekDaysM,
  (state) => state.config.calendarGrid.default,
  (eventsAndAllDayTermine, weekDaysM, gridConfig) => {
    return R.compose(
      R.reduce(R.max, 0),
      R.map((day) => calcAllDayGapHeight(eventsAndAllDayTermine, day, gridConfig)),
    )(weekDaysM)
  },
)

export const getComputedHeight = createSelector(
  sGridConfigFreeDates,
  sAllDayGapHeightWeek,
  ({ gridRowMinutes, gridRowHeight, headerHeight, numHours }, allDayGapHeight) => {
    const rowsPerHour = 60 / gridRowMinutes
    const pixelsPerHour = rowsPerHour * gridRowHeight
    return numHours * pixelsPerHour + headerHeight + allDayGapHeight
  },
)

// 3. Selector

const sSelectedTherapistId = createSelector(
  (_, { person }) => person,
  (p) => parseInt(p, 10),
)

export const filterDatesByPerson = R.curry((dates, selectedPersonId) =>
  R.filter(
    (date) =>
      date.therapeut === selectedPersonId ||
      (date.teilnehmerOrganisatorischerTermin &&
        date.teilnehmerOrganisatorischerTermin.indexOf(selectedPersonId) !== -1),
    dates,
  ),
)

export const filterDatesByRoom = R.curry((dates, selectedRoomId) =>
  R.filter((date) => !date?.istOrganisatorisch && date.room === selectedRoomId, dates),
)

const sSelectedPersonsDates = createSelector(datesWithoutCanceled, sSelectedTherapistId, filterDatesByPerson)

export const sDatesByWeekDay = createSelector(sWeekDaysM, sSelectedPersonsDates, (weekDaysM, dates) =>
  R.map(
    (day) =>
      R.filter(
        (date) => differenceInCalendarDays(day, date.beginn) === 0 && differenceInCalendarDays(day, date.ende) === 0,
        dates,
      ),
    weekDaysM,
  ),
)

export const sSelectedTherapist = createSelector(
  sSelectedTherapistId,
  (state) => state.entities.users,
  (id, users) => users[id],
)

const sEventsAndAllDayTermineSelectedPerson = createSelector(
  sEventsWithICSInfo,
  sSelectedPersonsDates,
  (events, termine) => R.compose(R.concat(events), R.filter(R.propEq('isAllDay', true)))(termine),
)

export const sAllDayGapHeightWeekSelectedPerson = createSelector(
  sEventsAndAllDayTermineSelectedPerson,
  sWeekDaysM,
  (state) => state.config.calendarGrid.default,
  (eventsAndAllDayTermine, weekDaysM, gridConfig) => {
    return R.compose(
      R.reduce(R.max, 0),
      R.map((day) => calcAllDayGapHeight(eventsAndAllDayTermine, day, gridConfig)),
    )(weekDaysM)
  },
)

export const sTimeBoundariesWeek = createSelector(sDatesByWeekDay, (days) => getTimeBoundaries(R.flatten(days)))

export const sGridConfigWeek = createSelector(
  sTimeBoundariesWeek,
  sGetBaseGridConfigWeek,
  (timeBoundaries, gridConfig) => getUpdatedGridConfig(timeBoundaries, gridConfig),
)
