import { isAfter, isDate, subMinutes } from 'date-fns'
import * as R from 'ramda'
import * as types from '../constants/actionTypes'
import { ACQUIRE_PIN_AUTHENTICATION_SUCCESS, UPGRADE_CREDENTIALS_SUCCESS } from '../redux/modules/auth'
import { TICK } from '../redux/modules/time'
import { CHANGE_PIN_CHANGE_PIN_SUCCESS } from '../shared/redux/modules/pin'
import { stringsToDates } from '../shared/utils/dates'

export const momentFields = {
  abbreviations: ['createdAt', 'modifiedAt'],
  aerzte: ['createdAt', 'modifiedAt'],
  anamnesen: ['dfDiabetikerSeitWann', 'dfHba1cDatum', 'seEinlagenAngefertigtAm', 'createdAt', 'modifiedAt'],
  dateien: ['createdAt', 'modifiedAt'],
  dokumentationen: ['createdAt', 'modifiedAt'],
  dokumentationsTexte: ['createdAt', 'modifiedAt'],
  heilmittelverordnungen: ['ausstellungsdatum', 'behandlungsbeginnSpaetestensAm', 'createdAt', 'modifiedAt'],
  kassenbuch: ['createdAt'],
  leistungenPrivat: ['createdAt', 'modifiedAt'],
  patienten: ['geburtsdatum', 'patientSeit', 'createdAt', 'modifiedAt'],
  praxisstammdaten: ['modifiedAt', 'roomFeatureEnabledAt'],
  termine: ['beginn', 'ende', 'absagedatum', 'createdAt', 'modifiedAt'],
  therapieberichte: ['createdAt', 'modifiedAt'],
  todos: ['createdAt', 'modifiedAt'],
  rooms: ['createdAt', 'modifiedAt'],
  equipment: ['createdAt', 'modifiedAt'],
  users: ['createdAt', 'modifiedAt'],
  zahlungen: ['createdAt'],
  zahlungspositionen: ['createdAt'],
}

export default function entities(
  state = {
    abbreviations: {},
    aerzte: {},
    anamnesen: {},
    dateien: {},
    dokumentationen: {},
    dokumentationsTexte: {},
    kassenbuch: {},
    leistungenPrivat: {},
    heilmittelverordnungen: {},
    hotspotAbbreviations: {},
    patienten: {},
    praxisstammdaten: {},
    termine: {},
    therapieberichte: {},
    todos: {},
    users: {},
    zahlungen: {},
    zahlungspositionen: {},
    events: {},
    rooms: {},
    equipment: {},
  },
  action = undefined,
) {
  if (action.json && action.json.entities) {
    const {
      abbreviations,
      aerzte,
      anamnesen,
      dateien,
      dokumentationen,
      dokumentationsTexte,
      termine,
      heilmittelverordnungen,
      hotspotAbbreviations,
      kassenbuch,
      leistungenPrivat,
      patienten,
      praxisstammdaten,
      therapieberichte,
      todos,
      zahlungen,
      zahlungspositionen,
      users,
      rooms,
      equipment,
      ...other
    } = action.json.entities

    const newState = mergeEntities(state, {
      ...other,
      abbreviations: stringsToDates(abbreviations, momentFields.abbreviations),
      aerzte: stringsToDates(aerzte, momentFields.aerzte),
      anamnesen: stringsToDates(anamnesen, momentFields.anamnesen),
      // Die Datei-Entity tief zu mergen ist eigentlich nicht optimal, wir machen das nur die ganze Zeit schon so.
      dateien: mergeDeep(state.dateien, stringsToDates(dateien || {}, momentFields.dateien)),
      dokumentationen: stringsToDates(dokumentationen, momentFields.dokumentationen),
      dokumentationsTexte: stringsToDates(dokumentationsTexte, momentFields.dokumentationsTexte),
      heilmittelverordnungen: stringsToDates(heilmittelverordnungen, momentFields.heilmittelverordnungen),
      hotspotAbbreviations,
      kassenbuch: stringsToDates(kassenbuch, momentFields.kassenbuch),
      leistungenPrivat: stringsToDates(leistungenPrivat, momentFields.leistungenPrivat),
      patienten: stringsToDates(patienten, momentFields.patienten),
      praxisstammdaten: stringsToDates(praxisstammdaten, momentFields.praxisstammdaten),
      termine: stringsToDates(termine, momentFields.termine),
      therapieberichte: stringsToDates(therapieberichte, momentFields.therapieberichte),
      todos: stringsToDates(todos, momentFields.todos),
      users: stringsToDates(users, momentFields.users),
      zahlungen: stringsToDates(zahlungen, momentFields.zahlungen),
      zahlungspositionen: stringsToDates(zahlungspositionen, momentFields.zahlungspositionen),
      rooms: stringsToDates(rooms, momentFields.rooms),
      equipment: stringsToDates(equipment, momentFields.equipment),
    })

    switch (action.type) {
      case types.CANCEL_DATE_DECEASED_SUCCESS: {
        const {
          json: { entities },
          patient,
        } = action
        return {
          ...newState,
          patienten: {
            ...newState.patienten,
            [patient]: {
              ...R.path(['patienten', `${patient}`], newState),
              modifiedAt: R.path(['patienten', `${patient}`, 'modifiedAt'], entities),
            },
          },
        }
      }
      case types.UPDATE_DATE_SUCCESS: {
        const {
          json: { entities },
          id,
        } = action
        return {
          ...state,
          termine: {
            ...state.termine,
            [id]: {
              ...R.path(['termine', `${id}`], state),
              modifiedAt: R.path(['termine', `${id}`, 'modifiedAt'], entities),
            },
          },
        }
      }
      case types.UPDATE_PRAXISSTAMMDATEN_SUCCESS:
        const maybeHash = window.location.pathname.split('/')[1]
        const maybePs = action.json.entities.praxisstammdaten[maybeHash]
        if (maybePs) {
          return {
            ...newState,
            events: filterIcsEvents(maybePs.icsSources, newState.events),
          }
        } else {
          return newState
        }

      default:
    }

    return newState
  }

  switch (action.type) {
    case types.CLEAR_TODOS:
      return {
        ...state,
        todos: {},
      }
    case types.DELETE_LEISTUNGPRIVAT_SUCCESS:
      const { leistungenPrivat } = state
      return {
        ...state,
        leistungenPrivat: {
          ...leistungenPrivat,
          [action.id]: {
            ...leistungenPrivat[action.id],
            deleted: true,
          },
        },
      }

    case TICK:
      if (action.interval !== 'minute') {
        return state
      }
      const { todos } = state
      return {
        ...state,
        todos: filterOldTodos(action.time, todos),
      }

    case CHANGE_PIN_CHANGE_PIN_SUCCESS:
    case types.UPDATE_USER_SUCCESS:
      return action.json.user
        ? {
            ...state,
            users: updateUser(state.users, action.json.user),
          }
        : state

    case ACQUIRE_PIN_AUTHENTICATION_SUCCESS:
    case UPGRADE_CREDENTIALS_SUCCESS:
      return {
        ...state,
        todos: {},
      }
    default:
  }

  return state
}

const updateUser = (users, updatedUser) => {
  if (updatedUser.authUserId) {
    // updating from inside an instance
    const user = R.compose(R.head, R.filter(R.propEq('authUserId', updatedUser.authUserId)), R.values)(users)

    return {
      ...users,
      ...(user
        ? {
            [user.id]: {
              ...user,
              ...updatedUser,
            },
          }
        : {}),
    }
  } else {
    // updating from startbereich, we could probably return empty obj as well
    return {
      [updatedUser.id]: {
        updatedUser,
      },
    }
  }
}

const filterOldTodos = (time, todos) => {
  if (typeof todos === 'object') {
    return todos
  }

  const result = {}
  for (const key in todos) {
    if (
      !todos[key].done === true ||
      (todos[key].done === true && isAfter(todos[key].createdAt, subMinutes(time, 30)))
    ) {
      result[key] = todos[key]
    } else {
      result[key] = null
    }

    return result
  }
}

// removes events that reference icsSources entries which are not valid anymore
const filterIcsEvents = (icsSources, events) =>
  R.compose(R.filter(R.compose(R.contains(R.__, R.map(R.prop('id'), icsSources)), R.prop('icsSourceId'))))(events)

const mergeEntities = R.mergeWith((a, b) => {
  if (Array.isArray(b)) {
    return b
  }
  if (typeof a === 'object' && typeof b === 'object') {
    return mergeInner(a, b)
  }
  if (typeof b !== 'undefined') {
    return b
  }
  return a
})

const mergeInner = R.mergeWith((a, b) => {
  if (typeof b !== 'undefined') {
    return b
  }
  return a
})

const mergeDeep = R.mergeWith((a, b) => {
  if (isDate(b)) {
    return b
  }
  if (typeof a === 'object' && typeof b === 'object') {
    return R.merge(a, b)
  }
  if (typeof b !== 'undefined') {
    return b
  }
  return a
})
