import { Typography } from '@material-ui/core'
import { Description as DescriptionIcon } from '@material-ui/icons'
import { format } from 'date-fns'
import { FC, ReactNode } from 'react'
import { GERMAN_DATE_SHORT_YEAR_FNS } from '../../../constants/dateFormats'
import { StyledFilePreview } from './StyledUploadPreview'

interface Props {
  contentType?: string
  name?: string
  thumbnail?: string
  message?: ReactNode
  createdAt?: number | Date
}

const UploadPreview: FC<Props> = ({ thumbnail, contentType, name, message, createdAt }) => {
  const getIcon = () => {
    switch (contentType) {
      case 'image/jpeg':
      case 'image/png':
      case 'image/gif':
      case 'image/bmp':
      case 'image/svg':
        if (thumbnail) {
          return <img className="previewThumbnail" src={`data:image/png;base64,${thumbnail}`} />
        }
      // falls through
      default:
        return <DescriptionIcon />
    }
  }

  return (
    <StyledFilePreview>
      {getIcon()}
      <div className="previewTextArea">
        <Typography variant="body1" className="textTypography">
          {name}
        </Typography>
        <Typography variant="body1" className="textTypography status">
          {message || `Hinzugefügt am ${createdAt && format(createdAt, GERMAN_DATE_SHORT_YEAR_FNS)}`}
        </Typography>
      </div>
    </StyledFilePreview>
  )
}

export default UploadPreview
