import { differenceInMinutes, isAfter, isBefore } from 'date-fns'
import { getDateAfter } from './dates'

const pricesBefore01072022 = {
  78010: {
    compensation: 2935,
    copayment: 294,
  },
  78020: {
    compensation: 4200,
    copayment: 420,
  },
  78030: {
    compensation: 200,
    copayment: 20,
  },
  79933: {
    compensation: 1650,
    copayment: 165,
  },
  79934: {
    compensation: 950,
    copayment: 95,
  },
}

const pricesAfter01072022 = {
  78010: {
    compensation: 3070,
    copayment: 307,
  },
  78020: {
    compensation: 4400,
    copayment: 440,
  },
  78030: {
    compensation: 300,
    copayment: 30,
  },
  79933: {
    compensation: 1750,
    copayment: 175,
  },
  79934: {
    compensation: 1000,
    copayment: 100,
  },

  // nagelkorrektur:
  78100: {
    compensation: 4871,
    copayment: 487,
  },
  78210: {
    compensation: 8654,
    copayment: 865,
  },
  78220: {
    compensation: 4737,
    copayment: 0,
  },
  78230: {
    compensation: 4339,
    copayment: 434,
  },
  78300: {
    compensation: 8681,
    copayment: 868,
  },
  78400: {
    compensation: 4764,
    copayment: 476,
  },
  78510: {
    compensation: 1519,
    copayment: 152,
  },
  78520: {
    compensation: 2271,
    copayment: 227,
  },
}

const pricesAfter01072023 = {
  78010: {
    compensation: 3270,
    copayment: 327,
  },
  78020: {
    compensation: 4693,
    copayment: 469,
  },
  78030: {
    compensation: 321,
    copayment: 32,
  },
  78040: {
    compensation: 2079,
    copayment: 208,
  },
  79933: {
    compensation: 1995,
    copayment: 200,
  },
  79934: {
    compensation: 1140,
    copayment: 114,
  },

  // nagelkorrektur:
  78100: {
    compensation: 5197,
    copayment: 520,
  },
  78110: {
    compensation: 2598,
    copayment: 260,
  },
  78210: {
    compensation: 9213,
    copayment: 921,
  },
  78220: {
    compensation: 5043,
    copayment: 0,
  },
  78230: {
    compensation: 4619,
    copayment: 462,
  },
  78300: {
    compensation: 8844,
    copayment: 884,
  },
  78400: {
    compensation: 5044,
    copayment: 504,
  },
  78510: {
    compensation: 1610,
    copayment: 161,
  },
  78520: {
    compensation: 2415,
    copayment: 241,
  },
  78530: {
    compensation: 1559,
    copayment: 0,
  },
}

const pricesAfter01072024 = {
  78010: {
    compensation: 3418,
    copayment: 342,
  },
  78020: {
    compensation: 4912,
    copayment: 491,
  },
  78030: {
    compensation: 337,
    copayment: 34,
  },
  78040: {
    compensation: 2183,
    copayment: 218,
  },
  79933: {
    compensation: 2195,
    copayment: 219,
  },
  79934: {
    compensation: 1254,
    copayment: 125,
  },

  // nagelkorrektur:
  78100: {
    compensation: 5441,
    copayment: 544,
  },
  78110: {
    compensation: 2713,
    copayment: 271,
  },
  78210: {
    compensation: 9633,
    copayment: 963,
  },
  78220: {
    compensation: 5277,
    copayment: 0,
  },
  78230: {
    compensation: 4828,
    copayment: 483,
  },
  78300: {
    compensation: 9193,
    copayment: 919,
  },
  78400: {
    compensation: 5253,
    copayment: 525,
  },
  78510: {
    compensation: 1675,
    copayment: 168,
  },
  78520: {
    compensation: 2520,
    copayment: 252,
  },
  78530: {
    compensation: 1637,
    copayment: 0,
  },
}

interface CalcHvoResult {
  copaymentAmount: number
  grossAmount: number
  inputCancelTreatmentActive: boolean
  inputHVOSettledActive: boolean
  appointments: any[]
  positionsCount: any[]
}

const calcNagelkorrekturHvo = (hvo: Prescription | {} = {}, notCancelledEvents: Appointment[] = []): CalcHvoResult => {
  const res: CalcHvoResult = {
    copaymentAmount: 0,
    grossAmount: 0,
    inputCancelTreatmentActive: false,
    inputHVOSettledActive: false,
    appointments: notCancelledEvents,
    positionsCount: [],
  }

  let grossAmount = 0
  let copaymentAmount = 1000
  const positionsUnsorted: any[] = []

  let anpassungPriceAdded = false

  // const allServicesKK = Object.keys(leistungenKK).map((key) => leistungenKK[key])

  notCancelledEvents.forEach((a) => {
    const servicesKK = a.leistungen?.filter((l) => !!l?.istKKLeistung) ?? []

    let prices
    if (isBefore(a.beginn, new Date('2022-07-01'))) {
      prices = pricesBefore01072022
    } else if (isBefore(a.beginn, new Date('2023-07-01')) && isAfter(a.beginn, new Date('2022-07-01'))) {
      prices = pricesAfter01072022
    } else if (isBefore(a.beginn, new Date('2024-07-01')) && isAfter(a.beginn, new Date('2023-07-01'))) {
      prices = pricesAfter01072023
    } else {
      prices = pricesAfter01072024
    }

    console.log('prices: ', prices)

    servicesKK.forEach((sk) => {
      if (sk.positionsnummer === 78210) {
        if (!anpassungPriceAdded) {
          anpassungPriceAdded = true
          grossAmount += prices[78210].compensation
          copaymentAmount += prices[78210].copayment
        }

        const positionIndex = positionsUnsorted.findIndex((p) => p.positionsnummer === 78210)
        if (positionIndex === -1) {
          positionsUnsorted.push({
            positionsnummer: 78210,
            faktor: 1,
          })
        } else {
          positionsUnsorted[positionIndex].faktor += 1
        }
      } else {
        if (isBefore(a.beginn, new Date('2023-07-01')) && sk.positionsnummer === 78110) {
          grossAmount += prices[78100].compensation
          copaymentAmount += prices[78100].copayment

          const positionIndex = positionsUnsorted.findIndex((p) => p.positionsnummer === 78100)
          if (positionIndex === -1) {
            positionsUnsorted.push({
              positionsnummer: 78100,
              faktor: 1,
            })
          } else {
            positionsUnsorted[positionIndex].faktor += 1
          }
        } else {
          grossAmount += prices[sk.positionsnummer].compensation
          copaymentAmount += prices[sk.positionsnummer].copayment

          const positionIndex = positionsUnsorted.findIndex((p) => p.positionsnummer === sk.positionsnummer)
          if (positionIndex === -1) {
            positionsUnsorted.push({
              positionsnummer: sk.positionsnummer,
              faktor: 1,
            })
          } else {
            positionsUnsorted[positionIndex].faktor += 1
          }
        }
      }
    })

    if (hvo.hausbesuch && a.hausbesuch) {
      if (hvo.socialFacility) {
        grossAmount += prices[79934].compensation
        copaymentAmount += prices[79934].copayment

        const positionIndex = positionsUnsorted.findIndex((p) => p.positionsnummer === 79934)
        if (positionIndex === -1) {
          positionsUnsorted.push({
            positionsnummer: 79934,
            faktor: 1,
          })
        } else {
          positionsUnsorted[positionIndex].faktor += 1
        }
      } else if (hvo.medicallyPrescribed) {
        grossAmount += prices[79933].compensation
        copaymentAmount += prices[79933].copayment

        const positionIndex = positionsUnsorted.findIndex((p) => p.positionsnummer === 79933)
        if (positionIndex === -1) {
          positionsUnsorted.push({
            positionsnummer: 79933,
            faktor: 1,
          })
        } else {
          positionsUnsorted[positionIndex].faktor += 1
        }
      }
    }
  })

  if (hvo.diagnosegruppe === 'UI2' && hvo.therapiebericht && notCancelledEvents.length === hvo.verordnungsmenge) {
    const latestTerminBeginn = notCancelledEvents.reduce((acc, curr) => {
      console.log('curr: ', curr)
      console.log('acc: ', acc)
      console.log('isAfter(curr.beginn, acc.beginn): ', isAfter(curr.beginn, acc.beginn))
      return isAfter(curr.beginn, acc) ? curr.beginn : acc
    }, new Date(0))

    let priceForTherapiebericht = null
    if (isBefore(latestTerminBeginn, new Date('2022-07-01'))) {
      priceForTherapiebericht = null
    } else if (
      isBefore(latestTerminBeginn, new Date('2023-11-01')) &&
      isAfter(latestTerminBeginn, new Date('2022-07-01'))
    ) {
      priceForTherapiebericht = null
    } else if (
      isBefore(latestTerminBeginn, new Date('2024-07-01')) &&
      isAfter(latestTerminBeginn, new Date('2023-11-01'))
    ) {
      priceForTherapiebericht = pricesAfter01072023[78530]
    } else {
      priceForTherapiebericht = pricesAfter01072024[78530]
    }

    if (priceForTherapiebericht !== null) {
      grossAmount += priceForTherapiebericht.compensation
      copaymentAmount += priceForTherapiebericht.copayment

      const positionIndex = positionsUnsorted.findIndex((p) => p.positionsnummer === 78530)
      if (positionIndex === -1) {
        positionsUnsorted.push({
          positionsnummer: 78530,
          faktor: 1,
        })
      } else {
        positionsUnsorted[positionIndex].faktor += 1
      }
    }
  }

  if (hvo.gebuehrenbefreit) {
    copaymentAmount = 0
  }

  res.grossAmount = grossAmount
  res.copaymentAmount = copaymentAmount
  res.positionsCount = positionsUnsorted.slice().sort((a, b) => a.positionsnummer - b.positionsnummer)

  return res
}

const calcNormalHvo = (hvo: Prescription | {} = {}, notCancelledEvents: Appointment[] = []): CalcHvoResult => {
  const res: CalcHvoResult = {
    copaymentAmount: 0,
    grossAmount: 0,
    inputCancelTreatmentActive: false,
    inputHVOSettledActive: false,
    appointments: notCancelledEvents,
    positionsCount: [],
  }

  let grossAmount = 0
  let copaymentAmount = 1000
  const positionsUnsorted: any[] = []

  notCancelledEvents.forEach((a) => {
    console.log('a: ', a)
    let prices = {}

    if (isBefore(a.beginn, new Date('2022-07-01'))) {
      prices = pricesBefore01072022
    } else if (isBefore(a.beginn, new Date('2023-07-01')) && isAfter(a.beginn, new Date('2022-07-01'))) {
      prices = pricesAfter01072022
    } else if (isBefore(a.beginn, new Date('2024-07-01')) && isAfter(a.beginn, new Date('2023-07-01'))) {
      prices = pricesAfter01072023
    } else {
      prices = pricesAfter01072024
    }

    let duration = differenceInMinutes(a.ende, a.beginn)
    if (a.leistungen.some((l) => l.positionsnummer === 78020)) {
      grossAmount += prices[78020].compensation
      copaymentAmount += prices[78020].copayment

      const positionIndex = positionsUnsorted.findIndex((p) => p.positionsnummer === 78020)
      if (positionIndex === -1) {
        positionsUnsorted.push({
          positionsnummer: 78020,
          faktor: 1,
        })
      } else {
        positionsUnsorted[positionIndex].faktor += 1
      }
    } else if (a.leistungen.some((l) => l.positionsnummer === 78010)) {
      grossAmount += prices[78010].compensation
      copaymentAmount += prices[78010].copayment

      const positionIndex = positionsUnsorted.findIndex((p) => p.positionsnummer === 78010)
      if (positionIndex === -1) {
        positionsUnsorted.push({
          positionsnummer: 78010,
          faktor: 1,
        })
      } else {
        positionsUnsorted[positionIndex].faktor += 1
      }
    } else if (a.leistungen.some((l) => l.positionsnummer !== 78040)) {
      if (a.preparationNeeded) {
        duration -= 15
      }

      if (duration > 20) {
        grossAmount += prices[78020].compensation
        copaymentAmount += prices[78020].copayment

        const positionIndex = positionsUnsorted.findIndex((p) => p.positionsnummer === 78020)
        if (positionIndex === -1) {
          positionsUnsorted.push({
            positionsnummer: 78020,
            faktor: 1,
          })
        } else {
          positionsUnsorted[positionIndex].faktor += 1
        }
      } else {
        grossAmount += prices[78010].compensation
        copaymentAmount += prices[78010].copayment

        const positionIndex = positionsUnsorted.findIndex((p) => p.positionsnummer === 78010)
        if (positionIndex === -1) {
          positionsUnsorted.push({
            positionsnummer: 78010,
            faktor: 1,
          })
        } else {
          positionsUnsorted[positionIndex].faktor += 1
        }
      }
    }

    if (hvo.hausbesuch && a.hausbesuch) {
      if (hvo.socialFacility) {
        grossAmount += prices[79934].compensation
        copaymentAmount += prices[79934].copayment

        const positionIndex = positionsUnsorted.findIndex((p) => p.positionsnummer === 79934)
        if (positionIndex === -1) {
          positionsUnsorted.push({
            positionsnummer: 79934,
            faktor: 1,
          })
        } else {
          positionsUnsorted[positionIndex].faktor += 1
        }
      } else if (hvo.medicallyPrescribed) {
        grossAmount += prices[79933].compensation
        copaymentAmount += prices[79933].copayment

        const positionIndex = positionsUnsorted.findIndex((p) => p.positionsnummer === 79933)
        if (positionIndex === -1) {
          positionsUnsorted.push({
            positionsnummer: 79933,
            faktor: 1,
          })
        } else {
          positionsUnsorted[positionIndex].faktor += 1
        }
      }
    }

    if (a.leistungen.some((l) => l.positionsnummer === 78040)) {
      grossAmount += prices[78040].compensation
      copaymentAmount += prices[78040].copayment

      const positionIndex = positionsUnsorted.findIndex((p) => p.positionsnummer === 78040)
      if (positionIndex === -1) {
        positionsUnsorted.push({
          positionsnummer: 78040,
          faktor: 1,
        })
      } else {
        positionsUnsorted[positionIndex].faktor += 1
      }
    } else {
      grossAmount += prices[78030].compensation
      copaymentAmount += prices[78030].copayment

      const positionIndex = positionsUnsorted.findIndex((p) => p.positionsnummer === 78030)
      if (positionIndex === -1) {
        positionsUnsorted.push({
          positionsnummer: 78030,
          faktor: 1,
        })
      } else {
        positionsUnsorted[positionIndex].faktor += 1
      }
    }
  })

  res.grossAmount = grossAmount
  res.copaymentAmount = copaymentAmount
  res.positionsCount = positionsUnsorted.slice().sort((a, b) => a.positionsnummer - b.positionsnummer)

  return res
}

export const calcHVOPrices = (hvo, state) => {
  const notCancelledEvents = hvo.termine.map((key) => state.entities.termine[key]).filter((a) => a && !a.absagegrund)

  const today = new Date()
  const hasFollowingEvents = !!getDateAfter(today, notCancelledEvents)

  let res: CalcHvoResult = {
    copaymentAmount: 0,
    grossAmount: 0,
    inputCancelTreatmentActive: false,
    inputHVOSettledActive: false,
    appointments: notCancelledEvents,
    positionsCount: [],
  }

  if (hvo.nagelkorrektur) {
    res = calcNagelkorrekturHvo(hvo, notCancelledEvents)
  } else {
    res = calcNormalHvo(hvo, notCancelledEvents)
  }

  if (hvo.gebuehrenbefreit) {
    res.copaymentAmount = 0
  }

  const numAppointmentsWithoutEingangsbefundung = notCancelledEvents.reduce((acc, curr) => {
    const servicesWithoutEingangsbefundung = curr.leistungen.filter((l) => l.positionsnummer !== 78040)

    if (servicesWithoutEingangsbefundung.length) {
      return acc + 1
    }

    return acc
  }, 0)

  if (numAppointmentsWithoutEingangsbefundung < hvo.verordnungsmenge && !hasFollowingEvents && !hvo.abgerechnet) {
    res.inputCancelTreatmentActive = true
  }

  if (
    hvo.nagelkorrektur ||
    hvo.behandlungAbgebrochen ||
    (numAppointmentsWithoutEingangsbefundung >= hvo.verordnungsmenge && !hasFollowingEvents) ||
    hvo.abgerechnet
  ) {
    res.inputHVOSettledActive = true
  }

  return res
}
