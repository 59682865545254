import { IconButton } from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'
import { createFilterOptions } from '@material-ui/lab'
import {
  addMinutes,
  differenceInMinutes,
  format,
  getDayOfYear,
  getHours,
  getMinutes,
  getYear,
  isAfter,
  isBefore,
  isDate,
  isSameDay,
  isValid,
  isWithinInterval,
  parseISO,
  set,
  setDayOfYear,
  setYear,
} from 'date-fns'
import debounce from 'lodash.debounce'
import { FC, useEffect, useMemo, useState } from 'react'
import { bindActionCreators, compose } from 'redux'
import { reduxForm } from 'redux-form'
import * as patientenActions from '../../actions/customers'
import { asyncValidateDate, loadDates } from '../../actions/dates'
import {
  GERMAN_DATE_LONG_DAY_FNS,
  GERMAN_DATE_LONG_YEAR_FNS,
  GERMAN_DATE_SHORT_YEAR_FNS,
} from '../../constants/dateFormats'
import { praxisstammdatenSelector } from '../../selectors/selectors'
import { AutocompleteField, MultipleAutocompleteField } from '../../shared/components/AutocompleteField'
import CheckboxField from '../../shared/components/CheckboxField/CheckboxField'
import DateField from '../../shared/components/DateField/DateField'
import InputField from '../../shared/components/InputField/InputField'
import { SelectFieldOption } from '../../shared/components/SelectField'
import { SelectField } from '../../shared/components/SelectField/SelectField'
import TimePickerField from '../../shared/components/TimePickerField/TimePickerField'
import countryCodes from '../../shared/static-data/countryCodes'
import { buildHeilmittelverordnungString, sortByAusstellungsdatumDescending } from '../../utils/heilmittelverordnungen'
import { buildLeistungenKKNagelkorrekturString, buildLeistungKKString } from '../../utils/leistungenKK'
import { buildLeistungPrivatString } from '../../utils/leistungenPrivat'
import { buildPatientString } from '../../utils/patienten'
import { sortByNachnameVorname } from '../../utils/persons'
import { validateHVOConditions, validateTerminConditions } from '../../utils/validation'
import CustomerFormSmall from './CustomerFormSmall'
import {
  istTerminAusserhalbDerArbeitszeiten,
  istTerminAusserhalbDerOeffnungszeiten,
  sHVOConditions,
  sIntersectsAllDayAppointment,
  sTerminConditions,
} from './selectors'
import { StyledTerminForm } from './StyledTerminForm'
import { sApiServer } from '../../shared/utils/auth'
import { isReadOnly } from '../../utils/helpers'
import { plan } from '../../shared/utils/constants'

export const fields = [
  'id',
  'beginn',
  'ende',
  'patient',
  'hausbesuch',
  'heilmittelverordnung',
  'hvoWirdNachgereicht',
  'leistungen',
  'notizen',
  'therapeut',
  'absagegrund',
  'absagedatum',
  'begruendungFrequenzueberschreitung',
  'modifiedAt',
  'istOrganisatorisch',
  'teilnehmerOrganisatorischerTermin',
  'titelOrganisatorischerTermin',
  'preparationNeeded',
  'room',
  'sevdeskinvoiceId',
]

const checkRoomFeatureEnabledDate = (enabledDate: Date | null, terminBeginn: Date) => {
  if (!enabledDate || !isValid(enabledDate) || !isValid(terminBeginn)) {
    return false
  }

  return isSameDay(terminBeginn, enabledDate) || isAfter(terminBeginn, enabledDate)
}

interface Props {
  actions?: any
  referenceDate?: {
    beginn?: any
    ende?: any
  }
  customers?: any
  error?: string
  onRequestClose?: () => void
  fields?: any
  busy?: boolean
  termine?: any
  loading?: any
  praxisstammdaten?: any
  terminIstAusserhalbDerOeffnungszeiten?: boolean
  terminIstAusserhalbDerArbeitszeiten?: boolean
  intersectsAllDayAppointment?: boolean

  events: any
  leistungenKK: any
  leistungenPrivat: any
  leistungenPrivatIds: Array<number>
  heilmittelverordnungenEntities: any
  heilmittelverordnungenIds: Array<number>
  people: any
  rooms: any
  HVOConditions: any

  submitFailed: boolean
  handleSubmit: any
}

const TerminForm: FC<Props> = ({
  actions,
  termine,
  fields,
  praxisstammdaten,
  events,
  intersectsAllDayAppointment,
  HVOConditions,
  terminIstAusserhalbDerOeffnungszeiten,
  terminIstAusserhalbDerArbeitszeiten,
  customers,
  people,
  rooms,
  submitFailed,
  heilmittelverordnungenIds,
  heilmittelverordnungenEntities,
  handleSubmit,
  leistungenKK,
  leistungenPrivat,
  leistungenPrivatIds,
  error: terminError,
  loading,
  currentServer,
}) => {
  const [dauer, setDauer] = useState<number | null>(null)
  const [customerFormOpen, setCustomerFormOpen] = useState<boolean>(false)
  const [mobilCountryPrefill, setMobilCountryPrefill] = useState<string | null>(null)
  const [lastAppointment, setLastAppointment] = useState<string | null>(null)
  const [isLastAppointmentCancelled, setIsLastAppointmentCancelled] = useState(false)
  const [nextAppointment, setNextAppointment] = useState<string | null>(null)
  const [isNextAppointmentCancelled, setIsNextAppointmentCancelled] = useState(false)
  const [patientInputValue, setPatientInputValue] = useState<string>('')
  const [customerFormOpenDate, setCustomerFormOpenDate] = useState<Date | null>(null)
  const [is15MinutesAdded, setIs15MinutesAdded] = useState<boolean>(fields.preparationNeeded.value || false)
  const [erstbefundungSelected, setErstbefundungSelected] = useState<boolean>(false)
  const [roomNoEquipmentWarning, setRoomNoEquipmentWarning] = useState<boolean>(false)

  const timeForPreparation: number = 15

  useEffect(() => {
    const patientId = fields?.initialValue?.patientId
    if (patientId) {
      actions.patienten.loadCustomer(patientId, true)
    }
  }, [])

  useEffect(() => {
    const patient = fields.patient.value || null
    const terminBeginn = fields.beginn.value || null

    if (patient && terminBeginn) {
      const terminArray: Array<any> = Object.values(termine)

      const pastAppointments = terminArray
        .filter(
          (termin) =>
            (!termin.absagegrund || termin.absagegrund === 'ohne Absage nicht erschienen') &&
            termin.patient === patient &&
            isBefore(termin.beginn, terminBeginn),
        )
        .sort((a, b) => b.beginn - a.beginn)
      let lastAppointmentLocal = '-'
      let isLastAppointmentCancelledLocal = false
      if (pastAppointments.length > 0) {
        lastAppointmentLocal = format(pastAppointments[0].beginn, 'dd.MM.yyyy HH:mm')
        isLastAppointmentCancelledLocal = pastAppointments[0].absagegrund === 'ohne Absage nicht erschienen'
      }

      const futureAppointments = terminArray
        .filter(
          (termin) =>
            (!termin.absagegrund || termin.absagegrund === 'ohne Absage nicht erschienen') &&
            termin.patient === patient &&
            isAfter(termin.beginn, terminBeginn),
        )
        .sort((a, b) => a.beginn - b.beginn)
      let nextAppointmentLocal = '-'
      let isNextAppointmentCancelledLocal = false
      if (futureAppointments.length > 0) {
        nextAppointmentLocal = format(futureAppointments[0].beginn, 'dd.MM.yyyy HH:mm')
        isNextAppointmentCancelledLocal = futureAppointments[0].absagegrund === 'ohne Absage nicht erschienen'
      }

      setLastAppointment(lastAppointmentLocal)
      setIsLastAppointmentCancelled(isLastAppointmentCancelledLocal)

      setNextAppointment(nextAppointmentLocal)
      setIsNextAppointmentCancelled(isNextAppointmentCancelledLocal)
    }
  }, [fields.patient.value, fields.beginn.value, termine])

  useEffect(() => {
    if (!fields.room.error) {
      const roomId = fields.room.value
      if (roomId) {
        const room = rooms[roomId]

        if (!room?.equipment?.length) {
          setRoomNoEquipmentWarning(true)
        } else {
          setRoomNoEquipmentWarning(false)
        }
      } else {
        setRoomNoEquipmentWarning(false)
      }
    } else {
      setRoomNoEquipmentWarning(false)
    }
  }, [fields.room.value])

  const allHvoTermine = useMemo(() => {
    if (fields.heilmittelverordnung.value && !!termine && Object.keys(termine).length > 0) {
      return Object.keys(termine)
        .map((k) => termine[k])
        .filter((termin) => termin.heilmittelverordnung === fields.heilmittelverordnung.value)
    }

    return []
  }, [termine, fields.patient.value, fields.heilmittelverordnung.value])

  const _findCountryCallingCode = (countryCode: string): string | null => {
    const selected = countryCodes.find((country) => country.code === countryCode)
    if (selected) {
      return selected.callingCode
    }
    return null
  }

  useEffect(() => {
    const localDauer = dauer || praxisstammdaten?.standardBehandlungsdauer || 5

    if (!fields?.ende?.value && fields?.beginn?.value) {
      fields.ende.onChange(addMinutes(beginn.value, Number(localDauer)))
    }
  }, [dauer, praxisstammdaten?.standardBehandlungsdauer, fields?.beginn?.value, fields?.ende?.value])

  useEffect(() => {
    if (!!fields.leistungen.value?.length) {
      const containsErstbefundung = fields.leistungen.value.some(
        (l) => l?.positionsnummer === 78100 || l?.positionsnummer === 78110,
      )

      setErstbefundungSelected(containsErstbefundung)
    } else {
      setErstbefundungSelected(false)
    }
  }, [fields.leistungen.value])

  useEffect(() => {
    if (dauer === null) {
      const { beginn, ende } = fields
      const standardBehandlungsdauer = praxisstammdaten.standardBehandlungsdauer
      let dauerLocal: number = 5
      if (standardBehandlungsdauer) {
        dauerLocal = Number(standardBehandlungsdauer)
      }
      if (beginn.initialValue && ende.initialValue) {
        dauerLocal = differenceInMinutes(ende.initialValue, beginn.initialValue)
      }
      if (beginn.initialValue && !ende.initialValue) {
        ende.onChange(addMinutes(beginn.initialValue, Number(dauerLocal)))
      }
      setDauer(dauerLocal)
    }

    if (mobilCountryPrefill === null) {
      const country = praxisstammdaten.land
      const mobilCountryCodeLocal = country ? _findCountryCallingCode(country) : '49'
      setMobilCountryPrefill(mobilCountryCodeLocal)
    }
  }, [
    fields?.beginn,
    fields?.ende,
    praxisstammdaten.land,
    dauer,
    mobilCountryPrefill,
    praxisstammdaten.standardBehandlungsdauer,
  ])

  const _handleCustomerFormRequestOpen = (event) => {
    event.preventDefault()
    setCustomerFormOpen(true)
    setCustomerFormOpenDate(new Date())
  }

  const _handleCustomerFormRequestClose = () => {
    setCustomerFormOpen(false)
    setCustomerFormOpenDate(null)
  }

  const _handlePatientValueChange = (data: string | number | null) => {
    if (data === null || isNaN(Number(data))) {
      fields.patient.onChange(null)
      fields.heilmittelverordnung.onChange(null)
      const leistungenOhneKK = (fields.leistungen.value || []).filter((item) => {
        if (!item) {
          return true
        }
        return !item.istKKLeistung
      })
      fields.leistungen.onChange(leistungenOhneKK)
    } else {
      actions.patienten.loadCustomer(data, true).then((customer) => {
        fields?.patient.onChange(data)
      })
    }
  }

  const _handlePatientInputUpdate = debounce((searchText: string) => {
    if (searchText.length > 0) {
      actions.patienten.findCustomers(searchText.replace(',', ''), ['nachname', 'vorname'])
    }
    setPatientInputValue(searchText)
  })

  const {
    beginn,
    patient,
    notizen,
    ende,
    therapeut,
    hausbesuch,
    heilmittelverordnung,
    leistungen,
    begruendungFrequenzueberschreitung,
    hvoWirdNachgereicht,
    preparationNeeded,
    room,
    sevdeskinvoiceId,
  } = fields

  const customersAutocompleteItems: Array<AutocompleteOption> = Object.keys(customers)
    .map((key) => customers[key])
    .sort(sortByNachnameVorname)
    .map((customer) => ({
      value: customer.id,
      text: buildPatientString(customer),
      filterText: `${customer.nachname} ${customer.vorname}`,
    }))

  const peopleSelect = Object.keys(people)
    .filter((key) => people[key].istTherapeut || parseInt(key, 10) === therapeut.value)
    .map((key) => {
      const person = people[key]
      return {
        ...person,
        displayName: [person.vorname, person.nachname].filter(Boolean).join(' '),
      }
    })

  const roomsSelect = Object.keys(rooms)
    .filter((key) => rooms[key] || parseInt(key, 10) === room.value)
    .map((key) => {
      const r = rooms[key]
      return {
        ...r,
        displayName: [r.abbreviation, r.name].filter(Boolean).join(' '),
      }
    })

  const customerData = patient.value ? customers[patient.value] : {}
  const selectRegularTherapist = customerData?.regularTherapist ? people[customerData?.regularTherapist] : {}

  const emptyPlaceholder = String.fromCharCode(8212) // Gedankenstrich: —
  const heilmittelverordnungenItems: Array<SelectFieldOption<string | number>> = [
    { value: 'null', text: emptyPlaceholder },
    ...(heilmittelverordnungenIds || [])
      .map((id) => heilmittelverordnungenEntities[id])
      .filter((hvo) => hvo.patient === patient.value)
      .sort(sortByAusstellungsdatumDescending)
      .map((hvo) => {
        const hvoString = buildHeilmittelverordnungString(hvo, termine, leistungenKK)
        return {
          value: hvo.id,
          text: hvoString,
        }
      }),
    { value: 'hvoWirdNachgereicht', text: 'HVO wird nachgereicht' },
  ]

  const kkLeistungSelected =
    leistungen.value &&
    leistungen.value.filter(
      (leistung) =>
        leistung && leistung.istKKLeistung && leistung.positionsnummer !== 78100 && leistung.positionsnummer !== 78110,
    ).length > 0

  const leistungenItemsKK: Array<AutocompleteOption> = Object.keys(leistungenKK)
    .map((key) => leistungenKK[key])
    .filter((leistungKK) => !leistungKK?.nagelkorrektur && !!leistungKK.kuerzel)
    .map((leistungKK) => {
      const leistungKKString = buildLeistungKKString(leistungKK)

      return {
        text: leistungKKString,
        value: { istKKLeistung: true, positionsnummer: leistungKK.positionsnummer },
        undeleteable: !!(kkLeistungSelected && !hvoWirdNachgereicht.value),
        unselectable:
          !!kkLeistungSelected ||
          !!hvoWirdNachgereicht.value ||
          (heilmittelverordnungenEntities[heilmittelverordnung.value] &&
            leistungKK.positionsnummer !== heilmittelverordnungenEntities[heilmittelverordnung.value].heilmittel),
      }
    })

  const eingangsbefundungSelectable = useMemo(() => {
    const termineForPatient = Object.keys(termine)
      .map((key) => termine[key])
      .filter((t) => t.patient === patient.value && (t.absagegrund === null || t.absagegrund === ''))

    // Not selectable if no hvo selected or is nagelkorrektur hvo
    if (
      !heilmittelverordnung.value ||
      heilmittelverordnung.value === 'hvoWirdNachgereicht' ||
      (heilmittelverordnungenEntities[heilmittelverordnung.value] &&
        !!heilmittelverordnungenEntities[heilmittelverordnung.value].nagelkorrektur)
    ) {
      return false
    }

    // Not selectable if appointment is before November 2023
    if (isBefore(beginn.value, new Date('2023-11-01'))) {
      return false
    }

    // Not selectable if patient had any prescribed appointments (excluding cancelled ones) before the one to be created
    if (
      termineForPatient.some((t) => {
        return !!t.heilmittelverordnung && isBefore(t.beginn, beginn.value)
      })
    ) {
      return false
    }

    // Not selectable if any other of the patient's appointments has "Eingangsbefundung" selected for "Leistungen"
    if (termineForPatient.some((t) => t.leistungen?.some((l) => l.positionsnummer === 78040))) {
      return false
    }

    return true
  }, [
    termine,
    patient.value,
    beginn.value,
    heilmittelverordnung.value,
    heilmittelverordnungenEntities,
    leistungenItemsKK,
  ])

  leistungenItemsKK.unshift({
    text: 'Eingangsbefundung',
    value: { istKKLeistung: true, positionsnummer: 78040 },
    unselectable: !eingangsbefundungSelectable,
    undeleteable: false,
  })

  const erstbefundungSelectable =
    !erstbefundungSelected &&
    heilmittelverordnungenEntities[heilmittelverordnung.value] &&
    !heilmittelverordnungenEntities[heilmittelverordnung.value].folgeverordnung &&
    !allHvoTermine.filter(
      (t) =>
        (t.absagegrund === null || t.absagegrund === '') &&
        !!t.leistungen.filter((l) => l?.istKKLeistung && (l?.positionsnummer === 78100 || l?.positionsnummer === 78110))
          .length,
    ).length

  const onlyErstbefundungSelectable =
    !erstbefundungSelected &&
    heilmittelverordnungenEntities[heilmittelverordnung.value] &&
    !heilmittelverordnungenEntities[heilmittelverordnung.value].folgeverordnung &&
    !allHvoTermine.filter((t) => t.absagegrund === null || t.absagegrund === '').length

  const leistungenItemsNagelkorrektur: Array<AutocompleteOption> = Object.keys(leistungenKK)
    .map((key) => leistungenKK[key])
    .filter((leistungKK) => !!leistungKK?.nagelkorrektur)
    .map((leistungKK) => {
      const leistungKKString = buildLeistungenKKNagelkorrekturString(leistungKK)
      return {
        text: leistungKKString,
        value: { istKKLeistung: true, positionsnummer: leistungKK.positionsnummer },
        undeleteable: !!(kkLeistungSelected && !hvoWirdNachgereicht.value),
        unselectable:
          (leistungKK.positionsnummer !== 78100 &&
            leistungKK.positionsnummer !== 78110 &&
            onlyErstbefundungSelectable) ||
          (leistungKK.positionsnummer !== 78100 && leistungKK.positionsnummer !== 78110 && !!kkLeistungSelected) ||
          hvoWirdNachgereicht.value ||
          !heilmittelverordnung.value ||
          (heilmittelverordnungenEntities[heilmittelverordnung.value] &&
            !heilmittelverordnungenEntities[heilmittelverordnung.value].nagelkorrektur) ||
          ((leistungKK.positionsnummer === 78100 || leistungKK.positionsnummer === 78110) && !erstbefundungSelectable),
      }
    })

  const leistungenItemsPrivat: Array<AutocompleteOption> = leistungenPrivatIds
    .map((key) => leistungenPrivat[key])
    .filter((leistungPrivat) => {
      return (
        !leistungPrivat.deleted || (!!leistungen?.value && leistungen?.value?.find((l) => l.id === leistungPrivat.id))
      )
    })
    .map((leistungPrivat) => {
      const leistungString = buildLeistungPrivatString(
        leistungPrivat,
        praxisstammdaten.currency === 'EUR' ? '€' : 'CHF',
      )
      return {
        text: leistungString,
        value: { istKKLeistung: false, id: leistungPrivat.id },
        unselectable: !!leistungPrivat.deleted,
      }
    })

  let leistungenItemsUnsorted = leistungenItemsPrivat

  if (
    heilmittelverordnung.value &&
    heilmittelverordnungenEntities[heilmittelverordnung.value] &&
    !heilmittelverordnungenEntities[heilmittelverordnung.value].nagelkorrektur
  ) {
    leistungenItemsUnsorted = leistungenItemsKK.concat(leistungenItemsPrivat)
  }

  if (
    heilmittelverordnung.value &&
    heilmittelverordnungenEntities[heilmittelverordnung.value] &&
    !!heilmittelverordnungenEntities[heilmittelverordnung.value].nagelkorrektur
  ) {
    leistungenItemsUnsorted = leistungenItemsNagelkorrektur.concat(leistungenItemsUnsorted)
  }

  const sortByKuerzel = (a, b) => {
    if (a.text > b.text) return 1
    if (a.text < b.text) return -1
    return 0
  }

  const leistungenItems = leistungenItemsUnsorted.sort(sortByKuerzel)

  const hausbesuchVerordnet =
    heilmittelverordnung.value &&
    heilmittelverordnungenEntities[heilmittelverordnung.value] &&
    heilmittelverordnungenEntities[heilmittelverordnung.value].hausbesuch === true

  const frequenzUeberschreitungVergangenheitMeldung = HVOConditions.frequenzueberschreitung.vergangenheit
    ? `Letzter Termin (${format(
        HVOConditions.frequenzueberschreitung.vergangenheit.beginn,
        GERMAN_DATE_SHORT_YEAR_FNS,
      )})`
    : null
  // Frequency exceeded future message
  // const frequenzUeberschreitungZukunftMeldung = HVOConditions.frequenzueberschreitung.zukunft
  //   ? `Nächster Termin (${format(HVOConditions.frequenzueberschreitung.zukunft.beginn, GERMAN_DATE_SHORT_YEAR_FNS)})`
  //   : null
  const frequenzunterschreitungTagVergangenheitMeldung = HVOConditions.frequenzunterschreitungTag.vergangenheit
    ? `Achtung! Min. Frequenz der HVO um ${HVOConditions.frequenzunterschreitungTag.differenzTage} Tag(e) unterschritten. Es kann zur Absetzung durch die KK kommen.`
    : null
  const frequenzunterschreitungTagZukunftMeldung = HVOConditions.frequenzunterschreitungTag.zukunft
    ? `Achtung! Der Folgetermin unterschreitet die min. Frequenz um ${HVOConditions.frequenzunterschreitungTag.differenzTage} Tag(e). Es kann zur Absetzung durch die KK kommen.`
    : null

  const eventWarning =
    beginn.value &&
    Object.keys(events).some((eventId) => {
      const { startDate, endDate } = events[eventId]
      return isWithinInterval(parseISO(beginn.value), {
        start: parseISO(startDate),
        end: parseISO(endDate),
      })
    })

  const hinweiseBeginn: Array<string> = []
  ;(eventWarning || intersectsAllDayAppointment) &&
    hinweiseBeginn.push('Es gibt min. einen ganztägigen Termin an diesem Tag.')
  ;(terminIstAusserhalbDerOeffnungszeiten || terminIstAusserhalbDerArbeitszeiten) &&
    hinweiseBeginn.push('Termin liegt außerhalb der Öffnungszeit, kann jedoch trotzdem angelegt werden!')
  frequenzunterschreitungTagVergangenheitMeldung && hinweiseBeginn.push(frequenzunterschreitungTagVergangenheitMeldung)
  frequenzunterschreitungTagZukunftMeldung && hinweiseBeginn.push(frequenzunterschreitungTagZukunftMeldung)

  const beginnError = terminError || hinweiseBeginn.join('\n')

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    ignoreAccents: true,
    stringify: (option: AutocompleteOption) => {
      return patientInputValue?.includes(',') ? option.text : option.text.replace(',', '')
    },
  })

  const updateDuration = (value: string | number | null) => {
    let duration = Number(value)
    if (isNaN(duration) || duration < 5) {
      duration = 5
    }
    setDauer(duration)
    let currentBegin = beginn.value || new Date()
    if (isNaN(currentBegin.getTime())) {
      currentBegin = new Date()
      beginn.onChange(currentBegin)
    }
    let newEnde = addMinutes(currentBegin, duration)
    ende.onChange(newEnde)
    // async validation ende
    ende.onBlur(newEnde)
  }

  useEffect(() => {
    if (!isNaN(heilmittelverordnung.value) && heilmittelverordnung.value !== null) {
      if (preparationNeeded.value && !is15MinutesAdded) {
        updateDuration(dauer + timeForPreparation)
        setIs15MinutesAdded(true)
      } else if (preparationNeeded.value === false && is15MinutesAdded) {
        updateDuration(dauer - timeForPreparation)
        setIs15MinutesAdded(false)
      }
    } else {
      if (is15MinutesAdded) {
        updateDuration(dauer - timeForPreparation)
        setIs15MinutesAdded(false)
      }
    }
  }, [heilmittelverordnung, preparationNeeded])

  useEffect(() => {
    // temporary fix for old HVOs
    if (
      leistungen.value &&
      leistungen.value.length &&
      heilmittelverordnung.value &&
      heilmittelverordnung.value !== 'hvoWirdNachgereicht'
    ) {
      const HVO = heilmittelverordnungenEntities[heilmittelverordnung.value]
      const currentLeistungen = leistungen.value.find((item) => item.istKKLeistung && item.positionsnummer !== 78040)

      if (
        heilmittelverordnungenEntities[heilmittelverordnung.value] &&
        !heilmittelverordnungenEntities[heilmittelverordnung.value]?.nagelkorrektur &&
        HVO.heilmittel !== currentLeistungen?.positionsnummer
      ) {
        const filteredLeistungens = leistungen.value.filter((item) => !item.istKKLeistung)
        leistungen.onChange([{ istKKLeistung: true, positionsnummer: HVO.heilmittel }, ...filteredLeistungens])
      }
    }

    // temporary fix for positionsnummer null on leistungen
    // after deploying nagelkorrektur hvo form, it was possible that positionsnummer is null, which was obviously not intended
    // this snipped is looking if a selected leistungKK has a positionsnummer and if not resets leistungen value to the remaining values with leistungKK filtered out
    if (
      leistungen.value &&
      leistungen.value.length &&
      heilmittelverordnung.value &&
      heilmittelverordnung.value !== 'hvoWirdNachgereicht'
    ) {
      const HVO = heilmittelverordnungenEntities[heilmittelverordnung.value]
      const currentLeistung = leistungen.value.find((item) => item.istKKLeistung)

      if (!currentLeistung?.positionsnummer) {
        leistungen.onChange(leistungen.value.filter((item) => !item.istKKLeistung))
      }
    }
  }, [])

  return (
    <StyledTerminForm>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <CheckboxField {...hausbesuch} label="Hausbesuch" />
        {hausbesuch.value && hausbesuchVerordnet === false ? (
          <span className="hausbesuchWarning">Achtung: Hausbesuch wurde nicht verordnet!</span>
        ) : null}
        {!isNaN(heilmittelverordnung.value) && heilmittelverordnung.value !== null && (
          <CheckboxField {...preparationNeeded} label="Vor-und Nachbereitungszeit" />
        )}
        {/* {beginn.value && ( */}
        <DateField
          className="field"
          disableToolbar
          format={GERMAN_DATE_LONG_DAY_FNS}
          value={beginn.value}
          label="Datum"
          error={!!beginn.error || !!beginnError}
          helperText={beginn.error || beginnError}
          onChange={(date) => {
            // update beginning and end against new dates and kickoff validation
            const dayOfYear = getDayOfYear(date as Date)
            const year = getYear(date as Date)
            let newBeginn = setDayOfYear(setYear(beginn.value || new Date(), year), dayOfYear)
            let newEnde = setDayOfYear(setYear(ende.value || newBeginn, year), dayOfYear)

            if (isNaN(newBeginn.getTime())) {
              newBeginn = new Date()
            }
            if (isNaN(newEnde.getTime())) {
              newEnde = newBeginn
            }

            // load dates for new day so they are available for client side validation
            if (!isSameDay(newBeginn, beginn.value)) {
              actions.termine.loadDates({ startDate: newBeginn, endDate: newBeginn })
            }

            beginn.onChange(newBeginn)
            ende.onChange(newEnde)
            // kickoff async validation if date changes
            // important: handover date as value will be 'undefined' from the beginning otherwise
            beginn.onBlur(newBeginn)
          }}
        />
        {/* )} */}
        <div className="row">
          {/* {beginn.value && ( */}
          <TimePickerField
            minutesStep={5}
            className="field"
            error={!!submitFailed && !!beginn.error}
            helperText={!!submitFailed && !!beginn.error ? beginn.error : ''}
            label="Beginn"
            value={beginn.value}
            onChange={(time: Date) => {
              const beginnHours = getHours(time)
              const beginnMinutes = getMinutes(time)
              const result = set(new Date(beginn.value), { hours: beginnHours, minutes: beginnMinutes })

              beginn.onChange(result)
              ende.onChange(addMinutes(result, Number(dauer)))
              beginn.onBlur(result)
            }}
          />
          {/* )} */}
          <InputField
            className="field"
            label="Dauer in Minuten"
            value={dauer}
            type="number"
            onChange={(value: string | number | null) => {
              // let duration = Number(value)
              // setDauer(duration || '')
              // const duration = Number(value)
              // this.setState({ dauer: duration })
              // let currentBegin =  beginn.value || new Date()
              // if(isNaN(currentBegin.getTime())) {
              //   currentBegin = new Date()
              //   beginn.onChange(currentBegin)
              // }
              // let newEnde = addMinutes(currentBegin, duration)
              // ende.onChange(newEnde)
              // // async validation ende
              // ende.onBlur(newEnde)
            }}
            onBlur={updateDuration}
          />
          {/* {ende.value && ( */}
          <TimePickerField
            minutesStep={5}
            className="field"
            error={!!submitFailed && !!ende.error}
            helperText={!!submitFailed && !!ende.error ? ende.error : ''}
            label="Ende"
            value={ende.value}
            onChange={(time: Date | null) => {
              let currentBegin = beginn.value || new Date()
              let newEnde = time || new Date()
              if (isNaN(currentBegin.getTime())) {
                currentBegin = new Date()
                beginn.onChange(currentBegin)
              }
              if (isNaN(newEnde.getTime())) {
                newEnde = new Date()
              }
              let newDauer = differenceInMinutes(newEnde, currentBegin)
              if (newDauer < 5) {
                newDauer = 5
                newEnde = addMinutes(currentBegin, 5)
              }
              setDauer(newDauer)
              ende.onChange(newEnde)
              // async validation ende
              ende.onBlur(newEnde)
            }}
          />
          {/* )} */}
        </div>
        <div className="field patientField">
          <AutocompleteField
            {...patient}
            label="Patient:in *"
            error={!!submitFailed && !customerFormOpen && !!patient.error}
            helperText={!!submitFailed && !customerFormOpen && !!patient.error ? patient.error : ''}
            freeSolo={true}
            options={customersAutocompleteItems}
            onInputChange={(value: string) => _handlePatientInputUpdate(value)}
            onChange={(value) => _handlePatientValueChange(value)}
            autoFocus={true}
            // filterOptions={filterOptions}
          />
          {!patient.value && !isReadOnly() && (
            <IconButton
              className="iconButton"
              size="small"
              onClick={_handleCustomerFormRequestOpen}
              disabled={loading.patienten}
            >
              <AddIcon />
            </IconButton>
          )}
        </div>
        <div className={`row ${praxisstammdaten?.treatmentRoomFeature ? 'twoItems' : 'oneItem'}`}>
          <SelectField
            className="field"
            {...therapeut}
            fullWidth
            label="Therapeut:in/Behandler:in *"
            error={!!therapeut.touched && !!therapeut.error}
            helperText={!!therapeut.touched && !!therapeut.error ? therapeut.error : ''}
            value={therapeut.value}
            options={peopleSelect.map((person) => {
              return { text: person.displayName, value: person.id }
            })}
          />
          {praxisstammdaten?.treatmentRoomFeature && (
            <SelectField
              className="field"
              {...room}
              fullWidth
              label={`Behandlungsraum ${
                praxisstammdaten?.treatmentRoomFeature &&
                praxisstammdaten?.roomSelectionMandatory &&
                checkRoomFeatureEnabledDate(praxisstammdaten?.roomFeatureEnabledAt, beginn.value)
                  ? '*'
                  : ''
              }`}
              error={!!room.touched && !!room.error}
              helperText={
                !!room.touched && !!room.error
                  ? room.error
                  : roomNoEquipmentWarning
                  ? 'Für den ausgewählten Raum ist keine Ausstattung hinterlegt!'
                  : ''
              }
              value={room.value}
              options={[
                {
                  text: emptyPlaceholder,
                  value: null,
                },
                ...roomsSelect.map((room) => {
                  return { text: room.name, value: room.id }
                }),
              ]}
            />
          )}
        </div>

        {patient.value && customers[patient.value] ? (
          <>
            {lastAppointment && (
              <p style={{ color: isLastAppointmentCancelled ? '#ff604f' : 'inherit' }}>
                Letzter Termin:{' '}
                {loading.patienten ? (
                  'suche vergangenheit...'
                ) : (
                  <>
                    {lastAppointment}
                    {isLastAppointmentCancelled ? ` (ohne Absage nicht erschienen!)` : false}
                  </>
                )}
              </p>
            )}
            {nextAppointment && (
              <p style={{ color: isNextAppointmentCancelled ? '#ff604f' : 'inherit' }}>
                Nächster Termin:{' '}
                {loading.patienten ? (
                  'suche zukünftige...'
                ) : (
                  <>
                    {nextAppointment}
                    {isNextAppointmentCancelled ? ` (ohne Absage nicht erschienen!)` : false}
                  </>
                )}
              </p>
            )}
          </>
        ) : null}
        {patient.value && (selectRegularTherapist.vorname || selectRegularTherapist.nachname) && (
          <div>
            Stammtherapeut:{' '}
            {[selectRegularTherapist.vorname, selectRegularTherapist.nachname].filter(Boolean).join(' ')}
          </div>
        )}

        {!!currentServer?.ownerPlanId && plan.find((p) => p.id === currentServer.ownerPlanId)?.name !== 'calendar' && (
          <SelectField
            className="field"
            {...heilmittelverordnung}
            label="Heilmittelverordnung (HVO)"
            error={!!heilmittelverordnung.touched && !!heilmittelverordnung.error}
            helperText={
              !!heilmittelverordnung.touched && !!heilmittelverordnung.error ? heilmittelverordnung.error : ''
            }
            onChange={(e) => {
              const { value } = e.target
              const leistungenOhneKK = (leistungen.value || []).filter((item) => {
                if (!item) {
                  return true
                }
                return !item.istKKLeistung
              })
              if (!value || value === 'null') {
                leistungen.onChange(leistungenOhneKK)
                hvoWirdNachgereicht.onChange(false)
              } else if (value === 'hvoWirdNachgereicht') {
                leistungen.onChange(leistungenOhneKK)
                hvoWirdNachgereicht.onChange(true)
              } else if (Number.isInteger(value)) {
                // Here we have to remove all KK services from the service array and add those of all HVOs again
                const hvo = heilmittelverordnungenEntities[value]
                const isNagelkorrektur =
                  (heilmittelverordnungenEntities[value] && heilmittelverordnungenEntities[value]?.nagelkorrektur) ||
                  false
                if (!isNagelkorrektur) {
                  const leistungKKId = hvo.heilmittel
                  const leistungKK = leistungKKId
                    ? leistungenItemsKK.filter((item) => item.value.positionsnummer === leistungKKId)[0].value
                    : null
                  const leistungenMitKK = leistungKK ? [leistungKK].concat(leistungenOhneKK) : leistungenOhneKK

                  leistungen.onChange(leistungenMitKK)
                }
                hvoWirdNachgereicht.onChange(false)
              }
              heilmittelverordnung.onChange(value)
            }}
            options={heilmittelverordnungenItems}
          />
        )}
        <MultipleAutocompleteField
          {...leistungen}
          className="field"
          label="Leistungen"
          options={leistungenItems}
          error={!!leistungen.touched && !!leistungen.error}
          helperText={!!leistungen.touched && !!leistungen.error ? leistungen.error : ''}
        />

        {/* {(frequenzUeberschreitungVergangenheitMeldung || frequenzUeberschreitungZukunftMeldung) && */}
        {frequenzUeberschreitungVergangenheitMeldung && !HVOConditions.verordnungsmengeErreicht && (
          <span>
            <span className="hide">Bitte Überschreitung der maximalen Behandlungsfrequenz begründen!</span>
            <InputField
              {...begruendungFrequenzueberschreitung}
              className="field"
              label="Begründung Frequenzüberschreitung"
              multiline
              fullWidth
              helperText={
                !!begruendungFrequenzueberschreitung.touched && !!begruendungFrequenzueberschreitung.error
                  ? begruendungFrequenzueberschreitung.error
                  : ''
              }
              error={!!begruendungFrequenzueberschreitung.touched && !!begruendungFrequenzueberschreitung.error}
            />
            <span>
              {frequenzUeberschreitungVergangenheitMeldung || null}
              {/* <br /> */}
            </span>
            {/* <span>{frequenzUeberschreitungZukunftMeldung}</span> */}
          </span>
        )}
        {patient.value && customers[patient.value] && customers[patient.value].notizen && (
          <InputField
            className="field"
            multiline
            label="Patientennotiz"
            readOnly
            value={customers[patient.value].notizen}
          />
        )}
        <InputField
          {...notizen}
          className="field"
          label="Terminnotiz"
          placeholder="Notiz zu diesem Termin"
          multiline
          rows={2}
        />
      </form>
      <CustomerFormSmall
        open={customerFormOpen}
        onRequestClose={_handleCustomerFormRequestClose}
        onChange={patient.onChange}
        initialValues={{
          patientSeit: customerFormOpenDate,
          vorname: null,
          nachname: patientInputValue,
          geschlecht: null,
          titel: null,
          geburtsdatum: null,
          strasse: null,
          hausnummer: null,
          postleitzahl: null,
          ort: null,
          telefon: null,
          mobil: null,
          email: null,
          emailErinnerung: null,
          emailNewsletter: null,
          krankenkasse: null,
          versichertennummer: null,
          hausarzt: null,
          facharzt: null,
          frequenz: null,
          empfehlung: null,
          bisherigePodologie: null,
          abgewandertZu: null,
          bild: null,
          verstorben: null,
          notizen: null,
          dateien: null,
          land: null,
          sozialeGemeinschaft: null,
          mobilCountry: mobilCountryPrefill,
          patientennummer: null,
          regularTherapist: null,
          versichertenstatus: null,
          ikNummerKK: null,
        }}
      />
    </StyledTerminForm>
  )
}

const validate = (values, props) => {
  const required = []

  const errors: KeyValue<string> = {}

  required.forEach((fieldName) => {
    if (!values[fieldName]) {
      errors[fieldName] = 'Bitte ausfüllen!'
    }
  })

  if (!values.patient) {
    errors.patient = 'Patienten wählen oder über + anlegen!'
  }

  if (values.patient && props.customers[values.patient] && props.customers[values.patient].verstorben) {
    errors.patient = 'Patient verstorben! Terminerstellung nicht möglich!'
  }

  if (!values.istOrganisatorisch && !values.therapeut) {
    errors.therapeut = 'Bitte Therapeuten auswählen!'
  }

  const isNagelkorrektur =
    !isNaN(Number(values.heilmittelverordnung)) &&
    props.heilmittelverordnungenEntities[values.heilmittelverordnung] &&
    props.heilmittelverordnungenEntities[values.heilmittelverordnung].nagelkorrektur

  if (!isNaN(Number(values.heilmittelverordnung))) {
    if (props.heilmittelverordnungenEntities[values.heilmittelverordnung]) {
      const selectedHVO = props.heilmittelverordnungenEntities[values.heilmittelverordnung]

      // temporary fix for old HVOs
      if (!selectedHVO.nagelkorrektur) {
        if (!selectedHVO.leitsymptomatik && !selectedHVO.diagnosegruppe) {
          selectedHVO.diagnosegruppe = 'Df'
          selectedHVO.leitsymptomatik = 'c'
        }

        const selectedHvoType = selectedHVO.leitsymptomatik
        let duration = differenceInMinutes(values.ende, values.beginn)
        if (values.preparationNeeded) {
          duration -= 15
        }

        if (values.leistungen?.some((l) => l.positionsnummer === 78040)) {
          if (selectedHvoType !== 'c' && duration > 40) {
            errors.ende = 'Der Termin darf nicht länger als 40 Minuten dauern'
          }
        } else {
          if (selectedHvoType !== 'c' && duration > 20) {
            errors.ende = 'Der Termin darf nicht länger als 20 Minuten dauern'
          }
        }
      }

      if (selectedHVO.nagelkorrektur) {
        if (
          values.leistungen &&
          (!values.leistungen?.length || !values.leistungen.filter((leistung) => leistung.istKKLeistung).length)
        ) {
          errors.leistungen = 'Bitte wählen Sie eine Leistung aus'
        }
      }
    }
  }

  const roomFeatureEnabled = props.praxisstammdaten?.treatmentRoomFeature
  const roomSelectionMandatory = props.praxisstammdaten?.roomSelectionMandatory
  const roomFeatureEnabledBeforeTerminBeginn = checkRoomFeatureEnabledDate(
    props.praxisstammdaten?.roomFeatureEnabledAt,
    values.beginn,
  )

  if (
    roomFeatureEnabled &&
    roomSelectionMandatory &&
    !values.istOrganisatorisch &&
    !values.hausbesuch &&
    checkRoomFeatureEnabledDate(props.praxisstammdaten?.roomFeatureEnabledAt, values.beginn) &&
    !values.room
  ) {
    errors.room = 'Bitte Behandlungsraum auswählen!'
  }

  if (!!values.room && !!values.leistungen?.length) {
    const terminLeistungenPrivat = values.leistungen.filter((l) => !l?.istKKLeistung)
    const room = Object.keys(props.rooms)
      .map((k) => props.rooms[k])
      .find((r) => r.id === values.room)

    if (room && !!room?.equipment?.length && !!terminLeistungenPrivat?.length) {
      terminLeistungenPrivat.forEach((leistung) => {
        const completeLeistung = props.leistungenPrivat[leistung.id]

        if (!!completeLeistung?.equipment?.length) {
          completeLeistung.equipment?.forEach((e) => {
            if (!room?.equipment?.includes(e)) {
              errors.room = 'Der Behandlungsraum ist nicht mit der benötigten Ausstattung ausgestattet'
            }
          })
        }
      })
    }
  }

  return {
    ...errors,
    ...validateTerminConditions(values, props),
    ...validateHVOConditions(values, props, isNagelkorrektur),
  }
}

async function asyncValidate(values, dispatch) {
  await dispatch(asyncValidateDate(values))
  return {}
}

const mapStateToProps = (state, props) => ({
  gridConfig: state.config.calendarGrid.default,
  termine: state.entities.termine,
  events: state.entities.events,
  busy: state.notification.busy,
  customers: state.entities.patienten,
  leistungenKK: state.leistungenKK,
  leistungenPrivat: state.entities.leistungenPrivat,
  leistungenPrivatIds: state.ids.leistungenPrivat,
  heilmittelverordnungenEntities: state.entities.heilmittelverordnungen,
  heilmittelverordnungenIds: state.ids.heilmittelverordnungen,
  people: state.entities.users,
  rooms: state.entities.rooms,
  auth: state.auth,
  loading: state.loading,
  praxisstammdaten: praxisstammdatenSelector(state), // @ts-ignore
  terminIstAusserhalbDerOeffnungszeiten: istTerminAusserhalbDerOeffnungszeiten(state, props), // @ts-ignore
  terminIstAusserhalbDerArbeitszeiten: istTerminAusserhalbDerArbeitszeiten(state, props),
  intersectsAllDayAppointment: sIntersectsAllDayAppointment(state),
  HVOConditions: sHVOConditions(state),
  terminConditions: sTerminConditions(state),
  currentServer: sApiServer(state),
})

const mapDispatchToProps = (dispatch, props) => ({
  onSubmit: function processFormData(data) {
    const hvoWirdNachgereicht = data.heilmittelverordnung === 'hvoWirdNachgereicht'
    const isEmptyPlaceholder = !data.heilmittelverordnung || data.heilmittelverordnung === 'null'
    const roomIsEmptyPlaceholder = !data.room || data.room === 'null'

    props.onSubmit({
      ...data,
      heilmittelverordnung: hvoWirdNachgereicht || isEmptyPlaceholder ? null : data.heilmittelverordnung,
      room: roomIsEmptyPlaceholder ? null : data.room,
      hvoWirdNachgereicht,
    })
  },
  actions: {
    patienten: bindActionCreators(patientenActions, dispatch),
    termine: bindActionCreators({ loadDates }, dispatch),
  },
})

export default compose(
  reduxForm(
    {
      form: 'terminForm',
      validate,
      asyncValidate,
      asyncBlurFields: ['beginn', 'ende'],
      fields,
    },
    mapStateToProps,
    mapDispatchToProps,
  ),
)(TerminForm)
