import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { differenceInWeeks, format, isAfter, isBefore } from 'date-fns'
import { FC, useRef } from 'react'
import styled from 'styled-components'
import { MessageError, MessageSuccess } from '../../shared/components/Message'
import absagegruende from './absagegruende'
import TerminAbsageForm from './TerminAbsageForm'

const warningMessages = {
  behandlungsbeginn:
    'Achtung: Der nächste Termin ({1}) liegt durch diese Absage nach dem spätesten Behandlungsbeginn ({2}) der HVO.',
  frequency:
    'Achtung: Der Abstand zwischen dem vorherigen Termin ({1}) und dem nächsten Termin ({2}) überschreitet durch diese Absage die max. Frequenz der HVO ({3} Wochen).',
  cancellation:
    'Achtung: Der Abstand zwischen dem vorherigen Termin ({1}) und dem nächsten Termin ({2}) überschreitet durch diese Absage 12 Wochen, wodurch es zur Absetzung durch die KK kommen kann.',
}

export const getWarningMessage = (key: keyof typeof warningMessages, ...args: string[]): string =>
  warningMessages[key].replace(/{(\d+)}/g, (_, index) => args[index - 1])

const terminAbsageNotifications = {
  request: 'Terminabsage wird gespeichert...',
  success: <MessageSuccess message={'Terminabsage erfolgreich gespeichert.'} />,
  failure: <MessageError message={'Speichern der Terminabsage fehlgeschlagen!'} />,
}

const WarningMessage = styled.p`
  color: red;
`

interface Props {
  termin?: any
  hvoTermine?: Appointment[]
  hvo?: Prescription
  open?: boolean
  patienten?: any
  closeDialog?: () => void
  terminActions?: any
  patientenActions?: any
}

const sortByDateAsc = (a, b) => {
  if (isBefore(a.beginn, b.beginn)) return -1
  if (isBefore(b.beginn, a.beginn)) return 1
  return 0
}

const TerminAbsageDialog: FC<Props> = ({
  termin,
  hvoTermine,
  hvo,
  open = false,
  patienten,
  closeDialog = () => {},
  terminActions,
  patientenActions,
}) => {
  const terminAbsageForm = useRef<any>()

  const _handleTerminAbsage = async (data) => {
    let findAbsagegruende = absagegruende.find((reason) => reason.id === data.absagegrund)
    const absagegrundStr = findAbsagegruende ? findAbsagegruende.text : null
    const terminPatient = patienten[data.termin.patient]

    closeDialog()

    switch (data.absagegrund) {
      // 'Neuvereinbarung folgt' / 'Sonstiges'
      case 1:
      case 4:
        await terminActions.updateDate(
          {
            ...data.termin,
            absagedatum: data.absagedatum,
            absagegrund: absagegrundStr,
            notizen: [data.termin.notizen, data.notizen].join('\n'),
          },
          terminAbsageNotifications,
        )
        return

      // 'Praxiswechsel'
      case 2:
        await patientenActions.updateCustomer(
          {
            ...terminPatient,
            abgewandertZu: data.abgewandertZu,
          },
          {
            request: 'Patient wird als abgewandert markiert...',
            success: (
              <MessageSuccess
                message={'Patient erfolgreich als abgewandert markiert. Alle zukünftigen Termine wurden abgesagt.'}
              />
            ),
            failure: <MessageError message={'Patient als abgewandert markieren fehlgeschlagen!'} />,
          },
        )
        await terminActions.updateDate(
          {
            ...data.termin,
            absagedatum: data.absagedatum,
            absagegrund: absagegrundStr,
          },
          {
            request: 'Patient wird als abgewandert markiert...',
            success: (
              <MessageSuccess
                message={'Patient erfolgreich als abgewandert markiert. Alle zukünftigen Termine wurden abgesagt.'}
              />
            ),
            failure: <MessageError message={'Patient als abgewandert markieren fehlgeschlagen!'} />,
          },
        )
        return

      // 'Patient verstorben'
      case 3:
        await terminActions.cancelDateDeceased(
          {
            ...data.termin,
            patient: terminPatient.id,
            absagegrund: 'Patient verstorben',
          },
          {
            request: 'Patient wird als verstorben markiert...',
            success: (
              <MessageSuccess
                message={'Patient erfolgreich als verstorben markiert. Alle zukünftigen Termine wurden abgesagt.'}
              />
            ),
            failure: <MessageError message={'Patient als verstorben markieren fehlgeschlagen!'} />,
          },
        )
        return

      default:
    }
  }

  const isHvoTermin = !!termin?.heilmittelverordnung
  const sortedHvoTermine = isHvoTermin && hvoTermine ? hvoTermine.slice().sort(sortByDateAsc) : []
  const isOnlyTermin = isHvoTermin && sortedHvoTermine.length === 1
  const terminIndexInArray = isHvoTermin ? sortedHvoTermine.findIndex((t) => t.id === termin.id) : -1
  const hasPrevTermin = isHvoTermin && terminIndexInArray !== -1 && !isOnlyTermin && terminIndexInArray > 0
  const hasNextTermin =
    isHvoTermin && terminIndexInArray !== -1 && !isOnlyTermin && terminIndexInArray < sortedHvoTermine.length - 1

  const prevTermin = isHvoTermin && hasPrevTermin ? sortedHvoTermine[terminIndexInArray - 1] : null
  const nextTermin = isHvoTermin && hasNextTermin ? sortedHvoTermine[terminIndexInArray + 1] : null

  // If latest treatment start of HVO is after the next appointment's start date, show warning
  const showBehandlungsbeginnWarning =
    isHvoTermin &&
    !hasPrevTermin &&
    hasNextTermin &&
    nextTermin &&
    isAfter(nextTermin.beginn, hvo?.behandlungsbeginnSpaetestensAm as Date)

  // If cancelling the selected appointment leads to an interval of more than 6 weeks and less than or equal to 12 between the previous and next appointment, show warning
  const showFrequencyWarning =
    isHvoTermin &&
    !showBehandlungsbeginnWarning &&
    hasPrevTermin &&
    hasNextTermin &&
    prevTermin &&
    nextTermin &&
    differenceInWeeks(nextTermin.beginn, prevTermin.beginn) > (hvo?.maxFrequenz ?? 6) &&
    differenceInWeeks(nextTermin.beginn, prevTermin.beginn) <= 12

  // If cancelling the selected appointment leads to an interval of more than 12 weeks between the previous and next appointment, show warning
  const showCancellationWarning =
    isHvoTermin &&
    !showBehandlungsbeginnWarning &&
    hasPrevTermin &&
    hasNextTermin &&
    prevTermin &&
    nextTermin &&
    differenceInWeeks(nextTermin.beginn, prevTermin.beginn) > 12

  return (
    <Dialog onClose={closeDialog} open={open} disableBackdropClick>
      <DialogTitle>Termin absagen</DialogTitle>
      <DialogContent>
        {termin && termin.patient && (
          <TerminAbsageForm
            ref={terminAbsageForm}
            initialValues={{
              absagegrund: null,
              absagedatum: new Date(),
              abgewandertZu: null,
              termin: termin,
            }}
            onSubmit={_handleTerminAbsage}
          />
        )}
        {showBehandlungsbeginnWarning && (
          <WarningMessage>
            {getWarningMessage(
              'behandlungsbeginn',
              format(nextTermin?.beginn, 'dd.MM.yy'),
              format(hvo?.behandlungsbeginnSpaetestensAm as Date, 'dd.MM.yy'),
            )}
          </WarningMessage>
        )}
        {showFrequencyWarning && (
          <WarningMessage>
            {getWarningMessage(
              'frequency',
              format(prevTermin?.beginn, 'dd.MM.yy'),
              format(nextTermin?.beginn, 'dd.MM.yy'),
              hvo?.maxFrequenz ? hvo.maxFrequenz.toString() : '6',
            )}
          </WarningMessage>
        )}
        {showCancellationWarning && (
          <WarningMessage>
            {getWarningMessage(
              'cancellation',
              format(prevTermin?.beginn, 'dd.MM.yy'),
              format(nextTermin?.beginn, 'dd.MM.yy'),
            )}
          </WarningMessage>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={closeDialog}>
          Abbrechen
        </Button>

        <Button variant="contained" color="secondary" onClick={() => terminAbsageForm?.current?.submit()}>
          Termin absagen
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default TerminAbsageDialog
