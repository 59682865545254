import { Divider, LinearProgress, List, ListItem, ListItemText } from '@material-ui/core'
import { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import FilePreview from './UploadPreview'

import * as fileActions from '../../../actions/files'
import { showNotification } from '../../../actions/notification'

import Compressor from 'compressorjs'

import {
  Add as AddIcon,
  CancelRounded as CancelRoundedIcon,
  CheckCircleRounded as CheckCircleRoundedIcon,
  Remove as RemoveIcon,
} from '@material-ui/icons'
import { hexStringFromArrayBuffer } from '../../../shared/utils/dataFormats'
import { StyledFileUpload, StyledFileUploadMessage } from './StyledUploadField'

class FileUpload extends Component {
  // static propTypes = {
  //   mimetypes: PropTypes.array,
  //   actions: PropTypes.object,
  //   dateien: PropTypes.object,
  //   style: PropTypes.object, // override component styles
  //   multiple: PropTypes.bool,
  //   maxFiles: PropTypes.number,
  //   onChange: PropTypes.func,
  //   onFinish: PropTypes.func,
  //   errorText: PropTypes.string,
  //   imageSizes: PropTypes.array,
  //   value: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
  //   // Format for maxFileSize (size in kb):
  //   // { 'image/jpeg': 5120 }
  //   maxFileSizes: PropTypes.object,
  //   buttonPrimaryText: PropTypes.string,
  //   buttonSecondaryText: PropTypes.string,
  //   assignTo: PropTypes.object,
  //   readOnly: PropTypes.bool,
  //   isHVOFormChanged: PropTypes.bool,
  // };

  // static contextTypes = {
  //   muiTheme: PropTypes.object
  // };

  static defaultProps = {
    imageSizes: [],
    maxFileSizes: {
      'image/jpeg': 10240,
      'image/png': 10240,
      'image/webp': 10240,
      'image/tiff': 10240,
      'image/gif': 10240,
    },
    readOnly: false,
    isHVOFormChanged: null,
    maxFiles: 4,
  }

  public input

  constructor(props) {
    super(props)
    this.state = { files: [], currentBatch: 0 }
    this._initState = this._initState.bind(this)
    this._getImageSize = this._getImageSize.bind(this)
  }

  componentDidMount() {
    this._initState(this.props)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { value } = this.props
    if (
      value !== undefined &&
      (nextProps.value === undefined || (Array.isArray(nextProps.value) && nextProps.value.length === 0))
    ) {
      this.setState({
        files: [],
      })
    }
    // falls keine Dateien im Component State vorhanden aber .value gesetzt ist, die passenden Dateien laden.
    this._initState(nextProps)
  }

  _initState(props) {
    const {
      value,
      actions: { files },
      imageSizes,
    } = props
    if (
      ((Array.isArray(value) && value.length > 0) || (!Array.isArray(value) && value)) &&
      this.state.files.length === 0
    ) {
      if (!this.state.triedLoading) {
        files.loadFiles(Array.isArray(value) ? value : [value], { sizes: imageSizes.length > 0 ? imageSizes : [] })
        this.setState({
          files: [],
          triedLoading: true,
        })
      }
      if (Array.isArray(value)) {
        const dateien = value.map((key) => props.dateien[key] && props.dateien[key])
        this.setState({
          files: dateien,
        })
      } else {
        if (props.dateien[value]) {
          this.setState({
            files: [props.dateien[value]],
          })
        }
      }
    }
  }

  _handleFiles = (e) => {
    const {
      target: { files },
    } = e
    if (files.length <= 0) {
      return
    }

    if (this._checkExceedsMaxFiles(files.length)) {
      this.props.actions.notification.showNotification(
        `Es können maximal ${this.props.maxFiles} Dateien hochgeladen werden. Bitte Auswahl wiederholen!`,
      )
      return
    }

    const currentCount = this.state.files.length

    for (let fi = 0; fi < files.length; fi++) {
      this._handleReadFile(files[fi], fi + currentCount)
    }
    this.setState({ currentBatch: files.length })

    this.input.value = ''
  }

  _getImageSize = async (fileBuffer: ArrayBuffer): Promise<{ width: number; height: number }> => {
    return new Promise((resolve, reject) => {
      const img = new Image()

      img.onload = () => {
        const { naturalHeight, naturalWidth } = img
        resolve({ width: naturalWidth, height: naturalHeight })
      }

      img.onerror = () => {
        reject(new Error('Error loading image'))
      }

      img.src = URL.createObjectURL(new Blob([fileBuffer]))
      img.remove()
    })
  }

  _handleReadFile = async (file: File, index) => {
    const {
      actions: {
        files: { uploadFile },
      },
      mimetypes,
      imageSizes,
      maxFileSizes,
      assignTo,
    } = this.props
    const { files } = this.state
    const { size, type, name } = file

    let mimeCorrect = true
    if (Array.isArray(mimetypes) && mimetypes.indexOf(type) === -1) {
      mimeCorrect = false
    }

    let fileSizeOK = false
    if (!maxFileSizes[type] || (maxFileSizes[type] && Math.round(size / 1024) <= maxFileSizes[type])) {
      fileSizeOK = true
    }

    if (mimeCorrect && fileSizeOK) {
      this.setState({
        files: [...files, { name, groesse: size, contentType: type, progress: 0, statusMessage: 'uploading' }],
      })

      const isImageType = /^image\//.test(type)
      const originalFileBuffer = await file.arrayBuffer()
      let resizedImage: Blob | null = null

      if (isImageType) {
        const imageWidthAndHeight = await this._getImageSize(originalFileBuffer)

        resizedImage = await new Promise((resolve, reject) => {
          new Compressor(file, {
            quality: 0.9,
            maxWidth: 1600,
            width: imageWidthAndHeight.width > 1600 ? 1600 : imageWidthAndHeight.width,
            success: resolve,
            error: reject,
          })
        })

        console.log('resizedImage size in KB: ', Math.round(resizedImage.size / 1024))
        console.log('original image size in KB: ', Math.round(size / 1024))
      }

      const hexString = hexStringFromArrayBuffer(resizedImage ? await resizedImage.arrayBuffer() : originalFileBuffer)

      uploadFile({
        file: {
          data: hexString,
          size,
          type,
          name,
          bezeichnung: null,
        },
        options: {
          thumbnailWidth: 128,
          thumbnailHeight: 128,
          sizes: imageSizes.length > 0 ? imageSizes : [],
        },
        progressCb: this._handleProgress(index),
        successCb: this._handleSuccess(index),
        failCb: this._handleFailure(index),
        assignTo,
      })
    } else if (!mimeCorrect) {
      this.setState({
        files: [...files, { name, groesse: size, contentType: type, progress: 0, statusMessage: 'mimetype_incorrect' }],
      })
    } else if (!fileSizeOK) {
      this.setState({
        files: [...files, { name, groesse: size, contentType: type, progress: 0, statusMessage: 'file_too_large' }],
      })
    }
  }

  _handleProgress = (index) => (progressPercent) => {
    const { files } = this.state
    if (!progressPercent) {
      return
    }
    this.setState({
      files: [...files.slice(0, index), { ...files[index], progress: progressPercent }, ...files.slice(index + 1)],
    })
  }

  _updateBatchProgress = () => {
    const currentBatch = this.state.currentBatch - 1
    this.setState({
      currentBatch,
    })
    const { onFinish } = this.props
    if (onFinish && currentBatch <= 0) {
      onFinish()
    }
  }

  _handleSuccess = (index) => (file) => {
    const { files } = this.state
    const { onChange, multiple } = this.props

    const newFiles = [
      ...files.slice(0, index),
      { ...file, statusMessage: 'success', progress: 100 },
      ...files.slice(index + 1),
    ]

    this.setState({
      files: newFiles,
    })

    if (onChange) {
      if (multiple) {
        onChange(newFiles.filter((fi) => !!fi.id && !fi.deleted).map((fi) => fi.id))
      } else {
        const tempFiles = newFiles.filter((fi) => !fi.deleted)
        if (tempFiles.length > 0) {
          onChange(tempFiles[0].id)
        } else {
          onChange(null)
        }
      }
    }

    this._updateBatchProgress()
  }

  _handleFailure = (index) => (response) => {
    const { files } = this.state

    this.setState({
      files: [
        ...files.slice(0, index),
        { ...files[index], statusMessage: 'failed', error: response.statusText },
        ...files.slice(index + 1),
      ],
    })

    this._updateBatchProgress()
  }

  _handleRemoveFile = (index) => () => {
    const { files } = this.state
    const { onChange, multiple } = this.props

    const newFiles = [...files.slice(0, index), { ...files[index], deleted: true }, ...files.slice(index + 1)]

    this.setState({
      files: newFiles,
    })

    if (onChange) {
      if (multiple) {
        onChange(newFiles.filter((fi) => !!fi.id && !fi.deleted).map((fi) => fi.id))
      } else {
        const tempFiles = newFiles.filter((fi) => !fi.deleted)
        if (tempFiles.length > 0) {
          onChange(tempFiles[0].id)
        } else {
          onChange(null)
        }
      }
    }
  }

  _checkEmpty = () => {
    const { files } = this.state
    if (files.length === 0) {
      return true
    }
    return files.every((element) => {
      return element.deleted
    })
  }

  _checkFull = () => {
    const { multiple, maxFiles } = this.props
    const { files } = this.state
    const actualFiles = files.filter((file) => file && !file.deleted)
    if (multiple) {
      return actualFiles.length >= maxFiles
    }
    return actualFiles.length === 1
  }

  _checkExceedsMaxFiles = (newFilesCount = 0) => {
    const { multiple, maxFiles } = this.props
    const { files } = this.state
    const actualFiles = files.filter((file) => file && !file.deleted)
    if (multiple) {
      return actualFiles.length + newFilesCount > maxFiles
    }
    return actualFiles.length > 0 ? newFilesCount > 0 : newFilesCount > 1
  }

  render() {
    const { multiple, errorText, mimetypes, buttonPrimaryText, buttonSecondaryText, readOnly } = this.props
    const { files } = this.state

    const statusMessage = {
      uploading: 'Lade hoch...',
      failed: (
        <StyledFileUploadMessage>
          Hochladen fehlgeschlagen!&nbsp;
          <CancelRoundedIcon htmlColor="IndianRed" />
        </StyledFileUploadMessage>
      ),
      success: (
        <StyledFileUploadMessage>
          Hochladen erfolgreich!&nbsp;
          <CheckCircleRoundedIcon htmlColor="green" />
        </StyledFileUploadMessage>
      ),
      mimetype_incorrect: (
        <StyledFileUploadMessage>
          Hochladen fehlgeschlagen!
          <br />
          Der Dateityp wird nicht unterstützt.&nbsp;
          <CancelRoundedIcon htmlColor="IndianRed" />
        </StyledFileUploadMessage>
      ),
      file_too_large: (
        <StyledFileUploadMessage>
          Hochladen fehlgeschlagen!
          <br />
          Die Datei ist zu groß.&nbsp;
          <CancelRoundedIcon htmlColor="IndianRed" />
        </StyledFileUploadMessage>
      ),
    }

    const buttonPrimaryDefaultText = multiple ? 'Dateien wählen' : 'Datei wählen'

    return (
      <StyledFileUpload>
        <input
          ref={(node) => (this.input = node)}
          className="fileUploadInput"
          type="file"
          multiple={multiple}
          accept={Array.isArray(mimetypes) ? mimetypes.join(',') : ''}
          onChange={this._handleFiles}
        />
        <List>
          <ListItem
            disabled={readOnly}
            className="uploadListItem"
            onClick={this._checkFull() || readOnly ? () => {} : () => this.input.click()}
          >
            <ListItemText
              primary={buttonPrimaryText || buttonPrimaryDefaultText}
              secondary={errorText ? <span className="uploadListItemError">{errorText}</span> : buttonSecondaryText}
            />
            {this._checkFull() ? null : (
              <>
                <AddIcon fontSize="large" className="addRemoveIcon add" />
              </>
            )}
          </ListItem>
        </List>

        {files && files.length > 0 ? (
          <>
            <Divider className="divider" />
            <List>
              {files.map(
                (file, ix) =>
                  file &&
                  !file.deleted && (
                    <ListItem
                      key={ix}
                      disabled={readOnly}
                      className="uploadedListItem"
                      onClick={readOnly ? () => {} : this._handleRemoveFile(ix)}
                    >
                      <div className="uploadedPreview">
                        <FilePreview {...file} message={statusMessage[file.statusMessage]} />
                        <RemoveIcon className="addRemoveIcon remove" fontSize="large" />
                      </div>
                      {!!file.progress && (
                        <LinearProgress
                          variant="determinate"
                          value={file.progress}
                          color="secondary"
                          className="linearProgress"
                        />
                      )}
                    </ListItem>
                  ),
              )}
            </List>
          </>
        ) : null}
      </StyledFileUpload>
    )
  }
}

const mapStateToProps = (state) => ({
  dateien: state.entities.dateien,
})
const mapDispatchToProps = (dispatch) => ({
  actions: {
    files: bindActionCreators(fileActions, dispatch),
    notification: bindActionCreators({ showNotification }, dispatch),
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(FileUpload)
