import { Euler, Vector3 } from 'three'

export const hotspots = [
  {
    id: 'li-1-apex',
    abbreviation: 'li. 1. apex',
    description: 'Links 1. Zeh Apex',
    position: new Vector3(0.11, -0.12, 0.426),
    rotation: new Euler(0.05, 0.15, 0, 'XYZ'),
  },
  {
    id: 'li-2-apex',
    abbreviation: 'li. 2. apex',
    description: 'Links 2. Zeh Apex',
    position: new Vector3(0.17, -0.14, 0.406),
    rotation: new Euler(0.05, 0.15, 0, 'XYZ'),
  },
  {
    id: 'li-3-apex',
    abbreviation: 'li. 3. apex',
    description: 'Links 3. Zeh Apex',
    position: new Vector3(0.205, -0.145, 0.375),
    rotation: new Euler(0.05, 0.15, 0, 'XYZ'),
  },
  {
    id: 'li-4-apex',
    abbreviation: 'li. 4. apex',
    description: 'Links 4. Zeh Apex',
    position: new Vector3(0.235, -0.148, 0.3375),
  },
  {
    id: 'li-5-apex',
    abbreviation: 'li. 5. apex',
    description: 'Links 5. Zeh Apex',
    position: new Vector3(0.265, -0.147, 0.282),
    rotation: new Euler(0.2, -0.15, 0, 'XYZ'),
  },
  {
    id: 're-1-apex',
    abbreviation: 're. 1. apex',
    description: 'Rechts 1. Zeh Apex',
    position: new Vector3(-0.15, -0.115, 0.422),
    rotation: new Euler(0.15, -0.2, 0, 'XYZ'),
  },
  {
    id: 're-2-apex',
    abbreviation: 're. 2. apex',
    description: 'Rechts 2. Zeh Apex',
    position: new Vector3(-0.21, -0.14, 0.402),
    rotation: new Euler(0.15, -0.25, 0, 'XYZ'),
  },
  {
    id: 're-3-apex',
    abbreviation: 're. 3. apex',
    description: 'Rechts 3. Zeh Apex',
    position: new Vector3(-0.245, -0.145, 0.365),
    rotation: new Euler(0.15, 0, 0, 'XYZ'),
  },
  {
    id: 're-4-apex',
    abbreviation: 're. 4. apex',
    description: 'Rechts 4. Zeh Apex',
    position: new Vector3(-0.27, -0.15, 0.33),
  },
  {
    id: 're-5-apex',
    abbreviation: 're. 5. apex',
    description: 'Rechts 5. Zeh Apex',
    position: new Vector3(-0.3, -0.142, 0.275),
  },
  {
    id: 'li-o1',
    abbreviation: 'li. O1',
    description: 'Links 1. Zeh Onyx',
    position: new Vector3(0.11, -0.093, 0.39),
    rotation: new Euler(-1.5, 0, 0, 'XYZ'),
  },
  {
    id: 'li-o2',
    abbreviation: 'li. O2',
    description: 'Links 2. Zeh Onyx',
    position: new Vector3(0.173, -0.115, 0.4),
    rotation: new Euler(-1.25, 0, 0, 'XYZ'),
  },
  {
    id: 'li-o3',
    abbreviation: 'li. O3',
    description: 'Links 3. Zeh Onyx',
    position: new Vector3(0.21, -0.118, 0.373),
    rotation: new Euler(-1.25, 0, 0, 'XYZ'),
  },
  {
    id: 'li-o4',
    abbreviation: 'li. O4',
    description: 'Links 4. Zeh Onyx',
    position: new Vector3(0.248, -0.127, 0.335),
    rotation: new Euler(-1.25, 0, 0, 'XYZ'),
  },
  {
    id: 'li-o5',
    abbreviation: 'li. O5',
    description: 'Links 5. Zeh Onyx',
    position: new Vector3(0.278, -0.127, 0.282),
    rotation: new Euler(-1.25, 0, 0, 'XYZ'),
  },
  {
    id: 're-o1',
    abbreviation: 're. O1',
    description: 'Rechts 1. Zeh Onyx',
    position: new Vector3(-0.148, -0.085, 0.387),
    rotation: new Euler(-1.5, 0, 0, 'XYZ'),
  },
  {
    id: 're-o2',
    abbreviation: 're. O2',
    description: 'Rechts 2. Zeh Onyx',
    position: new Vector3(-0.21, -0.109, 0.398),
    rotation: new Euler(-1.25, 0, 0, 'XYZ'),
  },
  {
    id: 're-o3',
    abbreviation: 're. O3',
    description: 'Rechts 3. Zeh Onyx',
    position: new Vector3(-0.246, -0.115, 0.366),
    rotation: new Euler(-1, -0.25, 0, 'XYZ'),
  },
  {
    id: 're-o4',
    abbreviation: 're. O4',
    description: 'Rechts 4. Zeh Onyx',
    position: new Vector3(-0.284, -0.124, 0.33),
    rotation: new Euler(-1, -0.25, 0, 'XYZ'),
  },
  {
    id: 're-o5',
    abbreviation: 're. O5',
    description: 'Rechts 5. Zeh Onyx',
    position: new Vector3(-0.315, -0.12, 0.275),
    rotation: new Euler(-0.8, -0.5, 0, 'XYZ'),
  },
  {
    id: 'li-s1-med',
    abbreviation: 'li. S1 med.',
    description: 'Links 1. Zeh Sulcus medial',
    position: new Vector3(0.09, -0.096, 0.41),
    rotation: new Euler(-1.5, 0, 0, 'XYZ'),
  },
  {
    id: 'li-s1-lat',
    abbreviation: 'li. S1 lat.',
    description: 'Links 1. Zeh Sulcus lateral',
    position: new Vector3(0.13, -0.096, 0.4),
    rotation: new Euler(-1.5, 0, 0, 'XYZ'),
  },
  {
    id: 'li-s2-med',
    abbreviation: 'li. S2 med.',
    description: 'Links 2. Zeh Sulcus medial',
    position: new Vector3(0.16, -0.118, 0.4),
    rotation: new Euler(-1.25, 0, 0, 'XYZ'),
  },
  {
    id: 'li-s2-lat',
    abbreviation: 'li. S2 lat.',
    description: 'Links 2. Zeh Sulcus lateral',
    position: new Vector3(0.185, -0.12, 0.4),
    rotation: new Euler(-1.25, 0, 0, 'XYZ'),
  },
  {
    id: 'li-s3-med',
    abbreviation: 'li. S3 med.',
    description: 'Links 3. Zeh Sulcus medial',
    position: new Vector3(0.2, -0.122, 0.373),
    rotation: new Euler(-1.25, 0, 0, 'XYZ'),
  },
  {
    id: 'li-s3-lat',
    abbreviation: 'li. S3 lat.',
    description: 'Links 3. Zeh Sulcus lateral',
    position: new Vector3(0.22, -0.125, 0.373),
    rotation: new Euler(-1.25, 0, 0, 'XYZ'),
  },
  {
    id: 'li-s4-med',
    abbreviation: 'li. S4 med.',
    description: 'Links 4. Zeh Sulcus medial',
    position: new Vector3(0.24, -0.127, 0.338),
    rotation: new Euler(-1.25, 0, 0, 'XYZ'),
  },
  {
    id: 'li-s4-lat',
    abbreviation: 'li. S4 lat.',
    description: 'Links 4. Zeh Sulcus lateral',
    position: new Vector3(0.255, -0.134, 0.335),
    rotation: new Euler(-1.25, 0, 0, 'XYZ'),
  },
  {
    id: 'li-s5-med',
    abbreviation: 'li. S5 med.',
    description: 'Links 5. Zeh Sulcus medial',
    position: new Vector3(0.271, -0.127, 0.282),
    rotation: new Euler(-1.25, -0.25, 0, 'XYZ'),
  },
  {
    id: 'li-s5-lat',
    abbreviation: 'li. S5 lat.',
    description: 'Links 5. Zeh Sulcus lateral',
    position: new Vector3(0.283, -0.131, 0.282),
    rotation: new Euler(-1.25, 0.5, 0, 'XYZ'),
  },
  {
    id: 're-s1-med',
    abbreviation: 're. S1 med.',
    description: 'Rechts 1. Zeh Sulcus medial',
    position: new Vector3(-0.127, -0.09, 0.41),
    rotation: new Euler(-1.5, 0.5, 0, 'XYZ'),
  },
  {
    id: 're-s1-lat',
    abbreviation: 're. S1 lat.',
    description: 'Rechts 1. Zeh Sulcus lateral',
    position: new Vector3(-0.17, -0.09, 0.395),
    rotation: new Euler(-1.5, -0.5, 0, 'XYZ'),
  },
  {
    id: 're-s2-med',
    abbreviation: 're. S2 med.',
    description: 'Rechts 2. Zeh Sulcus medial',
    position: new Vector3(-0.195, -0.111, 0.401),
    rotation: new Euler(-1.25, 0, 0, 'XYZ'),
  },
  {
    id: 're-s2-lat',
    abbreviation: 're. S2 lat.',
    description: 'Rechts 2. Zeh Sulcus lateral',
    position: new Vector3(-0.22, -0.115, 0.399),
    rotation: new Euler(-1.25, 0, 0, 'XYZ'),
  },
  {
    id: 're-s3-med',
    abbreviation: 're. S3 med.',
    description: 'Rechts 3. Zeh Sulcus medial',
    position: new Vector3(-0.237, -0.115, 0.369),
    rotation: new Euler(-1, 0, 0, 'XYZ'),
  },
  {
    id: 're-s3-lat',
    abbreviation: 're. S3 lat.',
    description: 'Rechts 3. Zeh Sulcus lateral',
    position: new Vector3(-0.258, -0.12, 0.365),
    rotation: new Euler(-1, -0.25, 0, 'XYZ'),
  },
  {
    id: 're-s4-med',
    abbreviation: 're. S4 med.',
    description: 'Rechts 4. Zeh Sulcus medial',
    position: new Vector3(-0.275, -0.124, 0.33),
    rotation: new Euler(-1, 0, 0, 'XYZ'),
  },
  {
    id: 're-s4-lat',
    abbreviation: 're. S4 lat.',
    description: 'Rechts 4. Zeh Sulcus lateral',
    position: new Vector3(-0.29, -0.13, 0.328),
    rotation: new Euler(-1, -0.25, 0, 'XYZ'),
  },
  {
    id: 're-s5-med',
    abbreviation: 're. S5 med.',
    description: 'Rechts 5. Zeh Sulcus medial',
    position: new Vector3(-0.306, -0.119, 0.275),
    rotation: new Euler(-0.8, 0, 0, 'XYZ'),
  },
  {
    id: 're-s5-lat',
    abbreviation: 're. S5 lat.',
    description: 'Rechts 5. Zeh Sulcus lateral',
    position: new Vector3(-0.323, -0.126, 0.275),
    rotation: new Euler(-0.8, -0.5, 0, 'XYZ'),
  },
  {
    id: 'li-o1-subung',
    abbreviation: 'li. O1 subung.',
    description: 'Links 1. Zeh Onyx subungual',
    position: new Vector3(0.11, -0.095, 0.42),
    rotation: new Euler(-0.25, 0.15, 0, 'XYZ'),
  },
  {
    id: 'li-o2-subung',
    abbreviation: 'li. O2 subung.',
    description: 'Links 1. Zeh Onyx subungual',
    position: new Vector3(0.17, -0.128, 0.406),
    rotation: new Euler(0.05, 0.15, 0, 'XYZ'),
  },
  {
    id: 'li-o3-subung',
    abbreviation: 'li. O3 subung.',
    description: 'Links 1. Zeh Onyx subungual',
    position: new Vector3(0.205, -0.133, 0.375),
    rotation: new Euler(0.05, 0.15, 0, 'XYZ'),
  },
  {
    id: 'li-o4-subung',
    abbreviation: 'li. O4 subung.',
    description: 'Links 1. Zeh Onyx subungual',
    position: new Vector3(0.24, -0.141, 0.3375),
  },
  {
    id: 'li-o5-subung',
    abbreviation: 'li. O5 subung.',
    description: 'Links 1. Zeh Onyx subungual',
    position: new Vector3(0.27, -0.138, 0.284),
    rotation: new Euler(0.2, -0.15, 0, 'XYZ'),
  },
  {
    id: 're-o1-subung',
    abbreviation: 're. O1 subung.',
    description: 'Rechts 1. Zeh Onyx subungual',
    position: new Vector3(-0.15, -0.09, 0.422),
    rotation: new Euler(0, -0.2, 0, 'XYZ'),
  },
  {
    id: 're-o2-subung',
    abbreviation: 're. O2 subung.',
    description: 'Rechts 1. Zeh Onyx subungual',
    position: new Vector3(-0.21, -0.12, 0.402),
    rotation: new Euler(0.15, -0.25, 0, 'XYZ'),
  },
  {
    id: 're-o3-subung',
    abbreviation: 're. O3 subung.',
    description: 'Rechts 1. Zeh Onyx subungual',
    position: new Vector3(-0.245, -0.127, 0.375),
    rotation: new Euler(0.15, 0, 0, 'XYZ'),
  },
  {
    id: 're-o4-subung',
    abbreviation: 're. O4 subung.',
    description: 'Rechts 1. Zeh Onyx subungual',
    position: new Vector3(-0.278, -0.135, 0.335),
  },
  {
    id: 're-o5-subung',
    abbreviation: 're. O5 subung.',
    description: 'Rechts 1. Zeh Onyx subungual',
    position: new Vector3(-0.31, -0.132, 0.28),
  },
  {
    id: 'li-met-1',
    abbreviation: 'li. Met. 1',
    description: 'Links 1. Zeh metatarsal',
    position: new Vector3(0.075, -0.167, 0.21),
    rotation: new Euler(-1.5, 0, 0, 'XYZ'),
  },
  {
    id: 'li-met-2',
    abbreviation: 'li. Met. 2',
    description: 'Links 2. Zeh metatarsal',
    position: new Vector3(0.14, -0.167, 0.22),
    rotation: new Euler(-1.5, 0, 0, 'XYZ'),
  },
  {
    id: 'li-met-3',
    abbreviation: 'li. Met. 3',
    description: 'Links 3. Zeh metatarsal',
    position: new Vector3(0.175, -0.167, 0.21),
    rotation: new Euler(-1.5, 0, 0, 'XYZ'),
  },
  {
    id: 'li-met-4',
    abbreviation: 'li. Met. 4',
    description: 'Links 4. Zeh metatarsal',
    position: new Vector3(0.21, -0.167, 0.19),
    rotation: new Euler(-1.5, 0, 0, 'XYZ'),
  },
  {
    id: 'li-met-5',
    abbreviation: 'li. Met. 5',
    description: 'Links 5. Zeh metatarsal',
    position: new Vector3(0.235, -0.167, 0.17),
    rotation: new Euler(-1.5, 0, 0, 'XYZ'),
  },
  {
    id: 're-met-1',
    abbreviation: 're. Met.1',
    description: 'Rechts 1. Zeh metatarsal',
    position: new Vector3(-0.115, -0.167, 0.2),
    rotation: new Euler(-1.5, 0, 0, 'XYZ'),
  },
  {
    id: 're-met-2',
    abbreviation: 're. Met.2',
    description: 'Rechts 2. Zeh metatarsal',
    position: new Vector3(-0.18, -0.167, 0.21),
    rotation: new Euler(-1.5, 0, 0, 'XYZ'),
  },
  {
    id: 're-met-3',
    abbreviation: 're. Met.3',
    description: 'Rechts 3. Zeh metatarsal',
    position: new Vector3(-0.215, -0.167, 0.2),
    rotation: new Euler(-1.5, 0, 0, 'XYZ'),
  },
  {
    id: 're-met-4',
    abbreviation: 're. Met.4',
    description: 'Rechts 4. Zeh metatarsal',
    position: new Vector3(-0.25, -0.167, 0.18),
    rotation: new Euler(-1.5, 0, 0, 'XYZ'),
  },
  {
    id: 're-met-5',
    abbreviation: 're. Met.5',
    description: 'Rechts 5. Zeh metatarsal',
    position: new Vector3(-0.275, -0.167, 0.16),
    rotation: new Euler(-1.5, 0, 0, 'XYZ'),
  },
  {
    id: 'li-1-pl',
    abbreviation: 'li. 1. pl.',
    description: 'Links 1. Zeh plantar',
    position: new Vector3(0.11, -0.155, 0.39),
    rotation: new Euler(-1.5, 0, 0, 'XYZ'),
  },
  {
    id: 'li-2-pl',
    abbreviation: 'li. 2. pl.',
    description: 'Links 2. Zeh plantar',
    position: new Vector3(0.165, -0.157, 0.375),
    rotation: new Euler(-1.5, 0, 0, 'XYZ'),
  },
  {
    id: 'li-3-pl',
    abbreviation: 'li. 3. pl.',
    description: 'Links 3. Zeh plantar',
    position: new Vector3(0.2, -0.156, 0.35),
    rotation: new Euler(-1.5, 0, 0, 'XYZ'),
  },
  {
    id: 'li-4-pl',
    abbreviation: 'li. 4. pl.',
    description: 'Links 4. Zeh plantar',
    position: new Vector3(0.235, -0.159, 0.307),
    rotation: new Euler(-1.5, 0, 0, 'XYZ'),
  },
  {
    id: 'li-5-pl',
    abbreviation: 'li. 5. pl.',
    description: 'Links 5. Zeh plantar',
    position: new Vector3(0.265, -0.157, 0.25),
    rotation: new Euler(-1.5, 0, 0, 'XYZ'),
  },
  {
    id: 're-1-pl',
    abbreviation: 're. 1. pl.',
    description: 'Rechts 1. Zeh plantar',
    position: new Vector3(-0.15, -0.153, 0.384),
    rotation: new Euler(-1.5, 0, 0, 'XYZ'),
  },
  {
    id: 're-2-pl',
    abbreviation: 're. 2. pl.',
    description: 'Rechts 2. Zeh plantar',
    position: new Vector3(-0.205, -0.152, 0.375),
    rotation: new Euler(-1.5, 0, 0, 'XYZ'),
  },
  {
    id: 're-3-pl',
    abbreviation: 're. 3. pl.',
    description: 'Rechts 3. Zeh plantar',
    position: new Vector3(-0.24, -0.149, 0.34),
    rotation: new Euler(-1.5, 0, 0, 'XYZ'),
  },
  {
    id: 're-4-pl',
    abbreviation: 're. 4. pl.',
    description: 'Rechts 4. Zeh plantar',
    position: new Vector3(-0.27, -0.155, 0.304),
    rotation: new Euler(-1.5, 0, 0, 'XYZ'),
  },
  {
    id: 're-5-pl',
    abbreviation: 're. 5. pl.',
    description: 'Rechts 5. Zeh plantar',
    position: new Vector3(-0.3, -0.15, 0.25),
    rotation: new Euler(-1.5, 0, 0, 'XYZ'),
  },
  {
    id: 're-cal-pl',
    abbreviation: 're. Cal. pl.',
    description: 'Rechts Calcaneum plantar',
    position: new Vector3(-0.15, -0.139, -0.2),
    rotation: new Euler(-1.5, 0, 0, 'XYZ'),
  },
  {
    id: 'li-cal-pl',
    abbreviation: 'li. Cal. pl.',
    description: 'Links Calcaneum plantar',
    position: new Vector3(0.11, -0.142, -0.2),
    rotation: new Euler(-1.5, 0, 0, 'XYZ'),
  },
  {
    id: 're-cal',
    abbreviation: 're. Cal.',
    description: 'Rechts Calcaneum',
    position: new Vector3(-0.14, -0.05, -0.322),
    rotation: new Euler(0, 0, 0, 'XYZ'),
  },
  {
    id: 'li-cal',
    abbreviation: 'li. Cal.',
    description: 'Links Calcaneum',
    position: new Vector3(0.1, -0.05, -0.318),
    rotation: new Euler(0, 0, 0, 'XYZ'),
  },
  {
    id: 'li-cal-lat',
    abbreviation: 'li. Cal. lat.',
    description: 'Links Calcaneum lateral',
    position: new Vector3(0.188, -0.08, -0.25),
    rotation: new Euler(0, 1.5, 0, 'XYZ'),
  },
  {
    id: 'li-aussenknoechel',
    abbreviation: 'li. Außenknöchel',
    description: 'Links Malleolus lateralis',
    position: new Vector3(0.219, 0.06, -0.19),
    rotation: new Euler(0, 1.5, 0, 'XYZ'),
  },
  {
    id: 'li-kleinzehenballen',
    abbreviation: 'li. Kleinzehenballen',
    description: 'Links Kleinzehenballen',
    position: new Vector3(0.3, -0.132, 0.15),
    rotation: new Euler(0, 1.5, 0, 'XYZ'),
  },
  {
    id: 'li-cal-med',
    abbreviation: 'li. Cal. med.',
    description: 'Links Calcaneum metatarsal',
    position: new Vector3(0.027, -0.08, -0.25),
    rotation: new Euler(0, 1.5, 0, 'XYZ'),
  },
  {
    id: 'li-innenknoechel',
    abbreviation: 'li. Innenknöchel',
    description: 'Links Malleolus medialis',
    position: new Vector3(0.027, 0.12, -0.09),
    rotation: new Euler(0, 1.5, 0, 'XYZ'),
  },
  {
    id: 'li-grosszehenballen',
    abbreviation: 'li. Großzehenballen',
    description: 'Links Großzehenballen',
    position: new Vector3(0.034, -0.115, 0.26),
    rotation: new Euler(0, 1.5, 0, 'XYZ'),
  },
  {
    id: 're-cal-lat',
    abbreviation: 're. Cal. lat.',
    description: 'Rechts Calcaneum lateral',
    position: new Vector3(-0.223, -0.08, -0.25),
    rotation: new Euler(0, -1.5, 0, 'XYZ'),
  },
  {
    id: 're-aussenknoechel',
    abbreviation: 're. Außenknöchel',
    description: 'Rechts Malleolus lateralis',
    position: new Vector3(-0.26, 0.06, -0.17),
    rotation: new Euler(0, -1.5, 0, 'XYZ'),
  },
  {
    id: 're-kleinzehenballen',
    abbreviation: 're. Kleinzehenballen',
    description: 'Rechts Kleinzehenballen',
    position: new Vector3(-0.335, -0.118, 0.15),
    rotation: new Euler(0, -1.5, 0, 'XYZ'),
  },
  {
    id: 're-cal-med',
    abbreviation: 're. Cal. med.',
    description: 'Rechts Calcaneum lateral',
    position: new Vector3(-0.065, -0.08, -0.25),
    rotation: new Euler(0, -1.5, 0, 'XYZ'),
  },
  {
    id: 're-innenknoechel',
    abbreviation: 're. Innenknöchel',
    description: 'Rechts Malleolus medialis',
    position: new Vector3(-0.06, 0.12, -0.105),
    rotation: new Euler(0, -1.5, 0, 'XYZ'),
  },
  {
    id: 're-grosszehenballen',
    abbreviation: 're. Großzehenballen',
    description: 'Rechts Großzehenballen',
    position: new Vector3(-0.07, -0.115, 0.26),
    rotation: new Euler(0, -1.5, 0, 'XYZ'),
  },
  {
    id: 'li-1/2',
    abbreviation: 'li. 1/2',
    description: 'Links zwischen 1. und 2. Zehe',
    position: new Vector3(0.132, -0.093, 0.29),
    rotation: new Euler(-1, 0, 0, 'XYZ'),
  },
  {
    id: 'li-2/3',
    abbreviation: 'li. 2/3',
    description: 'Links zwischen 2. und 3. Zehe',
    position: new Vector3(0.182, -0.088, 0.29),
    rotation: new Euler(-1, 0, 0, 'XYZ'),
  },
  {
    id: 'li-3/4',
    abbreviation: 'li. 3/4',
    description: 'Links zwischen 3. und 4. Zehe',
    position: new Vector3(0.222, -0.095, 0.27),
    rotation: new Euler(-1, 0, 0, 'XYZ'),
  },
  {
    id: 'li-4/5',
    abbreviation: 'li. 4/5',
    description: 'Links zwischen 5. und 6. Zehe',
    position: new Vector3(0.262, -0.1, 0.24),
    rotation: new Euler(-1, 0, 0, 'XYZ'),
  },
  {
    id: 're-1/2',
    abbreviation: 're. 1/2',
    description: 'Rechts zwischen 1. und 2. Zehe',
    position: new Vector3(-0.168, -0.093, 0.29),
    rotation: new Euler(-1, 0, 0, 'XYZ'),
  },
  {
    id: 're-2/3',
    abbreviation: 're. 2/3',
    description: 'Rechts zwischen 2. und 3. Zehe',
    position: new Vector3(-0.22, -0.083, 0.29),
    rotation: new Euler(-1, 0, 0, 'XYZ'),
  },
  {
    id: 're-3/4',
    abbreviation: 're. 3/4',
    description: 'Rechts zwischen 3. und 4. Zehe',
    position: new Vector3(-0.262, -0.088, 0.27),
    rotation: new Euler(-1, 0, 0, 'XYZ'),
  },
  {
    id: 're-4/5',
    abbreviation: 're. 4/5',
    description: 'Rechts zwischen 5. und 6. Zehe',
    position: new Vector3(-0.3, -0.095, 0.24),
    rotation: new Euler(-1, 0, 0, 'XYZ'),
  },
  {
    id: 're-1/5',
    abbreviation: 're. 1/5',
    description: 'Rechts zwischen allen Zehen',
    position: new Vector3(-0.23, -0.15, 0.285),
    rotation: new Euler(-1.5, 0, -0.4, 'XYZ'),
  },
  {
    id: 'li-1/5',
    abbreviation: 'li. 1/5',
    description: 'Links zwischen allen Zehen',
    position: new Vector3(0.19, -0.15, 0.29),
    rotation: new Euler(-1.5, 0, 0.4, 'XYZ'),
  },
  {
    id: 'li-1/5-met',
    abbreviation: 'li. 1/5 met.',
    description: 'Links 1. bis 5. Zeh metatarsal',
    position: new Vector3(0.175, -0.167, 0.25),
    rotation: new Euler(-1.5, 0, 0.4, 'XYZ'),
  },
  {
    id: 're-1/5-met',
    abbreviation: 're. 1/5 met.',
    description: 'Rechts 1. bis 5. Zeh metatarsal',
    position: new Vector3(-0.215, -0.167, 0.245),
    rotation: new Euler(-1.5, 0, -0.4, 'XYZ'),
  },
]
